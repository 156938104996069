<template>
  <div id="data-details" class="flex flex-col h-full select-text">
    <!-- Header -->
    <div class="flex items-center justify-between p-4 bg-gray-100">
      <div class="flex items-center space-x-4">
        <h1 v-if="!loading" class="text-2xl font-bold">
          {{ props.titleAttribute && data[props.titleAttribute] ? data[props.titleAttribute] : t('general.details') }}
        </h1>
        <h1 v-else>
          {{ t('general.loading') }}
        </h1>
        <span v-if="utils.isDefined(data) && utils.isDefined(data.Status) && !loading" class="inline-block">
          <span v-if="data.Status === 1" class="p-1 text-m text-white align-middle bg-green-500">{{ t('general.active') }}</span>
          <span v-if="data.Status === 0" class="p-1 text-m text-white align-middle bg-red-500">{{ t('general.inactive') }}</span>
          <span v-if="data.Status === 2" class="p-1 text-m text-white align-middle bg-blue-500">{{ t('general.notAssorted') }}</span>
          <span v-if="data.Status === 3" class="p-1 text-m text-white align-middle bg-red-500">{{ t('general.globallyDropped') }}</span>
        </span>
      </div>
      <!-- ACTIONS -->
      <div v-if="!loading" class="flex space-x-4">
        <tx-button
          v-for="(action, index) in itemActions" v-show="action.visible" :key="index" v-tooltip="action.label" type="icon" class="p-2 mx-1" width="40px" height="40px"
          :faicon="action.icon" :disabled="!action.enabled" @click="action.onClick(data)"
        />
        <tx-button
          v-if="showStaticActions" type="icon" width="40px" height="40px" faicon="fa-light fa-arrows-rotate"
          @click="doRefresh()"
        />
      </div>
    </div>

    <!-- Field Viewer -->
    <div v-if="loading" class="flex justify-center items-center flex-1">
      <loader />
    </div>
    <div v-else class="flex flex-wrap p-4 overflow-y-auto">
      <div v-for="field in props.columns" :key="field.property" class="w-2/4 mb-4">
        <div class="font-bold">
          {{ field.title }}
        </div>
        <tx-data-table-field-viewer
          class="left" width="200px" :field="field" :value="get(data, field.property, null)"
          :lookup-data="lookupData[field.property]"
        />
      </div>
    </div>

    <!-- Floating Action Button -->
    <!-- <tx-button v-if="!loading" class="fixed bottom-10 right-20 bg-red-500 text-white rounded-full p-4 shadow-lg hover:bg-red-600">
      edit
    </tx-button> -->
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ref, watch } from 'vue'
import { get } from 'lodash-es'
import TxDataTableFieldViewer from '../txDataTable/TxDataTableFieldViewer.vue'
import type { ITxDataTableColumn, ITxDataTableItemAction } from '../txDataTable/TxDataTable.types'
import useMasterDataLookup from '@/modules/admin/composables/masterDataLookup'
import { useUserStore } from '@/store/userData'
import utils from '@/services/utils'
import Loader from '@/shared/components/Loader.vue'
import TxButton from '@/shared/components/TxButton.vue'

interface IProps {
  columns: ITxDataTableColumn[]
  data: any
  loading: boolean
  itemActions: ITxDataTableItemAction[]
  titleAttribute?: string
  showStaticActions?: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  titleAttribute: 'ArticleNumber',
  showStaticActions: true,
})

const emit = defineEmits<{
  (e: 'refresh'): void
}>()

const { t } = useI18n()
const { loadLookupForTable } = useMasterDataLookup()
const userStore = useUserStore()
const lookupData = ref<Record<string, any>>({})

function doRefresh() {
  emit('refresh')
}

watch(() => props.columns, async () => {
  if (userStore.activeCatalog) {
    lookupData.value = await loadLookupForTable(props.columns, userStore.activeCatalog)
  }
})
</script>
