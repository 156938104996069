import { getArticles } from '@/api/t1/article'
import appConfig from '@/services/appConfig'
import useEventsBus from '@/shared/composables/eventBus'
import { useUserStore } from '@/store/userData'

export default function useArticleLocalDataUpdater() {
  const userStore = useUserStore()
  const { emitter } = useEventsBus()

  async function refreshLocalArticlesData(IsSeasonless: boolean, modelId?: number, articleId?: number, notify = true) {
    if (userStore.activeCatalog) {
      const promises: Promise<void>[] = []
      const catalogCodes = IsSeasonless ? userStore.activeCatalog._AllRelatedCatalogCodes : [userStore.activeCatalog.CatalogCode]
      const filter = modelId ? `ModelId|${modelId}` : articleId ? `Id|${articleId}` : undefined
      catalogCodes.forEach((catalogCode) => {
        promises.push(getArticles(catalogCode, filter, undefined, undefined, userStore.activeCatalog?.DataSourceTypeId)
          .then(async (res) => {
            if (res.data && res.data.length > 0) {
              const catalog = userStore.linkedCatalogDetails[catalogCode] || userStore.activeCatalog
              await appConfig.DB!.bulkUpdateArticlesFromAPI(catalog, res.data)
            }
          })
          .catch((e) => {
            console.error(`Unable to update article data (${catalogCode})`, e)
            throw e
          }),
        )
      })
      if (promises.length > 0) {
        await Promise.all(promises)
          .then(() => {
            if (notify) {
              emitter('catalogDataUpdated', { source: 'Local' })
            }
          })
      }
    }
  }

  return {
    refreshLocalArticlesData,
  }
}
