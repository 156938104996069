<template>
  <div class="flex flex-col h-full overflow-hidden">
    <div class="flex-grow overflow-hidden">
      <aside-content id="catalog" v-model:opened="asideOpened">
        <template #aside>
          <tx-nav-tree :groups="treeGroups" class="w-full" :selected-node="selectedNode" @node-click="doNodeClick" />
        </template>
        <router-view />
      </aside-content>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useUserStore } from '@/store/userData'
import AsideContent from '@/shared/components/AsideContent.vue'
import TxNavTree from '@/shared/components/txNavTree'
import type { ITxNavTreeGroup, ITxNavTreeNode } from '@/shared/components/txNavTree/TxNavTree.types'
import { privileges } from '@/models/constants'
import utils from '@/services/utils'

const { t } = useI18n()
const userStore = useUserStore()
const route = useRoute()
const router = useRouter()

const asideOpened = ref(true)

const treeGroups: ITxNavTreeGroup[] = [
  {
    title: 'Catalog',
    subTitle: 'Catalog related data',
    nodes: [
      {
        enabled: true,
        expanded: false,
        visible: privileges.admin.nav.articles.every(privilege => userStore.userProfile.isValidPrivilege(privilege)),
        faicon: 'fa-light fa-shirt',
        id: 'AdminArticles',
        title: t('general.articles'),
      },
    ],
  },
]

const selectedNode = ref<ITxNavTreeNode>()

function findNode(id: string) {
  for (const group of treeGroups) {
    for (const node of group.nodes) {
      if (node.id === id) {
        return node
      }
    }
  }
}

function doNodeClick(node: ITxNavTreeNode) {
  if (node.id !== route.name) {
    router.push({ name: node.id })
    selectedNode.value = node
  }
}

watch(route, (to) => {
  selectedNode.value = undefined
  if (to.meta && to.meta.privileges && Array.isArray(to.meta.privileges) && to.meta.privileges.length > 0) {
    if (!to.meta.privileges.every(privilege => userStore.userProfile.isValidPrivilege(privilege))) {
      router.replace({ name: 'AdminForbidden' })
    }
    else if (utils.isValidStringValue(to.name)) {
      selectedNode.value = findNode(to.name!.toString())
    }
  }
}, { flush: 'pre', immediate: true, deep: true })
</script>
