<template>
  <div class="relative w-full h-full overflow-hidden">
    <loader v-if="loading" />
    <div v-else class="container flex flex-col w-full h-full">
      <!-- HEADER -->
      <tx-form-header class="flex flex-col mx-2 my-[30px] grow-0 shrink-0 justify-center header" :title="t('articleDetails.actions.editPeriod')" :show-header="showHeader" />

      <!-- BODY -->
      <div class="px-10 mt-4 alerts">
        <tx-alert :show="showWarning" type="warning" :text="warningMessage" dismissible />
      </div>
      <div class="h-full">
        <div class="grid w-full grid-cols-2 px-10 overflow-auto gap-x-6">
          <div
            v-for="(attribute, index) in visibleAttributes" :key="attribute.SystemName"
            :class="{ 'col-span-2': index === 0, 'col-span-1': index > 0 }" class="mb-5 last-of-type:mb-4"
          >
            <form-editor
              v-model="form[attribute.SystemName]"
              :attribute="attribute"
              :form="form"
              :required="attribute.IsRequired"
              :errors="v$[attribute.SystemName]?.$errors"
              :show-label="true"
              :clearable="true"
              :sort-list="false"
              @blur="v$[attribute.SystemName]?.$touch"
              @change="changedSelected(attribute.SystemName)"
            />
          </div>
          <div class="mb-4">
            <p class="text-xs tracking-wide uppercase label">
              {{ t('updateArticleRetailWindow.shipmentStartDate') }}
            </p>
            {{ utils.formatDate(form.ShipmentStartDate) }}
          </div>
          <div class="mb-4">
            <p class="text-xs tracking-wide uppercase label">
              {{ t('updateArticleRetailWindow.shipmentEndDate') }}
            </p>
            {{ utils.formatDate(form.ShipmentEndDate) }}
          </div>
        </div>
      </div>

      <!-- FOOTER -->
      <tx-form-footer
        class="flex flex-row justify-end flex-shrink-0 flex-nowrap"
        :primary-text="t('general.update')" :primary-disabled="v$.$invalid" @primary-click="onUpdate"
        @secondary-click="onCancel"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { computed, reactive, ref } from 'vue'
import { uniqBy } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import useArticleLocalDataUpdater from '../composables/articleLocalDataUpdater'
import type CatalogShipmentWindowRange from '@/models/catalogShipmentWindowRange'
import Loader from '@/shared/components/Loader.vue'
import type MyArticle from '@/models/myArticle'
import TxAlert from '@/shared/components/TxAlert.vue'
import TxFormFooter from '@/shared/components/forms/TxFormFooter.vue'
import useShipmentWindow from '@/shared/composables/shipmentWindow'
import utils from '@/services/utils'
import { updateArticleShipmentWindowRange } from '@/api/t1/article'
import { useUserStore } from '@/store/userData'
import TxFormHeader from '@/shared/components/forms/TxFormHeader.vue'
import { appConstants } from '@/models/constants'
import { AttributeType } from '@/models/catalogAttribute'
import FormEditor from '@/shared/components/FormEditor.vue'

interface IFormItem {
  Period: number | null
  RetailIntroMonthId: number | null | undefined
  RetailExitMonthId: number | null | undefined
  ShipmentStartDate: Date | undefined
  ShipmentEndDate: Date | undefined
  ShipmentWindowRangeId: number
  IsShippingWindowUpdatable: number
}

interface IProps {
  article: MyArticle
  showHeader?: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  showHeader: true,
})

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated', articles: MyArticle[]): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { getShipmentWindows, getSortRetailIntroExitMonths } = useShipmentWindow()
const { refreshLocalArticlesData } = useArticleLocalDataUpdater()

const form = reactive<IFormItem>({
  Period: null,
  RetailIntroMonthId: null,
  RetailExitMonthId: null,
  ShipmentStartDate: undefined,
  ShipmentEndDate: undefined,
  ShipmentWindowRangeId: 0,
  IsShippingWindowUpdatable: 1,
})
const loading = ref<boolean>(false)
const isDefaultRetailWindowAssigned = ref<boolean>(false)
const availableShipmentWindows = computed<Array<CatalogShipmentWindowRange>>(() => getShipmentWindows([props.article], userStore.activeCatalog!))

const visibleAttributes = computed(() => {
  const visibleAttributes: IFormEditorAttribute[] = []
  const periodField: IFormEditorAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
    SystemName: 'Period',
    DisplayName: t('updateArticleRetailWindow.period'),
    Creatable: true,
    AttributeType: AttributeType.Int,
    ReadOnly: false,
    IsRequired: true,
    DropDownData: getRetailWindowByCriteria(),
    DropDownValueProp: 'Id',
    DropDownValueDisplayProp: 'Period',
  })
  visibleAttributes.push(periodField)
  const retailIntroMonthField: IFormEditorAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
    SystemName: 'RetailIntroMonthId',
    DisplayName: t('updateArticleRetailWindow.retailIntroMonth'),
    Creatable: true,
    AttributeType: AttributeType.Int,
    ReadOnly: false,
    IsRequired: true,
    DropDownData: getRetailWindowByCriteria(form.Period, undefined, 'RetailIntroMonth'),
    DropDownValueProp: 'Id',
    DropDownValueDisplayProp: 'RetailIntroMonth',
  })
  visibleAttributes.push(retailIntroMonthField)
  const retailExitMonthField: IFormEditorAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
    SystemName: 'RetailExitMonthId',
    DisplayName: t('updateArticleRetailWindow.retailExitMonth'),
    Creatable: true,
    AttributeType: AttributeType.Int,
    ReadOnly: false,
    IsRequired: true,
    DropDownData: getRetailWindowByCriteria(form.Period, form.RetailIntroMonthId, 'RetailExitMonth'),
    DropDownValueProp: 'Id',
    DropDownValueDisplayProp: 'RetailExitMonth',
  })
  visibleAttributes.push(retailExitMonthField)
  return visibleAttributes
})

const rules = computed(() => {
  const result: Record<string, any> = {}
  visibleAttributes.value.forEach((attribute) => {
    result[attribute.SystemName] = {}
    if (attribute.IsRequired) {
      result[attribute.SystemName].required = helpers.withMessage(t('validations.required', { property: attribute.DisplayName }), required)
    }
  })
  return result
})
const v$ = useVuelidate(rules, form)

function getRetailWindowByCriteria(period?: number | null, retailIntro?: number | null | undefined, context?: string) {
  if (context === 'RetailExitMonth' && !(period && retailIntro)) {
    return [] as CatalogShipmentWindowRange[]
  }
  if (context === 'RetailIntroMonth' && !period) {
    return [] as CatalogShipmentWindowRange[]
  }
  if (userStore.activeCatalog) {
    const catalogDetails = userStore.activeCatalog

    if (!catalogDetails) {
      return [] as CatalogShipmentWindowRange[]
    }
    const result: CatalogShipmentWindowRange[] = utils.sort(availableShipmentWindows.value, 'Period')
    if (!period) {
      return uniqBy(result, 'Period') as CatalogShipmentWindowRange[]
    }
    else if (period) {
      const selectedPeriod = result.filter(s => s.Id === period)
      const selectedPeriods = result.filter(data => data.Period === selectedPeriod[0]?.Period)
      if (retailIntro) {
        const selectedIntroMonth = result.filter(data => data.Id === retailIntro)
        const selectedIntros = selectedPeriods.filter(p => p.RetailIntroMonth === selectedIntroMonth[0]?.RetailIntroMonth)
        return getSortRetailIntroExitMonths(uniqBy(selectedIntros, 'RetailExitMonth') as CatalogShipmentWindowRange[], 'RetailExitMonth') as CatalogShipmentWindowRange[]
      }
      return getSortRetailIntroExitMonths(uniqBy(selectedPeriods, 'RetailIntroMonth') as CatalogShipmentWindowRange[], 'RetailIntroMonth') as CatalogShipmentWindowRange[]
    }
  }
  return []
}

function changedSelected(context: string) {
  v$.value[context]?.$touch()
  if (context === 'Period') {
    form.ShipmentEndDate = undefined
    form.ShipmentStartDate = undefined
    form.RetailIntroMonthId = null
    form.RetailExitMonthId = null
    // Set default value based on RetailWindowDefaultValueCriteria
    if (form.Period !== null && userStore.activeCatalog!.Config.RetailWindowDefaultValueCriteria) {
      const selectedPeriodretailWindow = availableShipmentWindows.value.filter(s => s.Id === form.Period)
      const selectedPeriod = selectedPeriodretailWindow[0]?.Period
      const defaultRetailConfigurations = userStore.activeCatalog!.Config.RetailWindowDefaultValueCriteria.defaultRetailConfigurations
      if (defaultRetailConfigurations) {
        // find the articleLifecycle, if article have SeasonalSequence use that else use the catalog SeasonalSequence
        const articleSeasonalSequence = props.article.SeasonalSequence ? props.article.SeasonalSequence : userStore.activeCatalog!.SeasonalSequence
        const articleLifeCycle = articleSeasonalSequence < userStore.activeCatalog!.SeasonalSequence ? 'carryover' : 'new'

        const retailWindowsMatchingLifecycleAndPeriod = defaultRetailConfigurations.filter((defaultRetailWindowRange) => {
          return defaultRetailWindowRange.Period?.map(p => p.toLowerCase()).includes(selectedPeriod.toLowerCase())
            && (!defaultRetailWindowRange.ArticleLifecycle || defaultRetailWindowRange.ArticleLifecycle?.map(al => al.toLowerCase()).includes(articleLifeCycle))
        })

        // Loop through retailWindowsMatchingLifecycleAndPeriod and check if any key is an attribute
        for (const retailWindow of retailWindowsMatchingLifecycleAndPeriod) {
          let matchFound = false
          Object.keys(retailWindow).forEach((key) => {
            if (!matchFound && key !== 'ArticleLifecycle' && key !== 'Period' && key !== 'RetailIntroMonth' && key !== 'RetailExitMonth') {
              // attribute
              if (props.article[key] && retailWindow[key]?.includes(props.article[key])) {
                const selectedPeriods = availableShipmentWindows.value.filter(data => data.Period === selectedPeriod)
                const defaultRetailWindowValue = selectedPeriods.find((rw) => {
                  return rw.RetailIntroMonth === retailWindow.RetailIntroMonth && rw.RetailExitMonth === retailWindow.RetailExitMonth
                })
                if (defaultRetailWindowValue) {
                  isDefaultRetailWindowAssigned.value = true
                  form.RetailIntroMonthId = defaultRetailWindowValue.Id
                  form.RetailExitMonthId = defaultRetailWindowValue.Id
                  form.ShipmentStartDate = defaultRetailWindowValue.ShipmentStartDate
                  form.ShipmentEndDate = defaultRetailWindowValue.ShipmentEndDate
                  form.ShipmentWindowRangeId = defaultRetailWindowValue.Id
                  matchFound = true
                }
                else {
                  isDefaultRetailWindowAssigned.value = false
                }
              }
            }
          })
          if (matchFound) {
            break
          }
        }
      }
    }
  }
  else if (context === 'RetailIntroMonthId' && !isDefaultRetailWindowAssigned.value) {
    form.ShipmentEndDate = undefined
    form.ShipmentStartDate = undefined
    form.RetailExitMonthId = null
  }
  else if (context === 'RetailExitMonthId' && !isDefaultRetailWindowAssigned.value) {
    form.ShipmentEndDate = undefined
    form.ShipmentStartDate = undefined
    const catalogDetails = userStore.activeCatalog
    const article = props.article
    const retailWindowCriteriaAttributeValue = article ? article[catalogDetails!.RetailWindowAttribute] : null
    if (catalogDetails) {
      const selectedPeriod = availableShipmentWindows.value.filter(s => s.Id === form.Period)
      const selectedIntroMonth = availableShipmentWindows.value.filter(s => s.Id === form.RetailIntroMonthId)
      const selectedExitMonth = availableShipmentWindows.value.filter(s => s.Id === form.RetailExitMonthId)
      const result: CatalogShipmentWindowRange[] = availableShipmentWindows.value.filter(s => s.Period === selectedPeriod[0]?.Period && s.RetailIntroMonth === selectedIntroMonth[0]?.RetailIntroMonth && s.RetailExitMonth === selectedExitMonth[0]?.RetailExitMonth && (catalogDetails.RetailWindowAttribute == null || s.CriteriaAttributeValue === retailWindowCriteriaAttributeValue))
      if (result) {
        form.ShipmentWindowRangeId = result[0].Id
        form.ShipmentStartDate = result[0].ShipmentStartDate
        form.ShipmentEndDate = result[0].ShipmentEndDate
      }
    }
  }
}

async function onUpdate() {
  loading.value = true
  await updateArticleShipmentWindowRange(userStore.activeCatalog!.CatalogCode, props.article.Id, form.ShipmentWindowRangeId || '')
  await refreshLocalArticlesData(false, undefined, props.article.Id)
  emit('updated', [props.article])
  loading.value = false
}

function onCancel() {
  emit('cancel')
}

const showWarning = ref(false)
const warningMessage = ref('')

function init() {
  const selectedPeriod = availableShipmentWindows.value.filter(s => s.Period === props.article.Period)
  form.Period = selectedPeriod[0]?.Id
  form.RetailIntroMonthId = null
  form.RetailExitMonthId = null
  const articleRetailIntroMonth = props.article.RetailIntroMonth
  const articleRetailExitMonth = props.article.RetailExitMonth

  const shipmentWindowsMatchedWithPeriod = availableShipmentWindows.value.filter(shipmentWindow => utils.haveEqualStringValue(shipmentWindow.Period, props.article.Period))

  const shipmentWindowsMatchedWithIntroDate = shipmentWindowsMatchedWithPeriod.filter(shipmentWindow => utils.haveEqualStringValue(shipmentWindow.RetailIntroMonth, articleRetailIntroMonth))
  if (shipmentWindowsMatchedWithIntroDate.length) {
    form.RetailIntroMonthId = shipmentWindowsMatchedWithIntroDate[0].Id
  }
  const shipmentWindowForExitMonth = shipmentWindowsMatchedWithIntroDate.find(shipmentWindow => utils.haveEqualStringValue(shipmentWindow.RetailExitMonth, articleRetailExitMonth))
  if (utils.isDefined(shipmentWindowForExitMonth)) {
    form.RetailExitMonthId = shipmentWindowForExitMonth.Id
    form.ShipmentStartDate = shipmentWindowForExitMonth.ShipmentStartDate
    form.ShipmentEndDate = shipmentWindowForExitMonth.ShipmentEndDate
    form.ShipmentWindowRangeId = shipmentWindowForExitMonth.Id
  }
  if (utils.isDefined(props.article.RetailIntroMonth) && utils.isDefined(props.article.RetailExitMonth)
    && (form.RetailIntroMonthId == null || form.RetailExitMonthId == null)) {
    showWarning.value = true
    warningMessage.value = t('updateArticleRetailWindow.assignedRetailWindowIsNotValid', { intro: props.article.RetailIntroMonth, exit: props.article.RetailExitMonth })
  }
}

init()
</script>
