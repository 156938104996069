import { get, isArray, isUndefined } from 'lodash-es'
import type { ArticleCrd } from './articleDeliveryDate'
import type { Segmentation } from './articleSegmentation'
import type SimpleFavoriteTag from './simpleFavoriteTag'
import { AttributeType } from './catalogAttribute'
import type { ISavedViewFilter } from './savedView'
import type LinkedCatalog from './linkedCatalog'
import type CatalogDetails from './catalogDetails'
import utils from '@/services/utils'

export interface IFilterCriteria {
  attribute: string
  exclude: boolean
  stringVal?: string
  numberVal?: number
  numberVal2?: number
  multipleVals?: any[]
  statusVal?: number | null
  mode: FilterCriteriaMode
  filterBlank?: boolean
  isAdvancedFilter?: boolean
}

export enum FilterCriteriaMode {
  string = 0,
  date = 1,
  dateTime = 2,
  dateRange = 3,
  dateTimeRange = 4,
  number = 5,
  numberRange = 6,
  multiString = 7,
  status = 8,
}

export class FilterCriteria implements IFilterCriteria {
  attribute: string
  exclude: boolean
  stringVal?: string
  numberVal?: number
  numberVal2?: number
  multipleVals?: any[]
  statusVal?: number | null
  mode: FilterCriteriaMode
  filterBlank?: boolean
  isAdvancedFilter?: boolean

  constructor(source?: IFilterCriteria) {
    if (!source) {
      this.attribute = ''
      this.mode = FilterCriteriaMode.string
      this.exclude = false
    }
    else {
      this.attribute = source.attribute || ''
      this.exclude = source.exclude
      this.stringVal = source.stringVal || ''
      this.numberVal = source.numberVal
      this.numberVal2 = source.numberVal2
      this.statusVal = source.statusVal
      this.mode = source.mode
      this.multipleVals = source.multipleVals || []
      this.filterBlank = source.filterBlank
      this.isAdvancedFilter = source.isAdvancedFilter || false
    }
  }

  resetValues() {
    this.exclude = false
    this.stringVal = ''
    this.numberVal = 0
    this.mode = FilterCriteriaMode.string
    this.numberVal2 = 0
    this.multipleVals = []
    this.statusVal = null
    this.filterBlank = false
    this.isAdvancedFilter = false
  }

  toInterface() {
    const res: IFilterCriteria = { attribute: this.attribute, exclude: this.exclude, mode: this.mode }
    if (!isUndefined(this.stringVal) && this.stringVal?.length > 0) { res.stringVal = this.stringVal }
    if (!isUndefined(this.numberVal)) { res.numberVal = this.numberVal }
    if (!isUndefined(this.numberVal2)) { res.numberVal2 = this.numberVal2 }
    if (this.multipleVals && this.multipleVals.length > 0) { res.multipleVals = this.multipleVals }
    if (utils.isDefined(this.statusVal)) { res.statusVal = this.statusVal }
    if (utils.isDefined(this.filterBlank)) { res.filterBlank = this.filterBlank }
    return res
  }

  toJSON() {
    return JSON.stringify(this.toInterface())
  }

  toDisplayString(attributes: Record<string, IMyAttribute>) {
    if (!this.attribute || !attributes[this.attribute]) { return '' }

    switch (this.mode) {
      case FilterCriteriaMode.date:
      case FilterCriteriaMode.dateTime:
      {
        const date = this.stringVal ? (this.mode === FilterCriteriaMode.date ? (new Date(this.stringVal)).toLocaleDateString() : (new Date(this.stringVal)).toLocaleString()) : ''
        return `${attributes[this.attribute].DisplayName} ${this.exclude ? '≠' : '='} ${date}`
      }

      case FilterCriteriaMode.string:
        return `${attributes[this.attribute].DisplayName} ${this.exclude ? 'not contain' : 'contains'} ${this.stringVal}`

      case FilterCriteriaMode.number:
        return `${attributes[this.attribute].DisplayName} ${this.exclude ? '≠' : '='} ${this.numberVal}`

      case FilterCriteriaMode.dateRange:
      case FilterCriteriaMode.dateTimeRange:
      {
        const fromDate = this.multipleVals && this.multipleVals.length >= 0
          ? (this.mode === FilterCriteriaMode.dateRange ? (new Date(this.multipleVals[0])).toLocaleDateString() : (new Date(this.multipleVals[0])).toLocaleString())
          : ''
        const toDate = this.multipleVals && this.multipleVals.length >= 1
          ? (this.mode === FilterCriteriaMode.dateRange ? (new Date(this.multipleVals[1])).toLocaleDateString() : (new Date(this.multipleVals[1])).toLocaleString())
          : ''
        return `${attributes[this.attribute].DisplayName} ${this.exclude ? 'not between' : 'between'} ${fromDate} and ${toDate}`
      }

      case FilterCriteriaMode.multiString:
        return `${attributes[this.attribute].DisplayName} ${this.exclude ? '≠' : '='} ${this.multipleVals?.map(v => attributes[this.attribute].FilterLookup.get(v) || v).join(' or ')}`

      case FilterCriteriaMode.numberRange:
        return `${attributes[this.attribute].DisplayName} ${this.exclude ? 'not between' : 'between'} ${this.numberVal} and ${this.numberVal2}`

      case FilterCriteriaMode.status: {
        const statusVal = attributes[this.attribute].FilterLookup.get(this.statusVal)
        return `${attributes[this.attribute].DisplayName} ${this.exclude ? '≠' : '='} ${statusVal}`
      }

        return ''
    }
  }

  hasValue(): boolean {
    switch (this.mode) {
      case FilterCriteriaMode.date:
      case FilterCriteriaMode.dateTime:
      case FilterCriteriaMode.string:
        return utils.isDefined(this.stringVal) && this.stringVal.length > 0
      case FilterCriteriaMode.dateRange:
      case FilterCriteriaMode.dateTimeRange:
        return utils.isDefined(this.multipleVals) && this.multipleVals.length > 1
      case FilterCriteriaMode.number:
        return utils.isDefined(this.numberVal)
      case FilterCriteriaMode.numberRange:
        return utils.isDefined(this.numberVal) && utils.isDefined(this.numberVal2)
      case FilterCriteriaMode.multiString:
        return utils.isDefined(this.multipleVals) && this.multipleVals.length > 0
      case FilterCriteriaMode.status:
        return utils.isDefined(this.statusVal)
    }
  }

  matchesRecord(record: any, checkOwnProperty = false, isDataTable = false): boolean {
    if (!checkOwnProperty && !record.hasOwnProperty(this.attribute)) { return true }

    let recordValue = get(record, this.attribute)

    // filter blanks
    if (this.filterBlank && (!utils.isValidStringValue(recordValue))) {
      return true
    }

    switch (this.mode) {
      case FilterCriteriaMode.date:
      case FilterCriteriaMode.dateTime:
      {
        if (isUndefined(this.stringVal) || !utils.isDefined(recordValue) || !utils.isValidStringValue(recordValue)) { return false }
        const dateVal = new Date(recordValue.toString())
        const date = new Date(this.stringVal)
        if (this.mode === FilterCriteriaMode.date) {
          dateVal.setHours(0, 0, 0, 0)
          date.setHours(0, 0, 0, 0)
        }
        return dateVal === date
      }

      case FilterCriteriaMode.dateRange:
      case FilterCriteriaMode.dateTimeRange:
      {
        if (isUndefined(this.multipleVals) || this.multipleVals.length <= 1 || !utils.isDefined(recordValue) || !utils.isValidStringValue(recordValue)) { return false }
        const dateVal = new Date(recordValue.toString())
        const fromDate = new Date(this.multipleVals[0])
        const toDate = new Date(this.multipleVals[1])
        if (this.mode === FilterCriteriaMode.dateRange) {
          dateVal.setHours(0, 0, 0, 0)
          fromDate.setHours(0, 0, 0, 0)
          toDate.setHours(0, 0, 0, 0)
        }
        // when filtering from date range filter user can filter using either start or end date
        if (fromDate.toString() === 'Invalid Date') {
          // start date is empty, so filter data less than or equal to the end date
          return dateVal <= toDate
        }
        else if (toDate.toString() === 'Invalid Date') {
          // end date is empty, so filter data greater than or equal to the start date
          return dateVal >= fromDate
        }

        // filter data in the range when both values are provided
        return dateVal >= fromDate && dateVal <= toDate
      }

      case FilterCriteriaMode.number:
        if (isDataTable) {
          if (this.attribute.toLocaleLowerCase().includes('_prices')) {
            recordValue = recordValue.Price || 0
          }
          return !isUndefined(this.numberVal) && this.exclude !== (recordValue.toString().includes(this.numberVal.toString()))
        }
        else {
          return !isUndefined(this.numberVal) && this.exclude !== (this.numberVal === recordValue)
        }

      case FilterCriteriaMode.numberRange:
        return !isUndefined(this.numberVal) && !isUndefined(this.numberVal2) && utils.isDefined(recordValue) && this.exclude !== (recordValue >= this.numberVal && recordValue <= this.numberVal2)

      case FilterCriteriaMode.string:
        if (this.attribute.toLocaleLowerCase().includes('_segmentations')) {
          recordValue = recordValue ? recordValue.PlanningValue ? recordValue.PlanningValue : 'Yes' : 'No'
        }
        return !isUndefined(this.stringVal) && utils.isDefined(recordValue) && this.exclude !== (recordValue.toString().toLowerCase().includes(this.stringVal.toLowerCase().trim()))

      case FilterCriteriaMode.multiString:
      {
        if (isUndefined(this.multipleVals)) { return false }
        // value on record is an array
        if (typeof recordValue === 'object' && this.attribute === '_Segmentations') {
          recordValue = Object.values(recordValue)
        }
        if (utils.isDefined(recordValue) && Array.isArray(recordValue)) {
          if (this.attribute === '_DeliveryDates') {
            const articleCrds = (recordValue as ArticleCrd[]).filter(a => a.Status > 0)
            if (!articleCrds.length) {
              return this.exclude !== (this.multipleVals.includes(null))
            }
            return this.exclude !== (this.multipleVals.some(a => (a == null && (articleCrds == null || !articleCrds.length)) || articleCrds.some(b => b.CrdId === Number(a))))
          }
          else if (this.attribute === '_Segmentations') {
            const articleSegmentation = recordValue as Segmentation[]
            if (!articleSegmentation.length) {
              return this.exclude !== (this.multipleVals.includes(null))
            }
            return this.exclude !== (this.multipleVals.some(a => (a == null && (articleSegmentation == null || !articleSegmentation.length)) || articleSegmentation.some(b => b.Id === Number(a))))
          }
          else if (this.attribute === '_FavoriteTags') {
            const favoriteTags = recordValue as SimpleFavoriteTag[]
            return this.exclude !== (this.multipleVals.some(a => (a == null && (favoriteTags == null || !favoriteTags.length)) || favoriteTags.some(b => b.Id === Number(a))))
          }
          else { // if multivalue attributes
            if (recordValue.length) {
              // TODO: I think if statement is not required here (the same discussed with the developer who wrote it)
              if (this.stringVal && this.stringVal.length !== 0) {
                return this.exclude !== (recordValue.some(articleAttributeValue =>
                  this.multipleVals?.some(multipleValCurrentValue =>
                    (multipleValCurrentValue == null && (articleAttributeValue == null || !articleAttributeValue.toString().trim().length)) || (articleAttributeValue != null && multipleValCurrentValue.toString().trim().toLowerCase().includes(articleAttributeValue.toLowerCase())))))
              }
              else {
                return this.exclude !== (recordValue.some(articleAttributeValue =>
                  this.multipleVals?.some(multipleValCurrentValue =>
                    (multipleValCurrentValue == null && (articleAttributeValue == null || !articleAttributeValue.toString().trim().length)) || (articleAttributeValue != null && multipleValCurrentValue.toString().trim().toLowerCase() === articleAttributeValue.toString().toLowerCase()))))
              }
            }
            else {
              return this.exclude !== (this.multipleVals.includes(null))
            }
          }
        }

        else if (utils.isDefined(recordValue) && recordValue.toString().trim().length > 0) {
          if (this.attribute === '_Seasons') {
            return true
          }
          else if (this.attribute === '_RequestSource') {
            const multipleValsSet = new Set<string>(this.multipleVals.filter(x => x != null).map(x => x.toString()))
            if (multipleValsSet.has('-1')) {
              return true
            }
            else if (multipleValsSet.has('-2')) {
              return this.exclude !== (record._IsRequestArticle === false)
            }
            else if (multipleValsSet.has('-3')) {
              return this.exclude !== (record._IsRequestArticle === true)
            }
            return this.exclude !== (this.multipleVals.findIndex(multipleValCurrentValue => multipleValCurrentValue != null && (recordValue.toString().toLowerCase() === multipleValCurrentValue.toString().trim().toLowerCase())) >= 0)
          }
          else if (this.stringVal && this.stringVal.length !== 0) {
            return this.exclude !== (this.multipleVals.findIndex(multipleValCurrentValue => multipleValCurrentValue != null && recordValue.toString().toLowerCase().includes(multipleValCurrentValue.toString().trim().toLowerCase())) >= 0)
          }
          else {
            return this.exclude !== (this.multipleVals.findIndex(multipleValCurrentValue => multipleValCurrentValue != null && recordValue.toString().toLowerCase() === multipleValCurrentValue.toString().trim().toLowerCase()) >= 0)
          }
        }
        else {
          return this.exclude !== (this.multipleVals.includes(null))
        }
      }

      case FilterCriteriaMode.status:
        return this.exclude !== ((this.statusVal === -1 || (utils.isDefined(this.statusVal) && this.statusVal === recordValue)))

        return false
    }
  }

  toSavedViewFilter(catalogDetails: CatalogDetails): ISavedViewFilter {
    const savedViewFilter: ISavedViewFilter = { value: this.multipleVals, type: 'list', exclude: this.exclude }
    if (this.mode === FilterCriteriaMode.date || this.mode === FilterCriteriaMode.dateTime || this.mode === FilterCriteriaMode.string) {
      savedViewFilter.type = (this.mode === FilterCriteriaMode.date) ? 'date' : (this.mode === FilterCriteriaMode.dateTime) ? 'datetime' : 'string'
      savedViewFilter.value = this.stringVal
    }
    else if (this.mode === FilterCriteriaMode.number) {
      savedViewFilter.type = 'number'
      savedViewFilter.value = this.numberVal
    }
    else if (this.mode === FilterCriteriaMode.numberRange) {
      savedViewFilter.type = 'numberRange'
      savedViewFilter.value = [this.numberVal, this.numberVal2]
    }
    else if (this.mode === FilterCriteriaMode.status) {
      savedViewFilter.type = 'status'
      savedViewFilter.value = this.statusVal
    }
    else if (this.mode === FilterCriteriaMode.multiString) {
      if (this.attribute === '_Seasons') {
        savedViewFilter.value = []
        if (this.multipleVals) {
          const indexedLinkedCatalog = catalogDetails.LinkedCatalogList.reduce((acc, curr) => {
            acc[curr.CatalogCode] = curr
            return acc
          }, {} as Record<number, LinkedCatalog>)
          this.multipleVals.forEach((val) => {
            const linkedCatalog = indexedLinkedCatalog[val]
            if (linkedCatalog) {
              savedViewFilter.value.push(`${linkedCatalog.Season}${linkedCatalog.CatalogCode}`)
            }
          })
        }
      }
      else {
        savedViewFilter.type = 'textarea'
        savedViewFilter.value = this.multipleVals
      }
    }
    return savedViewFilter
  }

  /**
   * @description: return a string value for a criteria which is being used to get the bucket attributes value
   */
  getCriteriaValueForBuckets() {
    let bucketCriteriaValue: string | undefined
    const propertyLower = this.getCriteriaNameForBuckets()

    if (this.mode === FilterCriteriaMode.date || this.mode === FilterCriteriaMode.dateTime) {
      if (utils.isDefined(this.statusVal) && this.statusVal.toString().trim() !== '') {
        const value = this.mode !== FilterCriteriaMode.dateTime ? new Date(this.statusVal).setHours(0, 0, 0, 0) : new Date(this.statusVal)
        bucketCriteriaValue = `${propertyLower}${this.exclude ? '!=' : '='}${value}`
      }
    }
    else if (this.mode === FilterCriteriaMode.dateRange || this.mode === FilterCriteriaMode.dateTimeRange) {
      if (utils.isDefined(this.multipleVals) && this.multipleVals.length === 2) {
        const d1 = this.mode !== FilterCriteriaMode.dateTimeRange ? new Date(this.multipleVals[0]).setHours(0, 0, 0, 0) : new Date(this.multipleVals[0])
        const d2 = this.mode !== FilterCriteriaMode.dateTimeRange ? new Date(this.multipleVals[1]).setHours(0, 0, 0, 0) : new Date(this.multipleVals[1])
        const shouldReverseValues = d1 > d2
        const value = `between${!shouldReverseValues ? d1 : d2}and${!shouldReverseValues ? d2 : d1}`
        bucketCriteriaValue = `${propertyLower}${this.exclude ? '!=' : '='}${value}`
      }
    }
    else if (this.mode === FilterCriteriaMode.number) {
      const value = utils.isDefined(this.numberVal) ? this.numberVal.toString().trim().toLowerCase() : this.numberVal
      bucketCriteriaValue = `${propertyLower}${this.exclude ? '!=' : '='}${value}`
    }
    else if (this.mode === FilterCriteriaMode.numberRange) {
      if (utils.isDefined(this.numberVal) && utils.isDefined(this.numberVal2) && !Number.isNaN(Number(this.numberVal)) && !Number.isNaN(Number(this.numberVal2))) {
        const n1 = Number(this.numberVal)
        const n2 = Number(this.numberVal2)
        const value = n1 < n2 ? `${n1}to${n2}` : `${n2}to${n1}`
        bucketCriteriaValue = `${propertyLower}${this.exclude ? '!=' : '='}${value}`
      }
    }
    else if (this.mode === FilterCriteriaMode.status) {
      const value = utils.isDefined(this.statusVal) ? this.statusVal.toString().trim().toLowerCase() : this.statusVal
      bucketCriteriaValue = `${propertyLower}${this.exclude ? '!=' : '='}${value}`
    }
    else if (this.mode === FilterCriteriaMode.multiString) {
      if (utils.isDefined(this.multipleVals)) { // if not defined there is no way to apply filter in T1SW
        const value = this.multipleVals.reduce((acu, cur) => {
          if (cur != null) {
            acu.push(cur.toString().trim().toLowerCase())
          }
          return acu
        }, []).sort().join(',')
        bucketCriteriaValue = `${propertyLower}${this.exclude ? '!=' : '='}${value}`
      }
    }
    else if (this.mode === FilterCriteriaMode.string) {
      const value = utils.isDefined(this.stringVal) ? this.stringVal.toString().trim().toLowerCase() : this.stringVal
      bucketCriteriaValue = `${propertyLower}${this.exclude ? '!=' : '='}${value}`
    }
    return bucketCriteriaValue
  }

  getCriteriaNameForBuckets() {
    let propertyName = this.attribute.trim().toLowerCase()
    // match with the values from T1S
    if (this.attribute === '_DeliveryDates') {
      propertyName = '_crd'
    }
    else if (this.attribute === '_FavoriteTags') {
      propertyName = 'filterByTags'
    }
    return propertyName
  }

  static attributeTypeToFilterMode(attributeType: AttributeType, filterLookup?: Map<any, any>): FilterCriteriaMode {
    switch (attributeType) {
      case AttributeType.Bool:
      case AttributeType.DateOption: // date option should have dropdown filter
        return FilterCriteriaMode.multiString
      case AttributeType.Date:
        return FilterCriteriaMode.dateRange
      case AttributeType.DateTime:
        return FilterCriteriaMode.dateTimeRange
      case AttributeType.Decimal:
      case AttributeType.Int:
        return FilterCriteriaMode.number
      case AttributeType.Status:
        return FilterCriteriaMode.status
      default:
        return filterLookup && filterLookup.size > 0 ? FilterCriteriaMode.multiString : FilterCriteriaMode.string
    }
  }
}

export function toFilterCriteria(attribute: string, savedViewFilter: ISavedViewFilter, catalogDetails: CatalogDetails): FilterCriteria {
  const filterCriteria = new FilterCriteria({ attribute, multipleVals: savedViewFilter.value, mode: FilterCriteriaMode.multiString, exclude: savedViewFilter.exclude })
  if (savedViewFilter.type === 'date' || savedViewFilter.type === 'datetime' || savedViewFilter.type === 'string') {
    filterCriteria.mode = savedViewFilter.type === 'date'
      ? FilterCriteriaMode.date
      : savedViewFilter.type === 'datetime'
        ? FilterCriteriaMode.dateTime
        : FilterCriteriaMode.string

    filterCriteria.multipleVals = undefined
    filterCriteria.stringVal = savedViewFilter.value
  }
  else if (savedViewFilter.type === 'number') {
    filterCriteria.mode = FilterCriteriaMode.number
    filterCriteria.multipleVals = undefined
    filterCriteria.numberVal = savedViewFilter.value
  }
  else if (savedViewFilter.type === 'numberRange') {
    filterCriteria.mode = FilterCriteriaMode.numberRange
    filterCriteria.multipleVals = undefined
    if (savedViewFilter.value.length > 0) {
      filterCriteria.numberVal = savedViewFilter.value[0]
    }
    if (savedViewFilter.value.length > 1) {
      filterCriteria.numberVal2 = savedViewFilter.value[1]
    }
  }
  else if (savedViewFilter.type === 'status') {
    filterCriteria.mode = FilterCriteriaMode.status
    filterCriteria.multipleVals = undefined
    filterCriteria.statusVal = savedViewFilter.value
  }
  else if (savedViewFilter.type === 'textarea') {
    // As because of issue fixed in PR-8100, earlier textarea is saved as new line seperated string but it has to be array of string.
    // So to make it comaptible need to translate if required
    filterCriteria.stringVal = isArray(savedViewFilter.value) ? savedViewFilter.value.join('\n') : savedViewFilter.value
    filterCriteria.multipleVals = isArray(savedViewFilter.value) ? savedViewFilter.value : savedViewFilter.value.split('\n')
  }
  else if (attribute === '_Seasons') {
    if (savedViewFilter.value && savedViewFilter.value.length) {
      filterCriteria.multipleVals = []
      const valueSet = new Set(savedViewFilter.value.map(v => v.toLowerCase()))
      catalogDetails.LinkedCatalogList.forEach((linkedCatalog) => {
        if (valueSet.has(`${linkedCatalog.Season}${linkedCatalog.CatalogCode}`.toLowerCase())) {
          filterCriteria.multipleVals!.push(linkedCatalog.CatalogCode)
        }
      })
    }
  }
  else if (filterCriteria.multipleVals && filterCriteria.multipleVals.length) {
    filterCriteria.multipleVals = filterCriteria.multipleVals.map(x => x.toLowerCase())
  }
  return filterCriteria
}
