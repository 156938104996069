<template>
  <tx-dialog v-model="visible" :title="t('generateDialog.title', { action: props.isMerch ? 'Slides' : 'Frames' })" width="50%" height="70%" @closed="onClose()">
    <div class="flex flex-col w-full h-full">
      <!-- Steps -->
      <div class="px-6 py-4 bg-gray-100 border-t border-gray-200">
        <div class="flex justify-between">
          <template v-for="(step, index) in steps" :key="step">
            <div class="flex flex-col items-center">
              <div
                class="flex items-center justify-center w-6 h-6 border-2 rounded-full"
                :class="[index <= currentStepIndex ? 'bg-blue-500 text-white border-blue-500' : 'border-gray-400']"
                v-text="index + 1"
              />
              <div class="text-sm" v-text="step" />
            </div>
            <div
              v-if="index < steps.length - 1" class="flex-1 h-2 mx-1 mt-3"
              :class="[index < currentStepIndex ? 'bg-blue-500' : 'bg-gray-400']"
            />
          </template>
        </div>
      </div>
      <!-- Content -->
      <div class="flex-1 w-full h-full mt-4 overflow-y-auto">
        <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
        <!-- Step 1: Template & Source -->
        <div v-show="currentStepIndex === 0">
          <div class="text-base">
            {{ !utils.isDefined(externalSourceDataObject) ? t('generateDialog.steps.template.desc') : t('generateDialog.steps.template.externalSourceDescription') }}
          </div>
          <div v-if="allowedTemplates && allowedTemplates.length" class="grid items-center grid-cols-4 gap-4 mt-4">
            <label class="text-xs tracking-wide uppercase label required" v-text="t('generateDialog.steps.template.title')" />
            <tx-select
              v-model="form.templateId" class="col-span-3" :data="allowedTemplates" value-prop="id" display-prop="name" required :sort-list="false"
              filterable :errors="v$.templateId.$errors" @change="onTemplateChange" @blur="v$.templateId.$touch"
            />

            <label v-if="!utils.isDefined(externalSourceDataObject)" class="text-xs tracking-wide uppercase label required">{{ t('generateDialog.steps.template.source') }}
              <div v-if="articlesLimit">(Limit {{ articlesLimit }})</div>
            </label>
            <div v-if="!utils.isDefined(externalSourceDataObject)" class="grid grid-cols-3 col-span-3 gap-4">
              <label v-if="form.templateId !== merchConstants.slideGenTemplatesId.browseByAttribute"><input v-model="form.source" type="radio" value="full"><span class="ml-1">{{ t('generateDialog.steps.template.sourceFull') }}</span></label>
              <label v-if="form.templateId !== merchConstants.slideGenTemplatesId.browseByAttribute"><input v-model="form.source" type="radio" value="fav"><span class="ml-1">{{ t('generateDialog.steps.template.sourceFav') }}</span></label>
              <label v-if="form.templateId !== merchConstants.slideGenTemplatesId.browseByAttribute"><input v-model="form.source" type="radio" value="list"><span class="ml-1">{{ t('generateDialog.steps.template.sourceList') }}</span></label>
              <label v-if="form.templateId !== merchConstants.slideGenTemplatesId.browseByAttribute"><input v-model="form.source" type="radio" value="modelList"><span class="ml-1">{{ t('generateDialog.steps.template.sourceModelList') }}</span></label>
              <label v-if="form.templateId !== merchConstants.slideGenTemplatesId.browseByAttribute"><input v-model="form.source" type="radio" value="excel"><span class="ml-1">{{ t('generateDialog.steps.template.sourceExcel') }}</span></label>
              <label><input v-model="form.source" type="radio" value="savedViews"><span class="ml-1">{{ t('generateDialog.steps.template.sourceSaveViews') }}</span></label>
            </div>
          </div>
        </div>
        <!-- Step 2: Articles -->
        <div v-show="currentStepIndex === 1">
          <div class="text-base">
            {{ getArticlesLabelDesc }}
          </div>
          <div class="w-full h-full my-2">
            <tx-select
              v-if="form.source === 'fav'"
              v-model="form.sourceList"
              :label="t('generateDialog.steps.template.sourceFav')"
              :data="availableFavorites"
              value-prop="Id"
              display-prop="Tag"
              secondary-display-prop="CreatedByUserName"
              required
              clearable
              filterable
              multiple-values
            />
            <tx-input
              v-else-if="form.source === 'list'"
              v-model="form.sourceText"
              style="height: 100px;"
              type="textarea"
              :label="t('generateDialog.steps.template.sourceList')"
              :placeholder="t('generateDialog.steps.template.sourceList')"
              required
            />
            <tx-input
              v-else-if="form.source === 'modelList'"
              v-model="form.sourceText"
              style="height: 100px;"
              type="textarea"
              :label="t('generateDialog.steps.template.sourceModelList')"
              :placeholder="t('generateDialog.steps.template.sourceModelList')"
              required
            />
            <div v-else-if="form.source === 'excel'">
              <file-upload
                v-model="form.sourceFile"
                file-format-hint="Excel (xlsx)"
                accept-format=".xlsx"
                @change="onFileChange"
              />
            </div>
            <tx-select
              v-else-if="form.source === 'savedViews'"
              v-model="form.sourceList"
              :label="t('generateDialog.steps.template.sourceSaveViews')"
              :data="availableSavedViews"
              value-prop="Id"
              display-prop="FullDisplayName"
              secondary-display-prop="Type"
              required
              clearable
              filterable
              multiple-values
            />
          </div>
        </div>
        <!-- Step 3: Map -->
        <div v-show="currentStepIndex === 2">
          <div class="text-base">
            {{ t('generateDialog.steps.mapping.desc') }}
          </div>
          <file-mapping-editor
            v-show="excelWorkbook" ref="refTemplateFileMappingEditor" v-model="form.mapping" :is-merch="props.isMerch"
            :template-id="form.templateId" :excel-workbook="excelWorkbook" :current-step="currentStepIndex" :validation-step="2"
          />
        </div>
        <!-- Step 4: Options -->
        <div v-show="currentStepIndex === 3">
          <div class="text-base">
            {{ t('generateDialog.steps.options.desc', { action: props.isMerch ? 'slides' : 'frames' }) }}
          </div>
          <!-- TODO: Need to work on predefined options -->
          <!-- Options -->

          <parent-folder-editor v-if="currentStepIndex === 3 && props.isMerch" v-model="selectedFolderLabel" :merch="props.merch" :slide-tree="props.slideTree" @update="folderSelectedForSlides" />
          <options-editor
            ref="refTemplateOptionsEditor" :template-id="form.templateId" :format="props.isMerch ? 'slides' : 'frames'" :is-merch="props.isMerch" :show-one-slide-per-style="props.isMerch && form.source === 'modelList'" :source="form.source" :mapping="form.mapping"
            :external-source-data-object="externalSourceDataObject" :current-step="currentStepIndex" :validation-step="3" @update="updateFormOptions"
          />
        </div>
        <!-- Finish -->
        <div v-show="currentStepIndex === 4">
          <tx-alert :show="showExceedLimitWarning" type="warning" :text="form.source === 'modelList' ? t('generateDialog.limitWarningForModel', { articlesLimit }) : t('generateDialog.limitWarningForArticle', { articlesLimit }) " dismissible />
          <div class="text-base">
            {{ t('generateDialog.steps.finish.desc', { action: props.isMerch ? 'slides' : 'frames' }) }}
          </div>
          <table class="min-w-full mt-4">
            <tbody class="divide-y divide-gray-200">
              <tr>
                <td class="px-4 py-2 font-medium text-gray-700 whitespace-nowrap">
                  {{ t('generateDialog.steps.template.title') }}:
                </td>
                <td v-if="((props.isMerch && merchTemplates[form.templateId]) || templates[form.templateId])" class="px-4 py-2 text-gray-500">
                  {{ props.isMerch ? merchTemplates[form.templateId].name : templates[form.templateId].name }}
                </td>
              </tr>
              <tr>
                <td class="px-4 py-2 font-medium text-gray-700 whitespace-nowrap">
                  {{ t('generateDialog.steps.template.source') }}:
                </td>
                <td class="px-4 py-2 text-gray-500">
                  {{ sourceDisplayName }}
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="isListIncludeInvalidArticle" class="p-2">
            <div v-if="isConsiderAllSeasonsData">
              <font-awesome-icon class="w-6 h-6 mr-2 text-red-500" icon="fa-light fa-warning" />
              <span class="text-red-500">
                {{ form.source === 'modelList' ? t('generateDialog.invalidNumberErrorModel') : t('generateDialog.invalidNumberErrorArticle') }}

              </span>
            </div>
            <div v-else>
              <font-awesome-icon class="w-6 h-6 mr-2 text-red-500" icon="fa-light fa-warning" />
              <span class="text-red-500">
                {{ form.source === 'modelList' ? t('generateDialog.invalidOrNotExistInCurrentSeasonNumberErrorModel') : t('generateDialog.invalidOrNotExistInCurrentSeasonNumberErrorArticle') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex items-center justify-center px-6 py-4 space-x-3 sm:justify-end bg-gray-50">
        <tx-button
          type="cancel" :disabled="currentStepIndex <= 0" :loading="loading"
          :text="t('general.back')" width="113px" height="40px" @click="onBack"
        />
        <tx-button
          v-show="currentStepIndex < (steps.length - 1)" type="confirm" :loading="loading" :text="t('general.next')"
          width="113px" height="40px" :disabled="v$.$invalid" @click="onNext"
        />
        <tx-button
          v-show="currentStepIndex >= (steps.length - 1)" class="bg-green-800" type="confirm" :loading="loading" :text="t('general.finish')"
          width="113px" height="40px" @click="onFinish"
        />
      </div>
    </template>
  </tx-dialog>
</template>

<script setup lang="ts">
import { Workbook } from 'exceljs'
import useVuelidate from '@vuelidate/core'
import { createI18nMessage, required } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import { computed, reactive, ref, watch } from 'vue'
import { clone } from 'lodash-es'
import ParentFolderEditor from '@/modules/merch/components/ParentFolderEditor.vue'
import templates from '@/modules/whiteboard/services/templates'
import merchTemplates from '@/modules/merch/services/templates'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import TxButton from '@/shared/components/TxButton.vue'
import TxSelect from '@/shared/components/TxSelect.vue'
import TxInput from '@/shared/components/TxInput.vue'
import FileUpload from '@/shared/components/FileUpload.vue'
import FileMappingEditor from '@/shared/components/templates/TemplateFileMappingEditor.vue'
import OptionsEditor from '@/shared/components/templates/TemplateOptionsEditor.vue'
import type FavoriteTag from '@/models/favoriteTag'
import type SavedView from '@/models/savedView'
import type Article from '@/models/article'
import type MyArticle from '@/models/myArticle'
import { FilterCriteria, FilterCriteriaMode, toFilterCriteria } from '@/models/filterCriteria'
import utils from '@/services/utils'
import useFilterableAttributes from '@/shared/composables/filterableAttributes'
import useErrorMessage from '@/shared/composables/errorMessage'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'
import { merchConstants, savedViewConstants, whiteboardConstants } from '@/models/constants'
import type Merch from '@/modules/merch/services/merch'
import type Whiteboard from '@/modules/whiteboard/services/whiteboard'
import { useNotificationStore } from '@/store/notification'
import { type IDataObject, useBrowseStore } from '@/store/browse'
import { getIndexedBucketValues } from '@/services/browseByFactory'
import { getArticlePropertyValue, getArticleSeasonValue, getSeasonsInfo, sortArticles } from '@/services/catalogFactory'
import type { IBucketGroupColumnData, IBucketGroupData, ISavedViewData } from '@/models/generateData'
import type { IMerchTemplate } from '@/modules/merch/services/templates/IMerchTemplate'

interface IGenerateFrameForm {
  templateId: number
  source: 'full' | 'fav' | 'list' | 'modelList' | 'excel' | 'savedViews' | 'browseByAttributeExternal' | 'external'
  sourceList: number[]
  sourceText: string
  sourceFile: null | File
  mapping: Record<string, any>
  options: Record<string, any>
}
interface ITemplateData {
  articles: MyArticle[]
  excelData: Record<string, Record<string, string[]>> | undefined
  savedViewsData: Record<string, ISavedViewData> | undefined
  saveViewVLBData: any[] | undefined
}
const props = withDefaults(defineProps<IProps>(), { isMerch: true })
const emit = defineEmits<{
  (e: 'update:modelValue', val: boolean): void
  (e: 'createFolder', name: string, parentFolderName: string): void
}>()
const initialTemplateDataState: ITemplateData = {
  articles: [],
  excelData: undefined,
  savedViewsData: undefined,
  saveViewVLBData: undefined,
}
interface IProps {
  modelValue: boolean
  slideTree?: ITreeNode[]
  isMerch?: boolean
  merch?: Merch | undefined
  whiteboard?: Whiteboard
}
const { t } = useI18n()
const userStore = useUserStore()
const browseStore = useBrowseStore()
const notificationStore = useNotificationStore()
const { filterableAttributesByAttributeSystemName } = useFilterableAttributes()
const { errorMessage, hasError } = useErrorMessage()
const withI18nMessage = createI18nMessage({ t })

const visible = ref(false)
const loading = ref(false)
const steps = ['Template', 'Articles', 'Map Columns', 'Options', 'Finish']
const currentStepIndex = ref(0)
const availableFavorites = ref<FavoriteTag[]>([])
let allSavedViews: SavedView[] = []
const excelWorkbook = ref<Workbook>()
const refTemplateFileMappingEditor = ref<InstanceType<typeof FileMappingEditor>>()
const refTemplateOptionsEditor = ref<InstanceType<typeof OptionsEditor>>()
const showExceedLimitWarning = ref(false)
const templateData = reactive<ITemplateData>(initialTemplateDataState)
const isConsiderAllSeasonsData = ref(true)
let externalSourceDataObject: IDataObject | undefined
const isListIncludeInvalidArticle = ref(false)
const allowedTemplates = computed(() => {
  if (props.isMerch) {
    const templates: IMerchTemplate[] = []
    if (userStore.activeCatalog) {
      const merchTemplateConfig = userStore.activeCatalog.Config.AllowedMerchTemplates
      if (utils.isDefined(merchTemplateConfig) && merchTemplateConfig.length) {
        merchTemplateConfig.forEach((templateId) => {
          if (utils.isDefined(merchTemplates[templateId])) {
            templates.push(merchTemplates[templateId])
          }
        })
      }
    }
    return templates
  }
  else {
    return Object.values(templates)
  }
})

const selectedFolderLabel = computed(() => {
  if (props.slideTree && props.slideTree.length && props.slideTree[0]?.key) {
    return props.slideTree[0].key
  }
  else {
    return ''
  }
})

const articlesLimit = computed(() => {
  let limit = 300
  if (userStore.activeCatalog) {
    limit = userStore.activeCatalog?.Config.GenerateFrameArticlesLimit
    if (props.isMerch) {
      limit = userStore.activeCatalog?.Config.GenerateSlideArticlesLimit
    }
  }
  return limit
})
const initialFormState: IGenerateFrameForm = {
  templateId: 5,
  source: 'full',
  sourceList: [],
  sourceText: '',
  sourceFile: null,
  mapping: {},
  options: {},
}

const form = reactive<IGenerateFrameForm>({ ...initialFormState })

const sourceDisplayName = computed(() => {
  const sourceMapping = {
    full: t('generateDialog.steps.template.sourceFull'),
    fav: t('generateDialog.steps.template.sourceFav'),
    list: t('generateDialog.steps.template.sourceList'),
    modelList: t('generateDialog.steps.template.sourceModelList'),
    excel: t('generateDialog.steps.template.sourceExcel'),
    savedViews: t('generateDialog.steps.template.sourceSaveViews'),
  }

  return sourceMapping[form.source] || form.source
})

const getArticlesLabelDesc = computed(() => {
  return form.source === 'excel'
    ? t('generateDialog.steps.articles.descFile', { action: props.isMerch ? 'slides' : 'frames' })
    : form.source === 'fav'
      ? t('generateDialog.steps.articles.descFav', { action: props.isMerch ? 'slides' : 'frames' })
      : form.source === 'list'
        ? t('generateDialog.steps.articles.descList', { action: props.isMerch ? 'slides' : 'frames' })
        : form.source === 'modelList'
          ? t('generateDialog.steps.articles.descModelList', { action: props.isMerch ? 'slides' : 'frames' })
          : form.source === 'savedViews'
            ? t('generateDialog.steps.articles.savedView', { action: props.isMerch ? 'slides' : 'frames' })
            : ''
})

const rules = computed(() => {
  const result: Record<string, any> = {
    templateId: { required: withI18nMessage(required) },
    source: { required: withI18nMessage(required) },
    sourceList: {},
    sourceText: {},
    sourceFile: {},
    mapping: {},
    options: {},
  }
  if (currentStepIndex.value === 1) {
    if (form.source === 'fav' || form.source === 'savedViews') {
      result.sourceList = { required: withI18nMessage(required) }
    }
    else if (form.source === 'list' || form.source === 'modelList') {
      result.sourceText = { required: withI18nMessage(required) }
    }
    else if (form.source === 'excel') {
      result.sourceFile = { required: withI18nMessage(required) }
    }
  }
  return result
})

const availableSavedViews = computed(() => {
  let savedViews = allSavedViews
  if (form.templateId === merchConstants.slideGenTemplatesId.browseByAttribute) {
    savedViews = savedViews.filter(savedView => savedView.BrowseByAttribute && savedView.BrowseByAttribute !== '' && savedView.Filter && Object.keys(savedView.Filter).length)
  }
  return savedViews
})
const v$ = useVuelidate(rules, form)

function resetForm() {
  Object.assign(form, initialFormState)
  form.templateId = allowedTemplates.value.length > 0
    ? allowedTemplates.value[0].id
    : 0
  refTemplateFileMappingEditor.value?.reset()
  refTemplateOptionsEditor.value?.reset()
  externalSourceDataObject = undefined
}

async function showDialog(externalDataObject?: IDataObject | null) {
  currentStepIndex.value = 0
  resetForm()
  visible.value = true
  if (userStore.activeCatalog) {
    availableFavorites.value = (await appConfig.DB!.favoriteTags
      .where({ CatalogCode: userStore.activeCatalog.CatalogCode, Status: 1 })
      .filter((tag) => {
        if (tag.CreatedByUserName === userStore.currentUsername) { return true }
        if (tag.SharedUsers && tag.SharedUsers.length > 0) { return true }
        if (tag.SharedUsersGroups && tag.SharedUsersGroups.length > 0) { return true }
        return false
      })
      .toArray()
    ).sort((a, b) => {
      if (a.CreatedByUserName === userStore.currentUsername) { return -1 }
      return a.Tag.localeCompare(b.Tag)
    })
    const mySavedViews = await appConfig.DB!.savedViews
      .where({ CatalogCode: userStore.activeCatalog!.CatalogCode, CreatedBy: userStore.userProfile.Id, IsCatalogLevel: 0, Status: 1 })
      .toArray()

    const catalogSavedViews = await appConfig.DB!.savedViews
      .where({ CatalogCode: userStore.activeCatalog!.CatalogCode, IsCatalogLevel: 1, Status: 1 })
      .toArray()
    allSavedViews = mySavedViews.concat(catalogSavedViews)

    if (externalDataObject) {
      externalSourceDataObject = externalDataObject
      if (externalDataObject.isBrowseByAttribute) {
        form.source = 'browseByAttributeExternal'
        form.templateId = merchConstants.slideGenTemplatesId.browseByAttribute
        // todo options to disable
        onNext()
      }
      else if (externalDataObject && externalDataObject.articles) {
        form.source = 'external'
        const VLBTemplate = allowedTemplates.value.find(template => template.id === merchConstants.slideGenTemplatesId.visualLineBuilder)
        // vlb
        if (externalDataObject.viewName && externalDataObject.viewName.toString().trim() !== '' && VLBTemplate) {
          form.templateId = VLBTemplate.id
        }
      }
    }
  }
}
function folderSelectedForSlides(folder: string, parentNode) {
  form.options.folder = parentNode
}
function onFileChange(file: File | null) {
  form.mapping.sheetId = ''
  if (file) {
    const reader = new FileReader()
    reader.onload = (event: ProgressEvent<FileReader>) => {
      if (event.target && event.target.result) {
        excelWorkbook.value = new Workbook()
        excelWorkbook.value.xlsx.load(event.target.result as ArrayBuffer)
          .then((workbook) => {
            form.mapping.sheetId = workbook.worksheets.length > 0 ? workbook.worksheets[0].id : ''
          })
      }
    }
    reader.readAsBinaryString(file)
  }
}

function onBack() {
  if ((form.source === 'full' || form.source === 'external') && currentStepIndex.value === 3) {
    currentStepIndex.value = 0
  }
  else if (form.source !== 'excel' && currentStepIndex.value === 3) {
    currentStepIndex.value = 1
  }
  else {
    currentStepIndex.value--
  }
}
function onTemplateChange() {
  if (form.templateId === merchConstants.slideGenTemplatesId.browseByAttribute) {
    form.source = 'savedViews'
  }
}
async function onNext() {
  if (currentStepIndex.value >= (steps.length - 1)) { return }
  if (currentStepIndex.value === 0) {
    if (form.source === 'full' || form.source === 'browseByAttributeExternal' || (form.templateId !== merchConstants.slideGenTemplatesId.browseByAttribute && form.source === 'external')) {
      currentStepIndex.value = 3
    }
    else if (form.source === 'external' && form.templateId === merchConstants.slideGenTemplatesId.browseByAttribute) {
      form.source = 'savedViews'
    }
    else {
      currentStepIndex.value++
    }
  }
  else if (form.source !== 'excel' && currentStepIndex.value === 1) {
    currentStepIndex.value = 3
  }
  else if (currentStepIndex.value === 3) {
    loading.value = true
    await getTemplateData()
    loading.value = false
    currentStepIndex.value++
  }
  else {
    currentStepIndex.value++
  }
}
async function generateRequestArticleIndexes(indexKey: string) {
  const indexedRequestArticles: Record<string, Article[]> = {}
  if (userStore.activeCatalog) {
    const requests = await appConfig.DB!.requests.where({ CatalogCode: userStore.activeCatalog.CatalogCode, Status: 1, IsCreateArticleRequest: 1 }).toArray()
    for (const request of requests) {
      if (request.Content) {
        const requestArticle = await utils.getRequestArticle(userStore.activeCatalog!, request, appConfig.DB)
        if (utils.isDefined(requestArticle[indexKey])) {
          if (!indexedRequestArticles[requestArticle[indexKey]!.toString()]) {
            indexedRequestArticles[requestArticle[indexKey]!.toString()] = []
          }
          indexedRequestArticles[requestArticle[indexKey]!.toString()].push(requestArticle)
        }
      }
    }
  }
  return indexedRequestArticles
}
async function getArticlesValidDBData(list: string[], isArticleList: boolean, sortedCatalogCode: number[]) {
  let articles: Article[] = []
  if (isArticleList) {
    const indexedRequestArticleNumberToArticle = await generateRequestArticleIndexes('ArticleNumber')
    const queryCriterion: Array<[number, string]> = []
    const requestArticles: Article[] = []
    for (let index = 0; index < list.length; index++) {
      if (indexedRequestArticleNumberToArticle[list[index]]) {
        requestArticles.push(indexedRequestArticleNumberToArticle[list[index]][0])
      }
      for (let codeIndex = 0; codeIndex < sortedCatalogCode.length; codeIndex++) {
        queryCriterion.push([+sortedCatalogCode[codeIndex], list[index]])
      }
    }
    if (queryCriterion.length) {
      const catalogArticles = await appConfig.DB!.getArticlesByCatalogCodeArticleNumbers(userStore.activeCatalog!, queryCriterion, false, userStore.currentCustomer, userStore.currentCustomerSegmentations)

      if (catalogArticles.length) {
        articles = catalogArticles
        requestArticles.forEach((requestArticle) => {
          const article = catalogArticles.find(catalogArticle => catalogArticle.ArticleNumber === requestArticle.ArticleNumber)
          if (!article) {
            articles.push(requestArticle)
          }
        })
      }
      else {
        articles = requestArticles
      }
    }
    else {
      articles = requestArticles
    }
  }
  else {
    const indexedRequestModelNumberToArticle = await generateRequestArticleIndexes('ModelNumber')

    const queryCriterion: Array<[number, number, string]> = []
    const requestArticles: Article[] = []
    for (let index = 0; index < list.length; index++) {
      if (indexedRequestModelNumberToArticle[list[index]]) {
        requestArticles.push(...indexedRequestModelNumberToArticle[list[index]])
      }
      for (let codeIndex = 0; codeIndex < sortedCatalogCode.length; codeIndex++) {
        queryCriterion.push([+sortedCatalogCode[codeIndex], 1, list[index]])
      }
    }
    if (queryCriterion.length) {
      const catalogArticles = await appConfig.DB!.getArticlesByCatalogCodeStatusModelNumbers(userStore.activeCatalog!, queryCriterion, false, userStore.currentCustomer, userStore.currentCustomerSegmentations)

      if (catalogArticles.length) {
        articles = catalogArticles
        requestArticles.forEach((requestArticle) => {
          const article = catalogArticles.find(catalogArticle => catalogArticle.ArticleNumber === requestArticle.ArticleNumber)
          if (!article) {
            articles.push(requestArticle)
          }
        })
      }
      else {
        articles = requestArticles
      }
    }
    else {
      articles = requestArticles
    }
  }
  articles.sort((a, b) => {
    if (a.ArticleNumber.toLowerCase() === b.ArticleNumber.toLowerCase()) {
      return sortedCatalogCode.indexOf(a.CatalogCode) - sortedCatalogCode.indexOf(b.CatalogCode)
    }
    return a.ArticleNumber.toLowerCase() > b.ArticleNumber.toLowerCase() ? 1 : -1
  })
  if (userStore.activeCatalog && !isConsiderAllSeasonsData.value) {
    articles = articles.filter(article => article.CatalogCode === userStore.activeCatalog!.CatalogCode)
  }
  if (!isListIncludeInvalidArticle.value) {
    for (let i = 0; i < list.length; i++) {
      const foundArticle = articles.find(article => ((isArticleList && article.ArticleNumber === list[i]) || (!isArticleList && article.ModelNumber === list[i])))
      if (!foundArticle) {
        isListIncludeInvalidArticle.value = true
        break
      }
    }
  }
  return articles
}
function getModelsArticlesAsPerLimit(catalogArticles: (Article | MyArticle)[]) {
  const articles: (Article | MyArticle)[] = []
  let counter = 0
  const groupedByModelNumber = catalogArticles.reduce((acc, item) => {
    if (!acc[item.ModelNumber]) {
      if (counter < articlesLimit.value) {
        counter++
        acc[item.ModelNumber] = []
      }
      else {
        showExceedLimitWarning.value = true
      }
    }
    if (counter >= articlesLimit.value) {
      if (acc[item.ModelNumber]) {
        acc[item.ModelNumber].push(item)
      }
    }
    else {
      acc[item.ModelNumber].push(item)
    }
    return acc
  }, {})
  Object.values(groupedByModelNumber).forEach((data) => {
    articles.push(...data as Article[])
  })
  return articles
}
async function getTemplateData() {
  const result: { articles: MyArticle[], excelData: Record<string, Record<string, string[]>> | undefined, savedViewsData: Record<string, ISavedViewData> | undefined, saveViewVLBData: any[] | undefined } = {
    articles: [],
    excelData: undefined,
    savedViewsData: undefined,
    saveViewVLBData: undefined,
  }
  let sortDataList: string[] = []

  let articles: Article[] = []
  isConsiderAllSeasonsData.value = !props.isMerch // in merch consider current catalog only
  isListIncludeInvalidArticle.value = false
  let isModelsGenerating = false
  showExceedLimitWarning.value = false
  if (props.isMerch || ((form.templateId !== whiteboardConstants.frameTemplatesId.standard || (!props.isMerch && form.templateId === whiteboardConstants.frameTemplatesId.standard && !form.options.frameTitle.includes('_Seasons')))
    && (form.templateId !== whiteboardConstants.frameTemplatesId.visualLineBuilder
    || (form.templateId === whiteboardConstants.frameTemplatesId.visualLineBuilder && !form.options.verticalAttributes.includes('_Seasons') && !form.options.horizontalAttributes.includes('_Seasons') && !form.options.groupBy.includes('_Seasons'))))) {
    isConsiderAllSeasonsData.value = false
  }
  if ((!props.isMerch && form.templateId === whiteboardConstants.frameTemplatesId.visualLineBuilder && form.options.groupByModel) || (form.templateId === merchConstants.slideGenTemplatesId.techSheet && form.options.generateOneSlidePerStyle) || form.templateId === merchConstants.slideGenTemplatesId.developmentReview) {
    isModelsGenerating = true
  }
  const sortedCatalogCode = props.isMerch && form.templateId !== merchConstants.slideGenTemplatesId.browseByAttribute ? [] : utils.sort(Object.values(userStore.linkedCatalogDetails), ['SeasonalSequence'], false).map(catalog => catalog.CatalogCode)
  const byPassLimit = false// !isModelsGenerating && props.isMerch && !!form.options.fitToOnePage
  if (userStore.activeCatalog && userStore.myAttributes) {
    sortedCatalogCode.splice(0, 0, userStore.activeCatalog.CatalogCode)
    if (form.source === 'external') {
      if (externalSourceDataObject!.articles && externalSourceDataObject!.articles.length) {
        if (isModelsGenerating) {
          result.articles = getModelsArticlesAsPerLimit(externalSourceDataObject!.articles) as MyArticle[]
        }
        else {
          result.articles = byPassLimit ? externalSourceDataObject!.articles : externalSourceDataObject!.articles.splice(0, articlesLimit.value)
        }
        if (!byPassLimit && externalSourceDataObject!.articles.length > articlesLimit.value) {
          showExceedLimitWarning.value = true
        }
      }
      else {
        console.warn('this should never happen')
      }
    }
    else if (form.source === 'full') {
      if (isModelsGenerating) {
        const catalogArticles = await appConfig.DB!.getArticlesByCatalogCodeStatus(userStore.activeCatalog, 1, userStore.currentCustomer, userStore.currentCustomerSegmentations)
        articles = getModelsArticlesAsPerLimit(catalogArticles)
      }
      else {
        if (byPassLimit) {
          articles = await appConfig.DB!.getArticlesByCatalogCodeStatus(userStore.activeCatalog, 1, userStore.currentCustomer, userStore.currentCustomerSegmentations)
        }
        else {
          articles = await appConfig.DB!.getArticlesByCatalogCodeStatus(userStore.activeCatalog, 1, userStore.currentCustomer, userStore.currentCustomerSegmentations, articlesLimit.value)
          if (userStore.totalArticles && userStore.totalArticles > articlesLimit.value) {
            showExceedLimitWarning.value = true
          }
        }
      }
    }
    else if (form.sourceList.length && form.source === 'fav') {
      const selectedFavs = await appConfig.DB!.favoriteTags.where('Id').anyOf(form.sourceList).toArray()
      const articleIds = new Set<number>()
      if (selectedFavs.length > 0) {
        selectedFavs.forEach((tag) => {
          if (tag.Articles.length > 0) {
            tag.Articles.forEach(artId => articleIds.add(artId))
          }
        })
      }
      if (articleIds.size > 0) {
        if (isModelsGenerating) {
          const catalogArticles = await appConfig.DB!.getArticleCollectionByCatalogCodeArticleIds(userStore.activeCatalog, articleIds, false, userStore.currentCustomer, userStore.currentCustomerSegmentations).then(collection => collection.toArray())
          articles = getModelsArticlesAsPerLimit(catalogArticles)
        }
        else {
          if (byPassLimit) {
            articles = await appConfig.DB!.getArticleCollectionByCatalogCodeArticleIds(userStore.activeCatalog, articleIds, false, userStore.currentCustomer, userStore.currentCustomerSegmentations).then(collection => collection.toArray())
          }
          else {
            articles = await appConfig.DB!.getArticleCollectionByCatalogCodeArticleIds(userStore.activeCatalog, articleIds, false, userStore.currentCustomer, userStore.currentCustomerSegmentations).then(collection => collection.limit(articlesLimit.value).toArray())
            if (articleIds.size > articlesLimit.value) {
              showExceedLimitWarning.value = true
            }
          }
        }
      }
    }
    else if (utils.isValidStringValue(form.sourceText) && form.source === 'list') {
      const articleNumbers = form.sourceText.split(/\r?\n/).map(s => s.trim()).filter((e) => { return e })
      const articleNumbersClone = clone(articleNumbers)
      sortDataList = clone(articleNumbers)
      if (articleNumbers.length > 0) {
        if (isModelsGenerating) {
          const currentData = await getArticlesValidDBData(articleNumbers, true, sortedCatalogCode)
          articles = getModelsArticlesAsPerLimit(currentData)
          articles = sortArticlesSequence(articles, sortDataList)
        }
        else {
          const limit = byPassLimit ? articleNumbersClone.length : articlesLimit.value
          while (articles.length < limit && articleNumbers.length !== 0) {
            let currentData = await getArticlesValidDBData(articleNumbers.splice(0, limit), true, sortedCatalogCode)
            currentData = sortArticlesSequence(currentData, sortDataList)
            articles.push(...currentData.splice(0, limit - articles.length))
          }
          if (articleNumbersClone.length > limit) {
            showExceedLimitWarning.value = true
          }
        }
      }
    }
    else if (utils.isValidStringValue(form.sourceText) && form.source === 'modelList') {
      const modelNumbers = form.sourceText.split(/\r?\n/).map(s => s.trim()).filter((e) => { return e })
      const modelNumbersClone = clone(modelNumbers)
      sortDataList = clone(modelNumbers)
      if (modelNumbers.length > 0) {
        if (isModelsGenerating) {
          const currentData = await getArticlesValidDBData(modelNumbers, false, sortedCatalogCode)
          articles = getModelsArticlesAsPerLimit(currentData)
          articles = sortArticlesSequence(articles, sortDataList)
        }
        else {
          const limit = byPassLimit ? modelNumbersClone.length : articlesLimit.value
          while (articles.length < limit && modelNumbers.length !== 0) {
            let currentData = await getArticlesValidDBData(modelNumbers.splice(0, limit), false, sortedCatalogCode)
            currentData = sortArticlesSequence(currentData, sortDataList)
            articles.push(...currentData.splice(0, limit))
          }
          if (modelNumbersClone.length > limit) {
            showExceedLimitWarning.value = true
          }
        }
      }
    }
    else if (form.sourceList.length && form.source === 'savedViews') {
      const selectedSavedViews = await appConfig.DB!.savedViews.where('Id').anyOf(form.sourceList).toArray()
      // const addedArticleIds = new Set<Number>()
      if (selectedSavedViews.length > 0) {
        const data = await getSavedViewData(selectedSavedViews, sortedCatalogCode)
        result.savedViewsData = data.savedViewGroupData
        result.articles = data.articles
        result.saveViewVLBData = data.saveViewVLBData
        if (!byPassLimit && form.templateId !== merchConstants.slideGenTemplatesId.browseByAttribute && form.templateId !== merchConstants.slideGenTemplatesId.visualLineBuilder) {
          if (result.articles.length > articlesLimit.value) {
            showExceedLimitWarning.value = true
          }
          result.articles = result.articles.slice(0, articlesLimit.value)
        }
      }
    }
    else if (utils.isDefined(form.sourceFile) && refTemplateFileMappingEditor.value && excelWorkbook.value && form.mapping.sheetId && form.source === 'excel') {
      const worksheet = excelWorkbook.value.getWorksheet(form.mapping.sheetId)
      if (worksheet) {
        const articleNumbers: string[] = []
        const excelColIndexMap = refTemplateFileMappingEditor.value.excelColumns.reduce((result, value, index) => {
          result[value] = index
          return result
        }, {} as Record<string, number>)
        const mappingColIndices: Record<string, number[]> = {}
        refTemplateFileMappingEditor.value.selectedTemplateFileMapping.forEach((mapping) => {
          mappingColIndices[mapping.name] = []
          if (mapping.multiple && form.mapping[mapping.name] && Array.isArray(form.mapping[mapping.name])) {
            form.mapping[mapping.name].forEach((val) => {
              if (utils.isDefined(excelColIndexMap[val])) {
                mappingColIndices[mapping.name].push(excelColIndexMap[val])
              }
            })
          }
          else if (utils.isDefined(excelColIndexMap[form.mapping[mapping.name]])) {
            mappingColIndices[mapping.name].push(excelColIndexMap[form.mapping[mapping.name]])
          }
        })
        if (mappingColIndices.articleNumber && mappingColIndices.articleNumber.length) {
          const excelData: Record<string, Record<string, string[]>> = {}
          const articleNumberColIndex = mappingColIndices.articleNumber[0] + 1
          worksheet.eachRow((row, rowNumber) => {
            const articleNumberCell = row.getCell(articleNumberColIndex)
            if (rowNumber > 1 && articleNumberCell && articleNumberCell.value) {
              const articleNumber = articleNumberCell.value.toString()
              articleNumbers.push(articleNumber)
              sortDataList.push(articleNumber)
              if (!utils.isDefined(excelData[articleNumber])) { excelData[articleNumber] = {} }
              for (const col in mappingColIndices) {
                if (!utils.isDefined(excelData[articleNumber][col])) { excelData[articleNumber][col] = [] }
                mappingColIndices[col].forEach((c) => {
                  const cell = row.getCell(c + 1)
                  if (cell && cell.value) {
                    excelData[articleNumber][col].push(cell.value.toString())
                  }
                })
              }
            }
          })
          if (articleNumbers.length > 0) {
            const articleNumbersClone = clone(articleNumbers)
            if (isModelsGenerating) {
              const currentData = await getArticlesValidDBData(articleNumbers, true, sortedCatalogCode)
              articles = getModelsArticlesAsPerLimit(currentData)
              articles = sortArticlesSequence(articles, sortDataList)
            }
            else {
              const limit = byPassLimit ? articleNumbersClone.length : articlesLimit.value
              while (articles.length < limit && articleNumbers.length !== 0) {
                let currentData = await getArticlesValidDBData(articleNumbers.splice(0, limit), true, sortedCatalogCode)
                currentData = sortArticlesSequence(currentData, sortDataList)
                articles.push(...currentData.splice(0, limit))
              }
              if (articleNumbersClone.length > limit) {
                showExceedLimitWarning.value = true
              }
            }
          }
          result.excelData = excelData
        }
      }
    }
    else if (form.source === 'browseByAttributeExternal') {
      if (externalSourceDataObject && externalSourceDataObject.articles) {
        // same as browse
        const articles = externalSourceDataObject.articles
        sortArticles(articles, userStore.sortBy, userStore.customSortArticlePropertyAndValues, userStore.myAttributes!, userStore.activeCatalog!)
        const groupData = getBucketGroupDataFromArticles(articles, externalSourceDataObject.browseByAttribute, externalSourceDataObject.rowDivider, externalSourceDataObject.columnDivider)
        const savedViewData: Record<string, ISavedViewData> = {}
        savedViewData._dummy = { groupData, bucketData: externalSourceDataObject.bucketData, title: externalSourceDataObject.title || '' }
        result.savedViewsData = savedViewData
        result.articles = externalSourceDataObject.articles
      }
    }
    if (form.source !== 'savedViews' && form.source !== 'browseByAttributeExternal' && form.source !== 'external') {
      result.articles = await getMyArticlesForArticlesFromDifferentCatalog(articles, sortedCatalogCode)
    }
    if (form.source === 'full' || form.source === 'fav') {
      result.articles = utils.sortMyArticles(result.articles)
    }
  }
  templateData.articles = result.articles
  templateData.excelData = result.excelData
  templateData.savedViewsData = result.savedViewsData
  templateData.saveViewVLBData = result.saveViewVLBData

  // return result
}
async function getMyArticlesForArticlesFromDifferentCatalog(articles: Article[], sortedCatalogCode: number[]) {
  const myArticlesData: MyArticle[] = []

  if (userStore.activeCatalog && userStore.myAttributes) {
    const catalogCodeWiseArticle = new Map()
    articles.forEach((article) => {
      if (!catalogCodeWiseArticle.has(article.CatalogCode)) {
        catalogCodeWiseArticle.set(article.CatalogCode, [article])
      }
      else {
        catalogCodeWiseArticle.get(article.CatalogCode).push(article)
      }
    })
    for (let index = 0; index < sortedCatalogCode.length; index++) {
      const catalogArticles = catalogCodeWiseArticle.get(sortedCatalogCode[index])
      if (catalogArticles && catalogArticles.length) {
        const catalog = userStore.linkedCatalogDetails[sortedCatalogCode[index]] ? userStore.linkedCatalogDetails[sortedCatalogCode[index]] : userStore.activeCatalog
        const catalogMyArticles = await appConfig.DB!.buildMyArticles(catalogArticles, catalog, userStore.linkedCatalogDetails, userStore.myAttributes, userStore.currentUsername, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
        if (catalogMyArticles && catalogMyArticles.length) {
          myArticlesData.push(...catalogMyArticles)
        }
      }
    }
  }
  return myArticlesData
}
function sortArticlesSequence(articles, sortDataList) {
  if (sortDataList.length && utils.isValidStringValue(form.sourceText) && form.source === 'list') {
    articles.sort((a, b) =>
      (sortDataList.indexOf(a.ArticleNumber) - sortDataList.indexOf(b.ArticleNumber))
      || a.ArticleNumber > b.ArticleNumber || -(a.ArticleNumber < b.ArticleNumber))
  }
  else if (sortDataList.length && utils.isValidStringValue(form.sourceText) && form.source === 'modelList') {
    articles.sort((a, b) =>
      (sortDataList.indexOf(a.ModelNumber) - sortDataList.indexOf(b.ModelNumber))
      || a.ModelNumber > b.ModelNumber || -(a.ModelNumber < b.ModelNumber))
  }
  else if (sortDataList.length && utils.isDefined(form.sourceFile) && excelWorkbook.value && form.mapping.sheetId && form.source === 'excel') {
    articles.sort((a, b) =>
      (sortDataList.indexOf(a.ArticleNumber) - sortDataList.indexOf(b.ArticleNumber))
      || a.ArticleNumber > b.ArticleNumber || -(a.ArticleNumber < b.ArticleNumber))
  }
  return articles
}
async function getSavedViewData(savedViews: SavedView[], sortedCatalogCode: number[]) {
  const savedViewGroupData: Record<string, ISavedViewData> = {}
  const saveViewVLBData: any[] = []
  const articles: MyArticle[] = []
  const blankValue = '[Blank]'
  const indexedSeasonsInfo = getSeasonsInfo(userStore.activeCatalog!, userStore.linkedCatalogDetails, blankValue)
  if (userStore.activeCatalog && userStore.myAttributes) {
    for (const savedView of savedViews) {
      const filterCriteria: FilterCriteria[] = []
      const savedViewArticles: MyArticle[] = []
      for (const attribute in savedView.Filter) {
        if (utils.isDefined(filterableAttributesByAttributeSystemName.value[attribute])) {
          filterCriteria.push(toFilterCriteria(attribute, savedView.Filter[attribute], userStore.activeCatalog!))
        }
      }
      if (savedView.FavoriteTags && savedView.FavoriteTags.length !== 0) {
        filterCriteria.push(new FilterCriteria({ attribute: '_FavoriteTags', multipleVals: savedView.FavoriteTags, mode: FilterCriteriaMode.multiString, exclude: false }))
      }
      // if any of the criteria has seasons, then set browseByDetailsMatchCurrentCatalogOnlyFromCriteria to true to avoid getting data from active catalog
      const browseByDetailsMatchCurrentCatalogOnlyFromCriteria = false
      const filteredArticles = await appConfig.DB!.getArticlesByCriteria(userStore.activeCatalog, userStore.myAttributes, filterCriteria, true, userStore.currentUsername, userStore.currentCustomer, userStore.currentCustomerSegmentations, browseByDetailsMatchCurrentCatalogOnlyFromCriteria)
      const myArticles = await getMyArticlesForArticlesFromDifferentCatalog(filteredArticles, sortedCatalogCode)
      myArticles.forEach((article) => {
        if (appConfig.DB!.articleMatchesCriteria(article, userStore.myAttributes!, filterCriteria || [], true, true)) {
          savedViewArticles.push(article)
        }
      })
      if (props.isMerch && form.templateId === merchConstants.slideGenTemplatesId.browseByAttribute) {
        const rowDivider = utils.isDefined(savedView.RowDivider) && savedView.RowDivider.toString().trim().length !== 0 ? savedView.RowDivider : undefined
        const columnDivider = utils.isDefined(savedView.ColumnDivider) && savedView.ColumnDivider.toString().trim().length !== 0 ? savedView.ColumnDivider : undefined
        const browsingBy = utils.isDefined(savedView.BrowseByAttribute) && utils.isDefined(userStore.myAttributes[savedView.BrowseByAttribute]) ? savedView.BrowseByAttribute : ''
        const dummyData = { _dummyRow: { rawValueLower: null as null | string, value: '' } }
        savedViewGroupData[savedView.Id] = { groupData: {}, bucketData: {}, savedView, title: savedView.Name.replace(savedViewConstants.folderPathSeparator, ' > ') }
        savedViewGroupData[savedView.Id].bucketData = await getIndexedBucketValues(userStore.activeCatalog.CatalogCode, filterCriteria, userStore.activeCatalog!, userStore.linkedCatalogDetails!, columnDivider, rowDivider, browsingBy)
        // this.$util.sort(articles, [browsingBy])
        savedViewArticles.forEach((article) => {
          const articleId = article.Id
          const modelNumberIdentifier = utils.isDefined(article.ModelNumber) && article.ModelNumber.toString().trim() !== '' ? String(article.ModelNumber) : Number(article.ArticleId)
          const currentArticleSeasonValue = getArticleSeasonValue(article, userStore.activeCatalog!, userStore.linkedCatalogDetails)
          const currentAttributePropertyValues = getArticlePropertyValue(article, browsingBy, userStore.myAttributes!, userStore.activeCatalog!, userStore.linkedCatalogDetails!, t, blankValue, currentArticleSeasonValue, indexedSeasonsInfo)

          currentAttributePropertyValues.forEach((currentPropertyValue) => {
            const attributeValueLowerCase = currentPropertyValue.lowerStringOrNull || ''
            let currentRowDividerAttributeValues = {}
            if (utils.isDefined(rowDivider)) {
              const rowDividerValuesInfo = getArticlePropertyValue(article, rowDivider, userStore.myAttributes!, userStore.activeCatalog!, userStore.linkedCatalogDetails!, t, blankValue, currentArticleSeasonValue, indexedSeasonsInfo)
              rowDividerValuesInfo.forEach((info) => {
                currentRowDividerAttributeValues[info.lowerStringOrNull ? info.lowerStringOrNull : ''] = { rawValueLower: info.lowerStringOrNull, value: info.displayValue }
              })
            }
            else {
              currentRowDividerAttributeValues = dummyData
            }
            Object.keys(currentRowDividerAttributeValues).forEach((currentRowDividerAttributeValue) => {
              if (!savedViewGroupData[savedView.Id].groupData[currentRowDividerAttributeValue]) {
                const bucketGroupValue: IBucketGroupData = { rowValue: currentRowDividerAttributeValues[currentRowDividerAttributeValue].value, columns: {} }
                savedViewGroupData[savedView.Id].groupData[currentRowDividerAttributeValue] = bucketGroupValue
              }
            })
            let currentColumnDividerAttributeValues = {}
            if (utils.isDefined(columnDivider)) {
              const colDividerValuesInfo = getArticlePropertyValue(article, columnDivider, userStore.myAttributes!, userStore.activeCatalog!, userStore.linkedCatalogDetails!, t, blankValue, currentArticleSeasonValue, indexedSeasonsInfo)
              colDividerValuesInfo.forEach((info) => {
                currentColumnDividerAttributeValues[info.lowerStringOrNull ? info.lowerStringOrNull : ''] = { rawValueLower: info.lowerStringOrNull, value: info.displayValue }
              })
            }
            else {
              currentColumnDividerAttributeValues = dummyData
            }
            Object.keys(currentColumnDividerAttributeValues).forEach((currentColumnDividerAttributeValue) => {
              Object.keys(currentRowDividerAttributeValues).forEach((currentRowDividerAttributeValue) => {
                if (!savedViewGroupData[savedView.Id].groupData[currentRowDividerAttributeValue].columns[currentColumnDividerAttributeValue]) {
                  const data = {
                    attributeValue: currentPropertyValue.displayValue,
                    attributeRawValueLower: currentPropertyValue.unFormattedValue,
                    currentRowRawValue: currentRowDividerAttributeValues[currentRowDividerAttributeValue].rawValueLower,
                    currentColumnRawValue: currentColumnDividerAttributeValues[currentColumnDividerAttributeValue].rawValueLower,
                    rowValue: currentRowDividerAttributeValues[currentRowDividerAttributeValue].value,
                    columnValue: currentColumnDividerAttributeValues[currentColumnDividerAttributeValue].value,
                    articleIds: [articleId],
                    modelNumbers: [modelNumberIdentifier],
                  }
                  savedViewGroupData[savedView.Id].groupData[currentRowDividerAttributeValue].columns[currentColumnDividerAttributeValue] = {} as IBucketGroupColumnData
                  savedViewGroupData[savedView.Id].groupData[currentRowDividerAttributeValue].columns[currentColumnDividerAttributeValue].columnValue = currentColumnDividerAttributeValues[currentColumnDividerAttributeValue].value
                  savedViewGroupData[savedView.Id].groupData[currentRowDividerAttributeValue].columns[currentColumnDividerAttributeValue].bucketData = {}
                  savedViewGroupData[savedView.Id].groupData[currentRowDividerAttributeValue].columns[currentColumnDividerAttributeValue].bucketData[attributeValueLowerCase] = data
                }
                else {
                  if (!savedViewGroupData[savedView.Id].groupData[currentRowDividerAttributeValue].columns[currentColumnDividerAttributeValue].bucketData[attributeValueLowerCase]) {
                    const data = {
                      attributeValue: currentPropertyValue.displayValue,
                      attributeRawValueLower: currentPropertyValue.unFormattedValue || '',
                      currentRowRawValue: currentRowDividerAttributeValues[currentRowDividerAttributeValue].rawValueLower,
                      currentColumnRawValue: currentColumnDividerAttributeValues[currentColumnDividerAttributeValue].rawValueLower,
                      rowValue: currentRowDividerAttributeValues[currentRowDividerAttributeValue].value,
                      columnValue: currentColumnDividerAttributeValues[currentColumnDividerAttributeValue].value,
                      articleIds: [articleId],
                      modelNumbers: [modelNumberIdentifier],
                    }
                    savedViewGroupData[savedView.Id].groupData[currentRowDividerAttributeValue].columns[currentColumnDividerAttributeValue].bucketData[attributeValueLowerCase] = data
                  }
                  else {
                    if (!savedViewGroupData[savedView.Id].groupData[currentRowDividerAttributeValue].columns[currentColumnDividerAttributeValue].bucketData[attributeValueLowerCase].articleIds.includes(articleId)) {
                      savedViewGroupData[savedView.Id].groupData[currentRowDividerAttributeValue].columns[currentColumnDividerAttributeValue].bucketData[attributeValueLowerCase].articleIds.push(articleId)
                    }
                    if (!savedViewGroupData[savedView.Id].groupData[currentRowDividerAttributeValue].columns[currentColumnDividerAttributeValue].bucketData[attributeValueLowerCase].modelNumbers.includes(modelNumberIdentifier)) {
                      savedViewGroupData[savedView.Id].groupData[currentRowDividerAttributeValue].columns[currentColumnDividerAttributeValue].bucketData[attributeValueLowerCase].modelNumbers.push(modelNumberIdentifier)
                    }
                  }
                }
              })
            })
          })
        })
      }
      else if (props.isMerch && form.templateId === merchConstants.slideGenTemplatesId.visualLineBuilder) {
        // const isBrowseByModel = userStore.activeCatalog.Config.EnableBrowseByModel && savedView.BrowseByAttribute && savedView.BrowseByAttribute !== '' && savedView.BrowseByAttribute === 'ModelNumber'
        const imageScaleFactor = savedView.ImageSize && savedView.ImageSize === 'small' ? merchConstants.slideImageDefaultScaleFactors.small : merchConstants.slideImageDefaultScaleFactors.medium
        let verticalAttributes: string[] = []
        let horizontalAttributes: string[] = []
        if (utils.isDefined(savedView.RowDivider)) {
          if (!Array.isArray(savedView.RowDivider)) {
            verticalAttributes = savedView.RowDivider.toString().trim() !== '' ? [savedView.RowDivider] : []
          }
          else {
            verticalAttributes = savedView.RowDivider
          }
        }
        if (utils.isDefined(savedView.ColumnDivider)) {
          if (!Array.isArray(savedView.ColumnDivider)) {
            horizontalAttributes = savedView.ColumnDivider.toString().trim() !== '' ? [savedView.ColumnDivider] : []
          }
          else {
            horizontalAttributes = savedView.ColumnDivider
          }
        }
        const options = {
          slideTitle: savedView.Name.replace(savedViewConstants.folderPathSeparator, ' > '),
          verticalAttributes,
          horizontalAttributes,
          imageScaleFactor,
        }
        if (articles.length > articlesLimit.value) {
          showExceedLimitWarning.value = true
        }
        const exportData = {
          isCatalogLevel: savedView.IsCatalogLevel,
          options,
          articles: savedViewArticles.slice(0, articlesLimit.value),
        }
        saveViewVLBData.push(exportData)
      }
      articles.push(...savedViewArticles)
    }
  }
  return { articles, savedViewGroupData, saveViewVLBData }
}
function getBucketGroupDataFromArticles(articles, browsingBy, rowDivider, columnDivider) {
  const groupData: Record< string, IBucketGroupData> = {}
  const dummyData = { _dummyRow: { rawValueLower: null as null | string, value: '' } }
  const blankValue = '[Blank]'
  const indexedSeasonsInfo = getSeasonsInfo(userStore.activeCatalog!, userStore.linkedCatalogDetails, blankValue)
  articles.forEach((article) => {
    const articleId = article.Id
    const modelNumberIdentifier = utils.isDefined(article.ModelNumber) && article.ModelNumber.toString().trim() !== '' ? String(article.ModelNumber) : Number(article.ArticleId)
    const currentArticleSeasonValue = getArticleSeasonValue(article, userStore.activeCatalog!, userStore.linkedCatalogDetails)
    const currentAttributePropertyValues = getArticlePropertyValue(article, browsingBy, userStore.myAttributes!, userStore.activeCatalog!, userStore.linkedCatalogDetails!, t, blankValue, currentArticleSeasonValue, indexedSeasonsInfo)

    currentAttributePropertyValues.forEach((currentPropertyValue) => {
      const attributeValueLowerCase = currentPropertyValue.lowerStringOrNull || ''
      let currentRowDividerAttributeValues = {}
      if (utils.isDefined(rowDivider)) {
        const rowDividerValuesInfo = getArticlePropertyValue(article, rowDivider, userStore.myAttributes!, userStore.activeCatalog!, userStore.linkedCatalogDetails!, t, blankValue, currentArticleSeasonValue, indexedSeasonsInfo)
        rowDividerValuesInfo.forEach((info) => {
          currentRowDividerAttributeValues[info.lowerStringOrNull ? info.lowerStringOrNull : ''] = { rawValueLower: info.lowerStringOrNull, value: info.displayValue }
        })
      }
      else {
        currentRowDividerAttributeValues = dummyData
      }
      Object.keys(currentRowDividerAttributeValues).forEach((currentRowDividerAttributeValue) => {
        if (!groupData[currentRowDividerAttributeValue]) {
          const bucketGroupValue: IBucketGroupData = { rowValue: currentRowDividerAttributeValues[currentRowDividerAttributeValue].value, columns: {} }
          groupData[currentRowDividerAttributeValue] = bucketGroupValue
        }
      })
      let currentColumnDividerAttributeValues = {}
      if (utils.isDefined(columnDivider)) {
        const colDividerValuesInfo = getArticlePropertyValue(article, columnDivider, userStore.myAttributes!, userStore.activeCatalog!, userStore.linkedCatalogDetails!, t, blankValue, currentArticleSeasonValue, indexedSeasonsInfo)
        colDividerValuesInfo.forEach((info) => {
          currentColumnDividerAttributeValues[info.lowerStringOrNull ? info.lowerStringOrNull : ''] = { rawValueLower: info.lowerStringOrNull, value: info.displayValue }
        })
      }
      else {
        currentColumnDividerAttributeValues = dummyData
      }
      Object.keys(currentColumnDividerAttributeValues).forEach((currentColumnDividerAttributeValue) => {
        Object.keys(currentRowDividerAttributeValues).forEach((currentRowDividerAttributeValue) => {
          if (!groupData[currentRowDividerAttributeValue].columns[currentColumnDividerAttributeValue]) {
            const data = {
              attributeValue: currentPropertyValue.displayValue,
              attributeRawValueLower: currentPropertyValue.unFormattedValue,
              currentRowRawValue: currentRowDividerAttributeValues[currentRowDividerAttributeValue].rawValueLower,
              currentColumnRawValue: currentColumnDividerAttributeValues[currentColumnDividerAttributeValue].rawValueLower,
              rowValue: currentRowDividerAttributeValues[currentRowDividerAttributeValue].value,
              columnValue: currentColumnDividerAttributeValues[currentColumnDividerAttributeValue].value,
              articleIds: [articleId],
              modelNumbers: [modelNumberIdentifier],
            }
            groupData[currentRowDividerAttributeValue].columns[currentColumnDividerAttributeValue] = {} as IBucketGroupColumnData
            groupData[currentRowDividerAttributeValue].columns[currentColumnDividerAttributeValue].columnValue = currentColumnDividerAttributeValues[currentColumnDividerAttributeValue].value
            groupData[currentRowDividerAttributeValue].columns[currentColumnDividerAttributeValue].bucketData = {}
            groupData[currentRowDividerAttributeValue].columns[currentColumnDividerAttributeValue].bucketData[attributeValueLowerCase] = data
          }
          else {
            if (!groupData[currentRowDividerAttributeValue].columns[currentColumnDividerAttributeValue].bucketData[attributeValueLowerCase]) {
              const data = {
                attributeValue: currentPropertyValue.displayValue,
                attributeRawValueLower: currentPropertyValue.unFormattedValue || '',
                currentRowRawValue: currentRowDividerAttributeValues[currentRowDividerAttributeValue].rawValueLower,
                currentColumnRawValue: currentColumnDividerAttributeValues[currentColumnDividerAttributeValue].rawValueLower,
                rowValue: currentRowDividerAttributeValues[currentRowDividerAttributeValue].value,
                columnValue: currentColumnDividerAttributeValues[currentColumnDividerAttributeValue].value,
                articleIds: [articleId],
                modelNumbers: [modelNumberIdentifier],
              }
              groupData[currentRowDividerAttributeValue].columns[currentColumnDividerAttributeValue].bucketData[attributeValueLowerCase] = data
            }
            else {
              if (!groupData[currentRowDividerAttributeValue].columns[currentColumnDividerAttributeValue].bucketData[attributeValueLowerCase].articleIds.includes(articleId)) {
                groupData[currentRowDividerAttributeValue].columns[currentColumnDividerAttributeValue].bucketData[attributeValueLowerCase].articleIds.push(articleId)
              }
              if (!groupData[currentRowDividerAttributeValue].columns[currentColumnDividerAttributeValue].bucketData[attributeValueLowerCase].modelNumbers.includes(modelNumberIdentifier)) {
                groupData[currentRowDividerAttributeValue].columns[currentColumnDividerAttributeValue].bucketData[attributeValueLowerCase].modelNumbers.push(modelNumberIdentifier)
              }
            }
          }
        })
      })
    })
  })
  return groupData
}
async function onFinish() {
  if (props.isMerch && userStore) {
    if (merchTemplates[form.templateId] && userStore.activeCatalog) {
      errorMessage.value = ''
      loading.value = true
      if (templateData.articles.length > 0) {
        const bucketAttributeList = userStore.activeCatalog?.BucketAttributeList.filter(attribute => attribute.Status === 1 && attribute.Visible)
        const response = await merchTemplates[form.templateId].generate(userStore.activeCatalog, templateData.articles, form.options, props.merch, userStore.myAttributes!, userStore.currentUsername, templateData.excelData, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet, templateData.saveViewVLBData, templateData.savedViewsData, externalSourceDataObject, bucketAttributeList)
        notificationStore.addNotification({ message: t('generateDialog.steps.messages.success', { action: props.isMerch ? 'slides' : 'frames', numberOf: response }), type: 'Success' })
        visible.value = false
      }
      else {
        notificationStore.addNotification({ message: t('generateDialog.steps.messages.failed'), type: 'Warning' })
        errorMessage.value = 'Unable to generate since there is no valid article'
      }
    }
  }
  else {
    if (props.whiteboard && templates[form.templateId] && userStore.activeCatalog && userStore.myAttributes) {
      errorMessage.value = ''
      loading.value = true
      if (templateData.articles.length > 0) {
      // const excelData: Record<string, Record<string, string | string[]>>
      //   = { '1252085-001': { 'frameTitle': ['Footwear', 'Male'], 'articlePrice': ['10'] } }
        const startPosition = { x: 0, y: 0 }
        const objects = await templates[form.templateId].generate(userStore.activeCatalog, userStore.linkedCatalogDetails, templateData.articles, form.options, userStore.myAttributes, userStore.currentUsername, startPosition, templateData.excelData, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet, false)
        if (objects.length && props.whiteboard) {
          // We need to first render frame object then children (template returns children before frame)
          props.whiteboard.addObjects(objects.reverse(), true)
        }
        visible.value = false
      }
      else {
        errorMessage.value = 'Unable to generate since there is no valid article'
      }
    }
  }
  loading.value = false
}
function onClose() {
  browseStore.setDataObjectForMerch(null)
}
function updateFormOptions(modelValue) {
  form.options = modelValue
}
watch(() => visible.value, val => emit('update:modelValue', val))

watch(() => form.source, (val) => {
  if (refTemplateOptionsEditor.value) {
    refTemplateOptionsEditor.value.disableOptions.frameTitle = val === 'excel'
    refTemplateOptionsEditor.value.reset()
  }
})

defineExpose({
  showDialog,
})
</script>
