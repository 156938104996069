import { fabric } from 'fabric'
import { v4 as guid } from 'uuid'
import { liveQuery } from 'dexie'
import type { Subscription } from 'dexie'
import { clone, pick } from 'lodash-es'
import type Article from '@/models/article'
import type DuneAsset from '@/models/duneAsset'
import pngLoading from '@/assets/download.png'
import pngNoImage from '@/assets/noimg.png'
import appConfig from '@/services/appConfig'
import { useUserStore } from '@/store/userData'
import utils, { CancelToken } from '@/services/utils'
import { appConstants, merchConstants, privileges } from '@/models/constants'
import { getArticleAssets } from '@/api/t1/article'

interface IMbArticleImageOptions extends fabric.IImageOptions {
  id?: string
  articleId: number
  objectId: string
  isRequest?: boolean
  locked?: boolean
  templateObject?: boolean
  baseScaleFactorY?: number
  baseScaleFactorX?: number
  hotspot?: boolean
  hotspotId?: string
  imageType?: string | null
  mask?: Record<string, any>
  requestWaterMarkDetails?: Record<string, any>
  favColorList?: string[]
}
const editableProps: Record<string, IMbObjectProp> = {
  imageType: { name: 'image type', type: 'imageType' },
  scale: { name: 'size', type: 'scale' },
  locked: { name: 'lock', type: 'lock' },
  addArticleDetails: { name: 'add article details', type: 'addArticleDetails' },
}
export default class MbArticleImage extends fabric.Image implements IMbObject {
  public id: string
  public articleId: number
  public objectId: string
  public isRequest: boolean
  public requestWaterMarkDetails: Record<string, any> = {}
  public favColorList: string[] = []
  public assets: DuneAsset[]
  public type = merchConstants.objectTypes.articleImage
  public highlighted = false
  public locked: boolean
  public imgLoaded: boolean
  public imgLoading: boolean
  public templateObject: boolean
  public imageType: string | null
  public hotspot: boolean
  public hotspotId: string
  public baseScaleFactorY: number
  public baseScaleFactorX: number
  public editableProps = editableProps

  public actions: Record<string, IWObjectActions> = {
    selectSimilar: { action: 'selectSimilar', label: 'Select Similar', faicon: 'fa-light fa-check-double', showInSubMenu: true },
    bringFront: { action: 'bringFront', label: 'Bring to Front', faicon: 'fa-light fa-bring-front', showInSubMenu: true },
    sendBack: { action: 'sendBack', label: 'Send to Back', faicon: 'fa-light fa-send-back', showInSubMenu: true },
    group: { action: 'group', label: 'Group', faicon: 'fa-light fa-object-group', showInSubMenu: true, multiple: true },
    copy: { action: 'copy', label: 'Copy Selection', faicon: 'fa-light fa-copy', showInSubMenu: true },
    align: { action: 'align', label: 'Align', faicon: 'fa-light fa-align-justify', showInSubMenu: true },
    delete: { action: 'delete', label: 'Remove', faicon: 'fa-light fa-trash-can', showInSubMenu: true },
    updateArticle: {
      action: 'updateArticle',
      label: 'Edit Colorways',
      faicon: 'fa-light fa-edit',
      showInSubMenu: (() => {
        const userStore = useUserStore()
        return userStore.userProfile.isValidPrivilege(privileges.article.update)
          && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountDetails.AccountId === userStore.activeCatalog!.AccountId)
      })(),
    },
    updateArticleSegmentation: {
      action: 'updateArticleSegmentation',
      label: 'Edit Segmentations',
      faicon: 'fa-light fa-edit',
      showInSubMenu: (() => {
        const userStore = useUserStore()
        return userStore.userProfile.isValidPrivilege(privileges.article.updateSegmentation)
          && !utils.isDefined(userStore.currentCustomer)
          && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountDetails.AccountId === userStore.activeCatalog!.AccountId)
      })(),
    },
    updateRetailWindow: {
      action: 'updateRetailWindow',
      label: 'Edit Retail Window',
      faicon: 'fa-light fa-edit',
      showInSubMenu: (() => {
        const userStore = useUserStore()
        return userStore.userProfile.isValidPrivilege(privileges.article.updateRetailWindow)
          && userStore.activeCatalog?.IsShippingWindowApply === 1
          && userStore.activeCatalog?.DataSourceTypeId !== appConstants.catalogTypes.inherited
          && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountDetails.AccountId === userStore.activeCatalog!.AccountId)
      })(),
    },
    editFavorites: { action: 'editFavorites', label: 'Edit Favorites', faicon: 'fa-light fa-star', showInSubMenu: true },
  }

  public subscriptions: Array<Subscription>

  constructor(element: string | HTMLImageElement | HTMLCanvasElement | HTMLVideoElement, options: IMbArticleImageOptions, imageScaleFactor?: number, defaultImageType?: string, isLiveObject: boolean = true, showFavorites: boolean = false) {
    super(element, options)
    this.id = options.id || guid()
    this.templateObject = options.templateObject || false
    this.articleId = options.articleId
    this.objectId = options.objectId
    this.isRequest = options.isRequest || false
    this.requestWaterMarkDetails = options.requestWaterMarkDetails || { fontColor: '#5093e16e', waterMarkText: 'Request' }
    this.favColorList = options.favColorList || []
    this.locked = options.locked || false
    this.assets = []
    this.imgLoaded = false
    this.imgLoading = false
    this.subscriptions = []
    this.originX = 'center'
    this.originY = 'center'
    // as of now scaleX and scaleY will be the same, but when image width and height is greater than what set in option wit will re-scale on x axis and y axis individually
    const scaleFactorX = imageScaleFactor || merchConstants.slideImageDefaultScaleFactors.large
    const scaleFactorY = imageScaleFactor || merchConstants.slideImageDefaultScaleFactors.large
    this.imageType = options.imageType || defaultImageType || null
    this.hotspot = options.hotspot || false
    this.hotspotId = options.hotspotId || ''
    this.scaleX = options.scaleX || scaleFactorX
    this.scaleY = options.scaleY || scaleFactorY
    this.baseScaleFactorX = options.baseScaleFactorX || this.scaleX
    this.baseScaleFactorY = options.baseScaleFactorY || this.scaleY
    // TODO in web- while render
    // re-scale images that have width and height grater then given height and width in options
    // This is a temporary fix for not disturbing existing slides image scale
    // if(util.isDefined(options.shouldAllowTemplateBigImagesToResize) && options.shouldAllowTemplateBigImagesToResize) {
    //   if(options && options.hasOwnProperty('width') && img.naturalWidth > options.width) {
    //     const scaleX = options.scaleX || scaleFactorX
    //     scaleFactorX = options.width * scaleX / img.naturalWidth
    //     options.scaleX = scaleFactorX
    //     delete options.width
    //   }
    //   if(options && options.hasOwnProperty('height') && img.naturalHeight > options.height) {
    //     const scaleY = options.scaleY || scaleFactorY
    //     scaleFactorY = options.height * scaleY / img.naturalHeight
    //     options.scaleY = scaleFactorY
    //     delete options.height
    //   }
    // if new object is created , baseFactorX and baseFactorY is undefined , so initialize with scaleX and scaleY else already initialize with Already existing baseFactor's

    // this.set('articleNumber', article.ArticleNumber || '')
    // this.setMask(this.mask)

    // // function to clip (crop) image based on mask
    // this.clipTo = ctx => {
    //   if(this.restore) {
    //     ctx.rect(-1 * (this.width / 2), -1 * (this.height / 2), this.width, this.height)
    //   } else if(util.merchBoardSlideObjectHasMask(this)) {
    //     const myMask = this.get('mask')
    //     ctx.rect(myMask.left, myMask.top, myMask.width, myMask.height)
    //   } else {
    //     let left = -1 * (this.width / 2)
    //     let top = -1 * (this.height / 2)
    //     ctx.rect(left, top, this.width, this.height)
    //   }
    // }
    if (isLiveObject) {
      const userStore = useUserStore()
      const catalogDetails = userStore.activeCatalog
      if (this.isRequest && catalogDetails) {
        const observable = liveQuery(async () => await appConfig.DB!.requests.where({ CatalogCode: catalogDetails.CatalogCode, Id: this.articleId }).toArray())
        const subscription = observable.subscribe(async ([request]) => {
          const article = await utils.getRequestArticle(catalogDetails, request, appConfig.DB)
          if (article != null && article.Status > 0) {
            if (this.opacity !== 1) {
              this.set('opacity', 1)
            }
          }
          else if (this.opacity !== 0.5) {
            this.set('opacity', 0.5)
          }
        })
        this.subscriptions.push(subscription)
      }
      else {
        // non segmented article will have _IsNonSegmented as true
        const articleIds = new Set([this.articleId])
        const observable = liveQuery(async () => appConfig.DB!.getArticleCollectionByCatalogCodeArticleIds(userStore.activeCatalog!, articleIds, true, userStore.currentCustomer, userStore.currentCustomerSegmentations, true).then(collection => collection.toArray()))
        const subscription = observable.subscribe(async ([article]) => {
          if (article != null && article.Status > 0 && !article._IsNonSegmented) {
            if (this.opacity !== 1) {
              this.set('opacity', 1)
              this.canvas?.fire('object:modified', { target: this, articleStatusUpdated: true, statusValue: 1 })
            }
          }
          else if (this.opacity !== 0.5) {
            this.set('opacity', 0.5)
            this.canvas?.fire('object:modified', { target: this, articleStatusUpdated: true, statusValue: 0 })
          }
        })
        this.subscriptions.push(subscription)
        if (showFavorites) {
          const favoritesObservable = liveQuery(async () => await appConfig.DB!.favoriteTags.where('[CatalogCode+CreatedByUserName+Status]').equals([userStore.activeCatalog!.CatalogCode, userStore.currentUsername, 1]).toArray())
          const favSubscription = favoritesObservable.subscribe(async (favoritesResponse) => {
            this.favColorList = []
            if (favoritesResponse != null && favoritesResponse.length > 0) {
              this.favColorList = favoritesResponse.filter(fav => fav.Articles.includes(this.articleId)).map(itm => itm.Color)
            }
            this.canvas?.fire('object:modified', { target: this, ignoreHistory: true })
          })
          this.subscriptions.push(favSubscription)
        }
      }
    }

    this.setLock(this.locked)

    this.stateProperties = this.stateProperties?.concat(['locked'])

    this.on('removed', () => {
      // console.log('unsubscribing from article image observables')
      this.subscriptions.forEach((subscription) => {
        if (subscription && !subscription.closed) {
          subscription.unsubscribe()
        }
      })
    })
  }

  setProp(prop: string, value: any, ignoreHistory: boolean = false) {
    switch (prop) {
      case 'imageType':
        this.set('imageType', value.assetKey)
        this.imgLoaded = false
        this.imgLoading = false
        break
      case 'scale':
        if (value.scale <= 100 && this.width && this.height) {
          const scale = value.scale / 100
          this.set('scaleX', scale)
          this.set('scaleY', scale)
        }
        break
      case 'locked':
        this.set('locked', value.locked)
        this.setLock(value.locked)
        break
      case 'opacity':
        this.set('opacity', value.opacity)
        break
      default:
        console.warn('Attempting to set unsupported MbObjectProp', prop, value)
        return
    }
    this.dirty = true
    this.canvas?.requestRenderAll()
    this.canvas?.fire('object:modified', { target: this, ignoreHistory })
  }

  getProp(prop: string) {
    const result: any = {}
    switch (prop) {
      case 'imageType':
        result.assetKey = this.imageType
        break
      case 'scale':
        result.scale = Math.round((this.scaleX || 1) * 100)
        break
      case 'locked':
        result.lock = this.locked
        break
      default:
        console.warn('Attempting to get unsupported MbObjectProp', prop)
    }
    return result
  }

  setLock(lock: boolean) {
    this.set('lockMovementX', lock)
    this.set('lockMovementY', lock)
    this.set('lockRotation', lock)
    this.set('lockScalingFlip', lock)
    this.set('lockScalingX', lock)
    this.set('lockScalingY', lock)
    this.set('hasControls', !lock)
  }

  override toObject() {
    const propsToPluck = [
      'id',
      'type',
      'top',
      'left',
      'width',
      'height',
      'articleId',
      'objectId',
      'isRequest',
      'angle',
      'scaleX',
      'scaleY',
      'flipX',
      'flipY',
      'locked',
      'templateObject',
      'baseScaleFactorY',
      'baseScaleFactorX',
      'hotspot',
      'hotspotId',
      'mask',
      'imageType',
    ]
    return pick(this, propsToPluck)
  }

  // static fromObject(object: MbArticleImage, callback?: Function) {
  //   // if (object.articleId) {
  //   //   this.loadArticleImageById(object.articleId, object.width || 500, object.height || 500, object).then(v => { callback && callback(v) })
  //   // }
  // }

  static async getImageSrc(articleId: number, objectId: string, isRequest: boolean, width: number, height: number, assets?: DuneAsset[], imageType?: string | null) {
    let src = pngNoImage
    const userStore = useUserStore()
    if (userStore && userStore.activeCatalog && assets && assets.length > 0) {
      let asset = assets[0]
      if (imageType === 'firstImage') {
        const assetsClone = clone(assets)
        utils.sort(assetsClone, ['SortOrder'])
        asset = assetsClone[0]
      }
      else if (imageType === 'newestImage') {
        asset = assets[0]
      }
      else {
        // if requested for specific assetKey then search in _Assets, if does not exist in _Assets it should show placeholder
        if (utils.isValidStringValue(imageType)) {
          const selectedAsset = assets.find(asset => asset.Key === imageType)
          if (selectedAsset) {
            asset = selectedAsset
          }
        }
      }
      src = `${appConfig.AssetsUrl}/assets/content/${asset.StorageFile}?ContextKey=${encodeURIComponent(userStore.activeCatalog.ContextKey)}&f=webp&w=${width}&h=${height}&trim=true`
    }
    else if (userStore && userStore.indexedSilhouetteImages && userStore.activeCatalog?.Config.SilhouetteImagesCriteria) {
      let article: Article | undefined
      if (isRequest) {
        const request = await appConfig.DB!.requests.get({ CatalogCode: userStore.activeCatalog.CatalogCode, Id: articleId })
        if (request && request.Content) {
          article = await utils.getRequestArticle(userStore.activeCatalog, request, appConfig.DB)
        }
      }
      else {
        article = await appConfig.DB!.articles.get({ CatalogCode: userStore.activeCatalog.CatalogCode, Id: articleId })
      }
      if (article) {
        const imageKey = utils.getPlaceHolderImageKey(userStore.activeCatalog.Config.SilhouetteImagesCriteria, article, userStore.indexedSilhouetteImages)
        if (imageKey !== '' && userStore.indexedSilhouetteImages[imageKey] && userStore.activeCatalog.ContextKey) {
          src = `${appConfig.AssetsUrl}/assets/content/${userStore.indexedSilhouetteImages[imageKey].StorageFile}?ContextKey=${encodeURIComponent(userStore.activeCatalog.ContextKey)}&f=webp&w=${width}&h=${height}&trim=true`
        }
      }
    }
    return src
  }

  static loadArticleImage(article: Article, opt?: IMbArticleImageOptions | null, imageSize?: number, showFavorites?: boolean, favColorList?: string[]) {
    return new Promise<MbArticleImage>((resolve) => {
      fabric.util.loadImage(pngLoading, (img) => {
        const userStore = useUserStore()
        const width = opt ? opt.width || 500 : 500
        const height = opt ? opt.height || 500 : 500
        const scaleFactor = imageSize || merchConstants.slideImageDefaultScaleFactors.large
        const o = Object.assign((opt || { width, height }), { articleId: article.Id, objectId: article.CatalogArticleId, isRequest: Boolean(article._IsRequestArticle), requestWaterMarkDetails: utils.getRequestWaterTextAndFontColor(article), favColorList })
        // check if need to set the top and left in case if add the object
        const obj = new MbArticleImage(img, o, scaleFactor, undefined, true, showFavorites)
        obj.imgLoading = true
        getArticleAssets(userStore.activeCatalog!.DuneContext, userStore.activeCatalog!.ContextKey, article.ArticleNumber, userStore.activeCatalog?.Config.NewestImageAssetKeyList)
          .then((assets) => {
            obj.assets = assets
            obj.canvas?.requestRenderAll()
          })
          .finally(() => {
            obj.imgLoaded = false
            obj.imgLoading = false
            obj.opacity = article.Status === 1 && !article._IsNonSegmented ? 1 : 0.5
          })
        resolve(obj)
      })
    })
  }
  //   static async getJsonObject(article: MyArticle, width: number, height: number, scaleValue, opt?: IWbArticleImageOptions) {
  //     const userStore = useUserStore()
  //     const o = Object.assign((opt || { width, height }), { catalogCode: article.CatalogCode, articleId: article.Id })
  //     const catalogDetails = userStore.linkedCatalogDetails[article.CatalogCode] || userStore.activeCatalog

  //     const obj = new WbArticleImage('', o, false).toObject()
  //     if (scaleValue.scale <= 100 && width && height) {
  //       const scale = scaleValue.scale / 100
  //       obj.scaleX = scale
  //       obj.scaleY = scale
  //     }
  //     if (article._Assets && article._Assets.length > 0 && catalogDetails) {
  //       obj.src = `${appConfig.AssetsUrl}/assets/content/${article._Assets[0].StorageFile}?ContextKey=${encodeURIComponent(catalogDetails.ContextKey)}&f=webp&w=${width}&h=${height}&trim=true`
  //     } else if (userStore && userStore.indexedSilhouetteImages && userStore.activeCatalog?.Config.SilhouetteImagesCriteria) {
  //       const imageKey = utils.getPlaceHolderImageKey(userStore.activeCatalog.Config.SilhouetteImagesCriteria, article, userStore.indexedSilhouetteImages)
  //       if (imageKey != '' && userStore.indexedSilhouetteImages[imageKey] && userStore.activeCatalog.ContextKey) {
  //         obj.src = `${appConfig.AssetsUrl}/assets/content/${userStore.indexedSilhouetteImages[imageKey].StorageFile}?ContextKey=${encodeURIComponent(userStore.activeCatalog.ContextKey)}&f=webp&w=${width}&h=${height}&trim=true`
  //       }
  //     }

  //     obj.height = height
  //     obj.width = width
  //     return obj
  //   }
  static async loadArticleImageById(articleId: number, objectId: string, opt: IMbArticleImageOptions, imageSize: number, showFavorites?: boolean) { // addARIndicator: string | undefined, addGHLIndicator: string | undefined, addBAndTIndicator: string | undefined, colorList: Array<string>) {
    const userStore = useUserStore()
    if (userStore && userStore.activeCatalog) {
      if (opt.isRequest) {
        const request = await appConfig.DB!.requests.get({ CatalogCode: userStore.activeCatalog.CatalogCode, Id: articleId })
        if (request && request.Content) {
          const article = await utils.getRequestArticle(userStore.activeCatalog, request, appConfig.DB)
          return await this.loadArticleImage(article, opt, imageSize)
        }
      }
      else {
        let favColorList: string[] = []
        const res = await appConfig.DB!.getMyArticles(userStore.activeCatalog, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, [articleId], userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet, false, userStore.currentCustomer, userStore.currentCustomerSegmentations, true)
        if (res && !(res instanceof CancelToken) && res.length > 0) {
          if (showFavorites) {
            favColorList = res[0]._FavoriteTags.map(tag => tag.Color)
          }
          return await this.loadArticleImage(res[0], opt, imageSize, showFavorites, favColorList)
        }
        // const article = await appConfig.DB!.articles.get({ CatalogCode: userStore.activeCatalog.CatalogCode, Id: articleId })
        // if (article) {
        //   return await this.loadArticleImage(article, opt, imageSize)
        // }
      }
      // TODO for unassorted article
      // return this.loadUnAssortedArticle(obj, this.imageDefaultScaleFactor)
      console.warn('Failed to load article image by Id')
    }
    console.warn('Unable to load Article Image by Id')
    return null
  }

  static getEditableProps() {
    return editableProps
  }

  override _render(ctx: CanvasRenderingContext2D): void {
    super._render(ctx)
    if (!this.imgLoaded && !this.imgLoading) {
      this.imgLoading = true
      MbArticleImage.getImageSrc(this.articleId, this.objectId, this.isRequest, 500, 500, this.assets, this.imageType)
        .then((src) => {
          this.setSrc(src, (img, error) => {
            if (error) {
              this.setSrc(pngNoImage, () => this.canvas?.requestRenderAll())
              console.warn('unable to load WbArticleImage', this, error)
            }
            else {
              this.canvas?.requestRenderAll()
            }
            this.imgLoaded = true
            this.imgLoading = false
          })
        })
    }
    if (this.isRequest) {
      const fontColor = this.requestWaterMarkDetails.fontColor || '#5093e16e'
      const waterMarkText = this.requestWaterMarkDetails.waterMarkText || '#5093e16e'
      const text = waterMarkText.split(' ')
      ctx.lineWidth = 1
      ctx.fillStyle = fontColor
      ctx.font = '50px arial'
      ctx.rotate(-Math.PI / 4)
      ctx.textAlign = 'center'
      if (text.length === 1) {
        ctx.fillText(text, 0, 0)
      }
      else if (text.length === 2) {
        let y = 0
        text.forEach((value, index) => {
          if (index === 0) {
            y = -50
          }
          else if (index === 1) {
            y = 50
          }
          ctx.fillText(value, 0, y)
        })
      }
      else if (text.length === 3) {
        text.forEach((value, index) => {
          let y = 0
          if (index === 0) {
            y = -100
          }
          else if (index === 2) {
            y = 100
          }
          ctx.fillText(value, 0, y)
        })
      }
      else if (text.length === 4) {
        text.forEach((value, index) => {
          let y = 0
          if (index === 0) {
            y = -100
          }
          else if (index === 1) {
            y = -35
          }
          else if (index === 2) {
            y = 35
          }
          else if (index === 3) {
            y = 100
          }
          ctx.fillText(value, 0, y)
        })
      }
      else {
        ctx.fillText(text, 0, 0)
      }
    }
    else if (this.favColorList && this.favColorList.length) {
      const radius = 12
      for (let index = 0; index < this.favColorList.length; index++) {
        const centerX = index % 2 === 0 ? (index / 2 * 32) : -(index / 2 * 32) - 14
        const centerY = (-(this.height || 500) / 2) + 14
        ctx.beginPath()
        ctx.arc(centerX, centerY, radius, 0, (2 * Math.PI), false)
        ctx.fillStyle = this.favColorList[index]
        ctx.fill()
        if (index === 14) {
          break
        }
      }
    }
  }
}

const f: any = fabric
f.MbArticleImage = MbArticleImage
