<template>
  <tx-panel
    v-model="panelVisible" :title="t('general.summary')" :width="900" :height="600" :close-on-esc="false" :show-maximize="true" :show-minimize="true"
    @resize="onPanelResized" @close="doClose"
  >
    <div class="flex flex-col w-full h-full">
      <!-- TOOLBAR -->
      <div class="w-full h-14 p-2 bg-gray-50 border-b">
        <div class="flex items-center">
          <tx-button v-show="currentView === viewsEnum.drillDown" type="icon" faicon="fa-light fa-chevron-left" :text="t('general.back')" @click="onBack" />
          <!-- Title -->
          <div class="ml-1 flex-1 flex items-center gap-2 font-semibold text-md">
            <div class="p-1 border border-solid rounded-md cursor-default text-sky-600 border-sky-600">
              {{ currentView === viewsEnum.drillDown ? filteredArticles.length : sourceArticles.length }} {{ t('general.articles') }}
            </div>
            <div v-show="currentView === viewsEnum.charts" class="p-1 border border-solid rounded-md cursor-default text-sky-600 border-sky-600">
              {{ modelsCount }} {{ t('general.models') }}
            </div>
            <div v-if="drillDownCriteria.attribute && currentView === viewsEnum.drillDown" class="font-semibold">
              {{ `${drillDownCriteria.attribute.DisplayName}: ${drillDownCriteria.val}` }}
            </div>
          </div>
          <div v-show="currentView === viewsEnum.charts">
            <tx-button width="100px" height="35px" :text="t('general.viewAll')" @click="onViewAll" />
          </div>
          <div v-if="currentView === viewsEnum.drillDown" class="flex gap-2">
            <tx-select
              v-model="groupByColDivider" :data="getGroupByOptions('col')" value-prop="articleProperty" display-prop="displayLabel"
              filterable clearable :placeholder="t('general.columnDivider')"
            >
              <template #prefix>
                <font-awesome-icon icon="fa-light fa-columns" class="w-4" />
              </template>
            </tx-select>
            <tx-select
              v-model="groupByRowDivider" :data="getGroupByOptions('row')" value-prop="articleProperty" display-prop="displayLabel"
              filterable clearable :placeholder="t('general.rowDivider')"
            >
              <template #prefix>
                <font-awesome-icon icon="fa-light fa-rows" class="w-4" />
              </template>
            </tx-select>
            <tx-select
              v-model="filterBy" :data="Object.values(filterByOptions)" value-prop="key" display-prop="label"
              filterable clearable multiple-values :placeholder="t('general.typeToFilter')"
            >
              <template #prefix>
                <font-awesome-icon icon="fa-light fa-search" class="w-4" />
              </template>
            </tx-select>
          </div>
        </div>
      </div>

      <div class="p-1 w-full h-full overflow-hidden">
        <!-- Charts -->
        <div v-show="currentView === viewsEnum.charts" class="w-full h-full overflow-auto">
          <tx-alert :show="!sourceArticles.length" type="warning" :text="t('general.noArticleToGenerateSummary')" />

          <div v-if="sourceArticles.length" class="grid gap-4" :class="panelWidth > 650 ? 'grid-cols-2' : 'grid-cols-1'">
            <div v-for="chart in charts" :key="chart.id" class="p-1 m-1 h-64 rounded-lg shadow-md">
              <template v-if="chartsData[chart.id]">
                <pie v-if="chart.type.toLowerCase() === 'pie'" :data="chartsData[chart.id] as PieChartDataType" :options="getChartOptions(chart, chartsData[chart.id])" />
                <bar v-else :data="chartsData[chart.id] as BarChartDataType" :options="getChartOptions(chart, chartsData[chart.id])" />
              </template>
            </div>
          </div>
        </div>
        <!-- Articles -->
        <div v-show="currentView === viewsEnum.drillDown" class="w-full h-full overflow-auto">
          <articles-list :articles="filteredArticles" :allow-selection="false" :column-attribute="groupByColDivider" :row-attribute="groupByRowDivider" />
        </div>
      </div>
    </div>
  </tx-panel>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { Bar, Pie } from 'vue-chartjs'
import type { ChartData, ChartOptions } from 'chart.js'
import { Chart } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { computedAsync } from '@vueuse/core'
import { useUserStore } from '@/store/userData'
import utils from '@/services/utils'
import TxPanel from '@/shared/components/TxPanel.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import TxButton from '@/shared/components/TxButton.vue'
import TxSelect from '@/shared/components/TxSelect.vue'
import ArticlesList from '@/shared/components/ArticlesList.vue'
import type MyArticle from '@/models/myArticle'
import appConfig from '@/services/appConfig'
import { appConstants } from '@/models/constants'
import { AttributeType } from '@/models/catalogAttribute'
import { FilterCriteria, FilterCriteriaMode } from '@/models/filterCriteria'

Chart.register(ChartDataLabels)

type PieChartDataType = ChartData<'pie'>
type BarChartDataType = ChartData<'bar'>

interface IChart {
  id: string
  title?: string
  type: string
  dataRange1: { articleProperty: string }
  dataRange2?: { articleProperty?: string, formula?: string }
  direction: number
}

const viewsEnum = {
  charts: 0,
  drillDown: 1,
}

const validChartTypes = new Set(['vbar', 'hbar', 'pie'])

const { t } = useI18n()
const userStore = useUserStore()

const panelVisible = ref(false)
const panelWidth = ref(800)
const currentView = ref(viewsEnum.charts)
const sourceArticles = ref<MyArticle[]>([])
const drillDownArticles = ref<MyArticle[]>([])
const drillDownCriteria = reactive<{ attribute?: IMyAttribute, val: string }>({ val: '' })

const groupByColDivider = ref('')
const groupByRowDivider = ref('')
const filterBy = ref<string[]>([])

const modelsCount = computed(() => (new Set(sourceArticles.value.map(a => a.ModelId))).size)

const availableAttributes = computed(() => {
  const result: Record<string, IMyAttribute> = {}
  if (userStore.myAttributes) {
    for (const attributeSystemName in userStore.myAttributes) {
      result[attributeSystemName] = userStore.myAttributes[attributeSystemName]
    }
    result._Segmentation = appConstants.staticAttributes._Segmentations
    result._Color = appConstants.staticAttributes.ColorId
    if (userStore.activeCatalog && userStore.activeCatalog.RequestsEnabled) {
      result._requestSource = appConstants.staticAttributes._RequestSource
      result._requestState = appConstants.staticAttributes._RequestState
      result._requestRejectionReason = appConstants.staticAttributes._RequestReason
    }
  }
  return result
})

const charts = computed(() => {
  const result: IChart[] = []
  if (userStore.activeCatalog && userStore.activeCatalog.Config.ArticleSummaryReport && userStore.activeCatalog.Config.ArticleSummaryReport.length) {
    userStore.activeCatalog.Config.ArticleSummaryReport.forEach((summaryReport, index) => {
      if (utils.isValidStringValue(summaryReport.chartType) && validChartTypes.has(summaryReport.chartType.toLowerCase())
        && utils.isDefined(summaryReport.dataRange1) && utils.isValidStringValue(summaryReport.dataRange1.articleProperty)
        && utils.isDefined(availableAttributes.value[summaryReport.dataRange1.articleProperty])) {
        const dataRange1Attribute = availableAttributes.value[summaryReport.dataRange1.articleProperty]
        let dataRange1AttributeLabel = dataRange1Attribute.DisplayName
        if (dataRange1Attribute.SystemName === '_WholesalePrice' && userStore.priceGroups.wholesale) {
          dataRange1AttributeLabel += ` (${userStore.priceGroups.wholesale.CurrencyCode})`
        }
        else if (dataRange1Attribute.SystemName === '_RetailPrice' && userStore.priceGroups.retail) {
          dataRange1AttributeLabel += ` (${userStore.priceGroups.retail.CurrencyCode})`
        }
        else if (dataRange1Attribute.SystemName === '_OutletPrice' && userStore.priceGroups.outlet) {
          dataRange1AttributeLabel += ` (${userStore.priceGroups.outlet.CurrencyCode})`
        }
        let chartTitle = utils.isValidStringValue(summaryReport.chartTitle) ? summaryReport.chartTitle : dataRange1AttributeLabel
        let isValid = true
        if (utils.isDefined(summaryReport.dataRange2)) {
          if (utils.isDefined(summaryReport.dataRange2.articleProperty) && utils.isDefined(availableAttributes.value[summaryReport.dataRange2.articleProperty])) { // if contains articleProperty do not consider formula
            const dataRange2Attribute = availableAttributes.value[summaryReport.dataRange2.articleProperty]
            if (!utils.isValidStringValue(summaryReport.chartTitle)) {
              chartTitle = `${dataRange1AttributeLabel} (Sum of ${dataRange2Attribute.DisplayName})`
            }
          }
          else if (utils.isValidStringValue(summaryReport.dataRange2.formula)) {
            if (!utils.isValidStringValue(summaryReport.chartTitle)) {
              chartTitle = `${dataRange1AttributeLabel} (Sum of a formula)`
            }
          }
          else {
            isValid = false
            console.warn(`Invalid entry in T1S-ArticleSummaryReport configuration, ${summaryReport.dataRange2.articleProperty ? `"${summaryReport.dataRange2.articleProperty}" in dataRange2 is not a valid attribute` : ''}, ${(summaryReport.dataRange2.formula && summaryReport.dataRange2.formula.toString().trim() !== '') ? `"${summaryReport.dataRange2.formula}" in dataRange2 is not a valid formula` : ''}`)
          }
        }
        if (isValid) {
          result.push({
            id: `${summaryReport.dataRange1.articleProperty}-${index}`,
            title: chartTitle,
            type: summaryReport.chartType,
            dataRange1: summaryReport.dataRange1,
            dataRange2: summaryReport.dataRange2,
            direction: utils.isDefined(summaryReport.groupBySortDirection) && summaryReport.groupBySortDirection.trim().toLowerCase() === 'descending' ? -1 : 1,
          })
        }
      }
      else {
        console.warn(`Invalid entry in T1S-ArticleSummaryReport configuration, either "chartType" is missing or contains invalid value or "${summaryReport && summaryReport.dataRange1 ? summaryReport.dataRange1.articleProperty : 'undefined'}" in dataRange1 is not a valid attribute`)
      }
    })
  }
  return result
})

async function getArticlesData(arts: MyArticle[], attribute: IMyAttribute, sortDirection: number) {
  const data: Record<string, MyArticle[]> = {}
  const blankValue = t('general.blankValue')

  for (const article of arts) {
    let propertyValues = await utils.getAttributeTypeSpecificValue(attribute, article, appConfig.DB, userStore.activeCatalog, undefined, true)
    if (typeof propertyValues === 'string' && !utils.isValidStringValue(propertyValues)) {
      propertyValues = blankValue
    }

    if (!Array.isArray(propertyValues)) {
      propertyValues = [propertyValues]
    }

    for (const propertyValue of propertyValues) {
      if (!data.hasOwnProperty(propertyValue)) {
        data[propertyValue] = []
      }
      data[propertyValue].push(article)
    }
  }

  // Sort
  return Object.entries(data).sort(([propertyValueA], [propertyValueB]) => {
    if (propertyValueA === blankValue) { return -1 }
    if (propertyValueB === blankValue) { return 1 }

    switch (attribute.AttributeType) {
      case AttributeType.Int:
      case AttributeType.Decimal:
      {
        const aValue = utils.isValidStringValue(propertyValueA) ? Number(propertyValueA) : 0
        const bValue = utils.isValidStringValue(propertyValueB) ? Number(propertyValueB) : 0
        return (aValue - bValue) * sortDirection
      }
      case AttributeType.Date:
      case AttributeType.DateOption:
      case AttributeType.DateTime:
      case AttributeType.DateUtc:
      {
        const aValue = utils.isDefined(propertyValueA) ? new Date(propertyValueA) : null
        const bValue = utils.isDefined(propertyValueB) ? new Date(propertyValueB) : null
        if (aValue && bValue) {
          if (aValue > bValue) { return 1 * sortDirection }
          if (aValue < bValue) { return -1 * sortDirection }
        }
        return 0
      }
      default:
      {
        const aValue = utils.isDefined(propertyValueA) ? propertyValueA : ''
        const bValue = utils.isDefined(propertyValueB) ? propertyValueB : ''
        if (aValue > bValue) { return 1 * sortDirection }
        if (aValue < bValue) { return -1 * sortDirection }
        return 0
      }
    }
  }).map(([label, articles]) => ({ label, articles }))
}

function openDrillDown(articles: MyArticle[], val: string, attribute?: IMyAttribute) {
  drillDownArticles.value = articles
  drillDownCriteria.val = val
  drillDownCriteria.attribute = attribute
  currentView.value = viewsEnum.drillDown
}

const chartsData = computedAsync(
  async () => {
    const result: Record<string, ChartData<'bar' | 'pie'>> = {}
    for (const chart of charts.value) {
      const attribute = availableAttributes.value[chart.dataRange1.articleProperty]

      // get article data
      const articlesData = await getArticlesData(sourceArticles.value, attribute, chart.direction)

      result[chart.id] = { labels: [], datasets: [] }
      const dataset = {
        data: [] as number[],
        backgroundColor: [] as string[],
        borderColor: [] as string[],
        hoverBorderColor: [] as string[],
        hoverBackgroundColor: [] as string[],
        borderWidth: 1,
      }
      for (const data of articlesData) {
        result[chart.id].labels?.push(data.label)
        if (utils.isDefined(chart.dataRange2) && (utils.isValidStringValue(chart.dataRange2.articleProperty) || utils.isValidStringValue(chart.dataRange2.formula))) {
          let sum = 0
          for (const article of data.articles) {
            if (utils.isDefined(chart.dataRange2.articleProperty)) {
              const dataRange2PropertyValue = article[availableAttributes.value[chart.dataRange2.articleProperty].SystemName]
              sum = Number.isNaN(dataRange2PropertyValue) ? 0 : Number(dataRange2PropertyValue)
            }
            else if (utils.isDefined(chart.dataRange2.formula)) {
              try {
                const evalFunction = `function(data){ return ${chart.dataRange2.formula}}`
                const pattern = /\['(.*?)'\]/g
                let match: RegExpExecArray | null = null
                const formulaData: Record<string, number> = {}
                do {
                  match = pattern.exec(chart.dataRange2.formula)
                  if (match && match.length > 1) {
                    const dataRange2PropertyValue = article[match[1]]
                    formulaData[match[1]] = Number.isNaN(dataRange2PropertyValue) ? 0 : Number(dataRange2PropertyValue)
                  }
                } while (match !== null)
                // eslint-disable-next-line no-new-func
                sum += Function(`'use strict';return (${evalFunction})`)()(data)
              }
              catch (error) {
                console.warn('Error in evaluating formula.', error)
              }
            }
          }
          dataset.data.push(sum)
        }
        else {
          dataset.data.push(data.articles.length)
        }

        if (chart.type.toLowerCase() === 'pie') {
          const r = Math.floor(Math.random() * 255)
          const g = Math.floor(Math.random() * 255)
          const b = Math.floor(Math.random() * 255)
          dataset.backgroundColor.push(`rgba(${r},${g},${b},${0.2})`)
          dataset.borderColor.push(`rgba(${r},${g},${b},${0.35})`)
          dataset.hoverBorderColor.push(`rgba(${r},${g},${b},${1})`)
          dataset.hoverBackgroundColor.push(`rgba(${r},${g},${b},${0.35})`)
        }
        else {
          dataset.backgroundColor.push(`rgb(152,152,152)`)
          dataset.borderColor.push(`rgb(112,112,112)`)
          dataset.hoverBorderColor.push(`rgb(96,96,96)`)
          dataset.hoverBackgroundColor.push(`rgb(112,112,112)`)
        }
      }
      result[chart.id].datasets.push(dataset)
    }
    return result
  },
  {},
)

const filterByOptions = computed(() => {
  const optionsMap: Record<string, { key: string, label: string, children: IKeyLabel[] }> = {}
  for (const chart of charts.value) {
    const chartData = chartsData.value[chart.id]
    if (chartData && chartData.labels && chartData.labels.length) {
      const attribute = availableAttributes.value[chart.dataRange1.articleProperty]
      if (drillDownCriteria.attribute !== attribute) {
        const parent = optionsMap.hasOwnProperty(attribute.SystemName)
          ? optionsMap[attribute.SystemName]
          : { key: attribute.SystemName, label: attribute.DisplayName, children: [] }
        chartData.labels.forEach((itm: any) => {
          const label: string = itm.toString()
          if (parent.children.findIndex(c => c.label === label) < 0) {
            parent.children.push({ key: `${attribute.SystemName}//${label}`, label })
          }
        })
        optionsMap[attribute.SystemName] = parent
      }
    }
  }
  return optionsMap
})

const filteredArticles = computed(() => {
  if (filterBy.value.length) {
    const selectedOptions: Record<string, FilterCriteria> = {}
    filterBy.value.forEach((val) => {
      const attributeSystemName = val.split('//')[0]
      if (filterByOptions.value[attributeSystemName]) {
        const selectedOption = filterByOptions.value[attributeSystemName].children.find(c => c.key === val)
        if (selectedOption) {
          if (!selectedOptions.hasOwnProperty(attributeSystemName)) {
            selectedOptions[attributeSystemName] = new FilterCriteria({
              attribute: attributeSystemName,
              mode: FilterCriteriaMode.multiString,
              exclude: false,
              multipleVals: [],
            })
          }
          const val = selectedOption.label === t('general.blankValue') ? null : selectedOption.label.toLowerCase()
          selectedOptions[attributeSystemName].multipleVals!.push(val)
        }
      }
    })
    return drillDownArticles.value.filter(article =>
      appConfig.DB!.articleMatchesCriteria(article, availableAttributes.value, Object.values(selectedOptions), true, true))
  }
  return drillDownArticles.value
})

function getChartOptions(chart: IChart, chartData: ChartData<'bar' | 'pie'>) {
  const options: ChartOptions<'bar' | 'pie'> = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: async (e, elements) => {
      if (elements.length) {
        const dataIndex = elements[0].index
        const attribute = availableAttributes.value[chart.dataRange1.articleProperty]
        const articlesData = await getArticlesData(sourceArticles.value, attribute, chart.direction)
        if (articlesData[dataIndex]) {
          openDrillDown(articlesData[dataIndex].articles, articlesData[dataIndex].label, attribute)
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: tooltipItem => `${tooltipItem.formattedValue.toLocaleString()}`,
        },
      },
      legend: {
        display: chartData.datasets.length > 1 && utils.isValidStringValue(chartData.datasets[0].label),
      },
      datalabels: {
        formatter: value => value.toLocaleString(),
      },
    },
  }
  if (chart.type.toLowerCase() === 'hbar') {
    options.indexAxis = 'y'
    options.scales = {
      x: {
        type: 'logarithmic',
      },
    }
  }
  else {
    options.scales = {
      y: {
        type: 'logarithmic',
      },
    }
  }
  if (utils.isValidStringValue(chart.title)) {
    options.plugins!.title = { display: true, text: chart.title }
  }
  return options
}

function onPanelResized(width: number, _height: number) {
  panelWidth.value = width
}

function open(articles: MyArticle[]) {
  panelVisible.value = true
  sourceArticles.value = articles
}

function updateArticles(articles: MyArticle[]) {
  sourceArticles.value = []
  if (panelVisible.value) {
    sourceArticles.value = articles
  }
}

function doClose() {
  panelVisible.value = false
}

function onViewAll() {
  openDrillDown(sourceArticles.value, '')
}

function onBack() {
  currentView.value = viewsEnum.charts
}

function getGroupByOptions(dividerCriteria: 'row' | 'col') {
  const res = [] as Array<{ displayLabel: string, articleProperty: string, direction?: string }>
  if (userStore.activeCatalog && userStore.myAttributes) {
    for (let attributeSystemName in userStore.activeCatalog.Config.GroupByAttributes) {
      if (attributeSystemName === '_Segmentation') {
        attributeSystemName = '_Segmentations'
      }
      if (dividerCriteria === 'row') {
        if (userStore.myAttributes.hasOwnProperty(attributeSystemName) && groupByColDivider.value !== attributeSystemName) {
          res.push({ displayLabel: userStore.myAttributes[attributeSystemName].DisplayName, articleProperty: attributeSystemName })
        }
      }
      else {
        if (userStore.myAttributes.hasOwnProperty(attributeSystemName) && groupByRowDivider.value !== attributeSystemName) {
          res.push({ displayLabel: userStore.myAttributes[attributeSystemName].DisplayName, articleProperty: attributeSystemName })
        }
      }
    }
  }
  return res
}

defineExpose({
  visible: panelVisible,
  open,
  updateArticles,
})
</script>
