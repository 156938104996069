// @ts-check

import { clone, padStart } from 'lodash-es'
import type Merch from '../merch'
import MbTextBox from '../textBox'
import { findParentPath } from '../../utils'
import type { IMerchTemplate, IMerchTemplateFileMapping, IMerchTemplateOption } from './IMerchTemplate'
import { merchConstants } from '@/models/constants'
import type CatalogDetails from '@/models/catalogDetails'
import utils from '@/services/utils'
import type MyArticle from '@/models/myArticle'
import type CatalogPriceGroup from '@/models/catalogPriceGroup'
import { AttributeType } from '@/models/catalogAttribute'
import appConfig from '@/services/appConfig'

class SlidesGenStandard implements IMerchTemplate {
  id = merchConstants.slideGenTemplatesId.standard
  name = 'Standard'
  imgSize: number = 150
  titleHeight: number = 0
  imgHorSpacing: number = 40
  imgVerSpacing: number = 20
  slideWidth: number = 1280
  slideHeight: number = 720
  articleDetailsHeight: number = 0
  articleAttributes?: string[]
  groupByArticleAttributes?: string[]
  imageHeight: number = 500
  imageWidth: number = 500
  font: string = 'Arial'
  articleAttributeProp = {
    fontWeight: 'normal',
    fontSize: merchConstants.slideArticleThumbnailFontSizes.large,
    textAlign: 'center',
    fontStyle: 'normal',
  }

  labelHeightAsPerImageScaleFactor = {
    large: 25,
    medium: 20,
    small: 14,
  }

  imageDefaultScaleFactor: number = merchConstants.slideImageDefaultScaleFactors.small
  merchLabelAttributes: IMyAttribute[] = []
  mapOfSelectedArticleAttributesSystemNameToDisplayLabel = new Map()
  fitInOnePageProps = {
    articleAttributeMaxHeight: 0,
    maximumRowInOnePage: 4,
    maximumArticlesToConsiderForOnePage: 250,
    articleMaxHeightText: '',
    scaleX: 1,
    scaleY: 1,
  }

  isMerch: boolean = true
  needOverLapping: boolean = false

  getOptions(catalog: CatalogDetails, myAttributes: Record<string, IMyAttribute>): IMerchTemplateOption[] {
    const attributes: IMyAttribute[] = []
    for (const key in myAttributes) {
      attributes.push(myAttributes[key])
    }
    const options: IMerchTemplateOption[] = []

    options.push({
      name: 'slideTitle',
      label: 'generateDialog.steps.options.groupBy',
      type: 'list',
      default: [],
      required: false,
      clearable: true,
      filterable: true,
      multiple: true,
      multipleLimit: 3,
      data: attributes,
      valueProp: 'SystemName',
      displayProp: 'DisplayName',
    })

    options.push({
      name: 'titleOnEachSlide',
      label: 'generateDialog.steps.options.titleOnEachSlide',
      type: 'bool',
      default: false,
      required: false,
      disabled: true,
    })

    options.push({
      name: 'displayAttributes',
      label: 'generateDialog.steps.options.attributesToDisplay',
      type: 'list',
      default: ['ArticleNumber'],
      required: false,
      clearable: true,
      filterable: true,
      multiple: true,
      data: attributes,
      valueProp: 'SystemName',
      displayProp: 'DisplayName',
    })

    options.push({
      name: 'sortAttributes',
      label: 'generateDialog.steps.options.sortAttributes',
      type: 'list',
      default: [],
      required: false,
      clearable: true,
      filterable: true,
      multiple: true,
      data: attributes,
      valueProp: 'SystemName',
      displayProp: 'DisplayName',
    })

    options.push({
      name: 'fitToOnePage',
      label: 'generateDialog.steps.options.fitToOnePage',
      type: 'bool',
      default: false,
      required: false,
    })

    const imageScales: IKeyLabel[] = []
    for (const key in merchConstants.slideImageDefaultScaleFactors) {
      imageScales.push({ key: merchConstants.slideImageDefaultScaleFactors[key], label: utils.capitalizeFirstLetter(key) })
    }

    options.push({
      name: 'imageScaleFactor',
      label: 'generateDialog.steps.options.imageSize',
      type: 'list',
      default: merchConstants.slideImageDefaultScaleFactors.small,
      required: true,
      multiple: false,
      data: imageScales,
      valueProp: 'key',
      displayProp: 'label',
    })

    options.push({
      name: 'imageType',
      label: 'generateDialog.steps.options.imageType',
      type: 'list',
      default: 'firstImage',
      data: merchConstants.defaultImageType,
      required: false,
      multiple: false,
      valueProp: 'key',
      displayProp: 'value',
    })
    const availableSlideSizes: IKeyLabel[] = []
    for (const key in merchConstants.slideSize) {
      availableSlideSizes.push({ key, label: merchConstants.slideSize[key] })
    }
    options.push({
      name: 'slideSize',
      label: 'generateDialog.steps.options.slideSize',
      type: 'list',
      default: 'wideScreen',
      required: true,
      multiple: false,
      data: availableSlideSizes,
      valueProp: 'key',
      displayProp: 'label',
    })

    return options
  }

  getFileMapping(): IMerchTemplateFileMapping[] {
    const mapping: IMerchTemplateFileMapping[] = []
    mapping.push({
      name: 'articleNumber',
      column: 'ArticleNumber',
      type: 'articleNumber',
      label: 'generateDialog.steps.mapping.articleNumber',
      required: true,
      autoMap: ['article', 'articlenumber', 'article number', 'artnbr', 'style color code', 'colorwaynumber', 'colorway number'],
    })
    mapping.push({
      name: 'slide',
      column: 'Slide Title',
      label: 'generateDialog.steps.mapping.groupBy',
      type: 'list',
      required: false,
      multipleLimit: 3,
      autoMap: [],
    })
    mapping.push({
      name: 'articlePrice',
      column: 'ArticlePrice',
      type: 'list',
      label: 'generateDialog.steps.mapping.articlePrice',
      required: false,
      autoMap: [],
    })
    return mapping
  }

  async generate(catalog: CatalogDetails, articlesData: MyArticle[], options: Record<string, any>, merch: Merch | undefined, myAttributes: Record<string, IMyAttribute>, myUsername: string, excelData?: Record<string, Record<string, string[]>>, retailPg?: CatalogPriceGroup, wholesalePg?: CatalogPriceGroup, outletPg?: CatalogPriceGroup): Promise<(void | number)> {
    const data = new Array<IGroupItem>()
    const indexedKeys = {} as Record<string, any>
    let isCustomArticlePrice: boolean = false
    if (utils.isDefined(excelData)) {
      articlesData.forEach((article) => {
        data.push({
          group: utils.isDefined(excelData[article.ArticleNumber].slide) && excelData[article.ArticleNumber].slide.length ? (excelData[article.ArticleNumber].slide).join('>') : 'Slide',
          article,
          articlePrice: utils.isDefined(excelData[article.ArticleNumber].articlePrice) && excelData[article.ArticleNumber].articlePrice[0] ? Number(excelData[article.ArticleNumber].articlePrice[0]) : 0,
        })
        if (!isCustomArticlePrice && utils.isDefined(excelData[article.ArticleNumber].articlePrice) && excelData[article.ArticleNumber].articlePrice.length) {
          isCustomArticlePrice = true
        }
      })
    }
    else {
      await articlesData.forEach(async (article) => {
        if (options.slideTitle.length) {
          const keys = await this.getKeys(options.slideTitle, article, catalog, myAttributes, myUsername, retailPg, wholesalePg, outletPg)
          for (let index = 0; index < keys.length; index++) {
            const key = keys[index]
            indexedKeys[key.toLowerCase()] = key
            data.push({ group: key.toLowerCase(), article })
          }
        }
        else {
          indexedKeys.slide = 'slide'
          data.push({ group: 'Slide', article })
        }
      })
    }
    return await this.buildTemplate(catalog, data, options, myAttributes, indexedKeys, merch, isCustomArticlePrice, retailPg, wholesalePg, outletPg)
  }

  async getKeys(slideTitle: Array<any>, article: MyArticle, catalog: CatalogDetails, myAttributes: Record<string, IMyAttribute>, myUsername: string, retailPg?: CatalogPriceGroup, wholesalePg?: CatalogPriceGroup, outletPg?: CatalogPriceGroup): Promise<any[]> {
    let keys: any[] = []
    if (myAttributes) {
      for (let index = 0; index < slideTitle.length; index++) {
        const key: any[] = []
        const property = slideTitle[index]
        const emptyValuePlaceHolder = '[Blank]'
        const valueAtPath = article[property]
        if (utils.isDefined(valueAtPath)) {
          if (myAttributes[property] && (myAttributes[property].AttributeType === AttributeType.Date || myAttributes[property].AttributeType === AttributeType.DateOption || myAttributes[property].AttributeType === AttributeType.DateTime)) {
            key.push(valueAtPath.toString().trim() !== '' ? utils.formatDate(valueAtPath) : emptyValuePlaceHolder)
          }
          else if (property.toLowerCase() === 'shipmentstartdate' || property.toLowerCase() === 'shipmentenddate') {
            key.push(valueAtPath.toString().trim() !== '' ? utils.formatDateUTC(valueAtPath) : emptyValuePlaceHolder)
          }
          else if (property.toLowerCase() === '_retailprice') {
            key.push(utils.isDefined(valueAtPath) ? utils.formatPrice(retailPg, Number(valueAtPath), catalog?.Config.ShowPriceThousandsSeparated) : emptyValuePlaceHolder)
          }
          else if (property.toLowerCase() === '_wholesaleprice') {
            key.push(utils.isDefined(valueAtPath) ? utils.formatPrice(wholesalePg, Number(valueAtPath), catalog?.Config.ShowPriceThousandsSeparated) : emptyValuePlaceHolder)
          }
          else if (property.toLowerCase() === '_outletprice') {
            key.push(utils.isDefined(valueAtPath) ? utils.formatPrice(outletPg, Number(valueAtPath), catalog?.Config.ShowPriceThousandsSeparated) : emptyValuePlaceHolder)
          }
          else if (property.toLowerCase() === 'colorid') {
            key.push(utils.isDefined(valueAtPath) ? article.ColorName ? article.ColorName : valueAtPath : emptyValuePlaceHolder)
          }
          else if (myAttributes[property] && myAttributes[property].AttributeType === AttributeType.MultiValue) {
            if (Array.isArray(valueAtPath)) {
              valueAtPath.forEach((value) => {
                key.push(value)
              })
            }
            else {
              key.push(valueAtPath.toString().trim() !== '' ? valueAtPath : emptyValuePlaceHolder)
            }
          }
          else if (property.toLowerCase() === '_favoritetags') {
            article._FavoriteTags.forEach((tagObject) => {
              key.push(tagObject.Tag)
            })
          }
          else if (property.toLowerCase() === '_crds') {
            article._DeliveryDates.forEach((deliveryDate) => {
              if (deliveryDate.Status === 1) {
                // key.push(utils.formatDate(new Date(deliveryDate.CustomerRequiredDate))) //todo where i will find the date
              }
            })
          }
          else if (property.toLowerCase() === '_firstdeliverydate') {
            const date = article.getFirstDeliveryDate(catalog)
            key.push(date && date.toString().trim() !== '' ? utils.formatDate(date) : emptyValuePlaceHolder)
          }
          else if (myAttributes[property] && (myAttributes[property].AttributeType === AttributeType.LinkedCatalogArticleNumber)) {
            const val = await utils.getAttributeTypeSpecificValue(myAttributes[property], article)
            key.push(val.toString() !== '' ? val : emptyValuePlaceHolder)
            // todo need to support ArticleNumber
          }
          else if (myAttributes[property] && (myAttributes[property].AttributeType === AttributeType.ArticleNumber)) {
            const articles = await appConfig.DB!.getMyArticlesById(catalog, {}, myAttributes, myUsername, Number(valueAtPath), retailPg, wholesalePg, outletPg)
            if (articles.length !== 0) {
              key.push(articles[0].ArticleNumber)
            }
            else {
              key.push(emptyValuePlaceHolder)
            }
          }
          else {
            key.push(valueAtPath.toString().trim() !== '' ? valueAtPath.toString().trim() : emptyValuePlaceHolder)
          }
        }
        else {
          key.push(emptyValuePlaceHolder)
        }
        if (index > 0) {
          const combined: any[] = []
          for (let i = 0; i < keys.length; i++) {
            for (let l = 0; l < key.length; l++) {
              combined.push(`${keys[i]} > ${key[l]}`)
            }
          }
          keys = combined
        }
        else {
          keys.push(...key)
        }
      }
    }
    return keys
  }

  changePropsAsPerSlideSize(slideSize: string) {
    this.slideHeight = merchConstants.slideSizeCanvasDimensions[slideSize].height
    this.slideWidth = merchConstants.slideSizeCanvasDimensions[slideSize].width
  }

  resetProps() {
    this.fitInOnePageProps = {
      articleAttributeMaxHeight: 0,
      maximumRowInOnePage: 4,
      maximumArticlesToConsiderForOnePage: 250,
      articleMaxHeightText: '',
      scaleX: 1,
      scaleY: 1,
    }
  }

  async buildTemplate(catalog: CatalogDetails, data: Array<IGroupItem>, options: Record<string, any>, myAttributes: Record<string, IMyAttribute>, indexedKeys: Record<string, any>, merch: Merch | undefined, isCustomArticlePrice: boolean, retailPg?: CatalogPriceGroup, wholesalePg?: CatalogPriceGroup, outletPg?: CatalogPriceGroup) {
    this.resetProps()
    const promises: Promise<void | number>[] = []
    const titleOnEachPage = options.titleOnEachSlide
    const attributes = options.displayAttributes
    const sortAttributes = options.sortAttributes
    const slideSize = options.slideSize
    if (utils.isDefined(options.imageScaleFactor)) {
      this.imageDefaultScaleFactor = options.imageScaleFactor
      this.imgSize = this.imageWidth * this.imageDefaultScaleFactor
      if (this.imageDefaultScaleFactor === merchConstants.slideImageDefaultScaleFactors.small) {
        this.articleAttributeProp.fontSize = merchConstants.slideArticleThumbnailFontSizes.small
      }
      else if (this.imageDefaultScaleFactor === merchConstants.slideImageDefaultScaleFactors.medium) {
        this.articleAttributeProp.fontSize = merchConstants.slideArticleThumbnailFontSizes.medium
      }
      else {
        this.articleAttributeProp.fontSize = merchConstants.slideArticleThumbnailFontSizes.large
      }
    }
    this.findMerchLabelAttributes(catalog, Object.values(myAttributes))

    // create map of attribute system name to label used for calculating height article attributes
    this.createMapForArticleAttributes(attributes)

    // Set slide size as per user selected
    this.changePropsAsPerSlideSize(slideSize)
    const groups = new Map()
    let ttlSlides = 0

    // if we have `_favoriteTags` as slide title/group by, then we will not do the sorting by _favoriteTags
    options.slideTitle.forEach((slideTitle) => {
      if (myAttributes[slideTitle] && myAttributes[slideTitle].AttributeType === AttributeType.MultiValue) {
        const slideTitleIndex = sortAttributes.indexOf(slideTitle)
        sortAttributes.splice(slideTitleIndex, 1)
      }
    })

    // Parse lines into groups
    for (const dataItem of data) {
      if (!groups.has(dataItem.group)) {
        groups.set(dataItem.group, [])
      }
      const group = groups.get(dataItem.group)!
      const articleClone = clone(dataItem.article)
      // Find max height of attributes of Articles
      const articleArticleHeight = await this.getArticleAttributesHeight(catalog, articleClone, attributes, retailPg, wholesalePg, outletPg)
      articleClone._maxHeight = articleArticleHeight.maxHeight ? articleArticleHeight.maxHeight : 0
      if (attributes.length && options.fitToOnePage && this.fitInOnePageProps.articleAttributeMaxHeight < articleClone._maxHeight) {
        this.fitInOnePageProps.articleAttributeMaxHeight = articleClone._maxHeight
        this.fitInOnePageProps.articleMaxHeightText = articleArticleHeight.textForWidth
      }
      // for customPrice
      if (isCustomArticlePrice && utils.isDefined(dataItem.articlePrice)) {
        articleClone._templateCustomPrice = dataItem.articlePrice
        // find height of custom price
        const customPriceHeight = this.getTextBoxHeight(dataItem.articlePrice.toString(), this.articleAttributeProp.fontSize, this.font, this.imgSize)
        articleClone._isCustomPriceHeight = customPriceHeight || 10
      }
      else {
        articleClone._isCustomPriceHeight = 0
      }

      if (options.sortAttributes.length) {
        // add ArticleNumber on top of what user select for sorting
        if (!sortAttributes.includes('ArticleNumber')) {
          sortAttributes.push('ArticleNumber')
        }
        if (articleClone) {
          articleClone._sortOrderString = this.generateSortOrderString(articleClone, options.sortAttributes, myAttributes).toLowerCase()
          utils.insertSorted(articleClone, group, (a, b) =>
            utils.comparer(a, b, ['_sortOrderString']))
        }
      }
      else {
        group.push(articleClone)
      }
    }
    if (options.fitToOnePage) {
      // find maximum in all grouos
      const maximumArticlesInGroup = [...groups.entries()].reduce((a, e) => e[1].length > a[1].length ? e : a)
      const eachCellHeight = Math.floor((this.slideHeight - 60) / this.fitInOnePageProps.maximumRowInOnePage)
      this.findTheScalingFactor(maximumArticlesInGroup[1].length > this.fitInOnePageProps.maximumArticlesToConsiderForOnePage ? this.fitInOnePageProps.maximumArticlesToConsiderForOnePage : maximumArticlesInGroup[1].length, this.fitInOnePageProps.scaleX, this.fitInOnePageProps.scaleY, this.slideWidth, this.slideHeight - (titleOnEachPage ? 60 : 0))
      this.imgSize = this.imgSize * this.fitInOnePageProps.scaleX
      // find the fontsize of the articleDetails
      if (attributes.length) {
        // from the cell height - image size and calculate the font size so that article max height can be accomodated in the remaining space
        this.articleAttributeProp.fontSize = this.findFontSizeDependingOnTheHeight(this.fitInOnePageProps.scaleX, this.articleAttributeProp.fontSize, eachCellHeight)
      }
    }
    if (options.fitToOnePage) {
      // Articles per row
      groups.forEach((articleList, slideName) => {
        let currentSlideNbr = 1
        let currentObjects: any[] = []
        const slideNameValue = indexedKeys[slideName] ? indexedKeys[slideName] : slideName
        // Add title
        if (titleOnEachPage) {
          this.titleHeight = this.getTextBoxHeight(slideNameValue, 51, this.font, this.slideWidth) || 20
          currentObjects.push(this.createTitleObj(slideNameValue))
        }
        else {
          this.titleHeight = 0
        }
        const remainingHeight = this.slideHeight - this.titleHeight
        let currentTop = this.titleHeight
        let maximumNumberOCardsInOneRow = Math.floor(this.slideWidth / (this.imgSize + this.imgHorSpacing))
        const maximumNumberOfCardsInOneColumn = Math.floor((remainingHeight) / (this.imgSize + this.fitInOnePageProps.articleAttributeMaxHeight + this.imgVerSpacing))
        const totalArticlesCanAccommodateInOnePage = maximumNumberOCardsInOneRow * maximumNumberOfCardsInOneColumn
        // it means number of cards is greater than it can be accommodated in one page - need to change the sizing of the cards to accommodate
        let overLappingWidthFactor = 0
        let overlappingObject: IOverlapingObject = { canAccommodate: true, canAccommodateWithoutSpacing: false, overlappingWidthPercentage: 0, maxNumberOfRowsCanBe: 0, overlappingHeight: 0, maxNumberOfCardsCanBeInOneRow: 0 }
        const conisderedArticlesLength = articleList.length > this.fitInOnePageProps.maximumArticlesToConsiderForOnePage ? this.fitInOnePageProps.maximumArticlesToConsiderForOnePage : articleList.length
        if (conisderedArticlesLength > totalArticlesCanAccommodateInOnePage) {
          overlappingObject = this.findNeedsOverLapping(this.slideWidth, remainingHeight, conisderedArticlesLength)
          if (overlappingObject.canAccommodate) {
            maximumNumberOCardsInOneRow = Math.floor((this.slideWidth) / (this.imgSize + this.imgHorSpacing))
          }
          else if (overlappingObject.canAccommodateWithoutSpacing) {
            maximumNumberOCardsInOneRow = Math.floor((this.slideWidth) / this.imgSize)
          }
          else {
            // needs overLapping
            overLappingWidthFactor = overlappingObject.overlappingWidthPercentage
            maximumNumberOCardsInOneRow = overlappingObject.maxNumberOfCardsCanBeInOneRow
          }
        }
        let currentLeft = 0
        articleList = articleList.slice(0, conisderedArticlesLength)
        articleList.forEach((article, index) => {
          if (index === 0) {
            currentTop += this.imgSize / 2
          }
          if (index % maximumNumberOCardsInOneRow === 0 && index !== 0) {
            currentTop += this.imgSize + this.fitInOnePageProps.articleAttributeMaxHeight
          }
          if (index !== 0 && index % maximumNumberOCardsInOneRow !== 0) {
            currentLeft = currentLeft - overLappingWidthFactor
          }
          if (index === 0 || index % maximumNumberOCardsInOneRow === 0) {
            currentLeft = 0
          }
          currentLeft += this.imgSize / 2

          currentObjects.push(
            this.createImage(currentTop, currentLeft, article.Id, options.imageType, this.imgSize / this.imageWidth, this.imgSize / this.imageHeight),
          )
          if (attributes.length !== 0) {
            currentObjects.push(
              this.createLabel(currentTop + this.imgSize / 2, currentLeft - this.imgSize / 2, this.imgSize, attributes, article),
            )
          }
          if (isCustomArticlePrice) {
            currentObjects.push(this.createTextObject(article, currentTop, index % maximumNumberOCardsInOneRow))
          }
          currentLeft += this.imgSize / 2 + (overlappingObject.canAccommodate ? this.imgHorSpacing : 0)
        })
        ttlSlides++
        if (this.isMerch) {
          promises.push(this.createSlide(merch, `${slideNameValue} - ${padStart(currentSlideNbr.toString(), 3, '0')}`, currentObjects.slice(), options, this.imageDefaultScaleFactor)
            .catch((error) => {
              // TODO:Merch@andre ask andre what needs to be done when failed creating slide due to system unable to create slide objects file
              console.warn(`Create slide failed, unable to create slide object file, \n${error}`)
            }))
        }
        currentSlideNbr++
        currentObjects = []
      })
      return Promise.all(promises)
        .then(() => {
          if (this.isMerch) {
            return ttlSlides
          }
        })
    }
    else {
      // Articles per row
      const artPerRow = Math.floor(this.slideWidth / (this.imgSize + this.imgHorSpacing))
      groups.forEach((articleList, slideName) => {
        let groupArticleCounter = 0
        let rowMaxHeight = 0
        let currentSlideNbr = 1
        let currentObjects: any[] = []
        let currentImgInRow = 0
        const slideNameValue = indexedKeys[slideName] ? indexedKeys[slideName] : slideName
        // Add title
        if (titleOnEachPage) {
          this.titleHeight = this.getTextBoxHeight(slideNameValue, 51, this.font, this.slideWidth) || 20
          currentObjects.push(this.createTitleObj(slideNameValue))
        }
        else {
          this.titleHeight = 0
        }
        let remainingHeight = this.slideHeight - this.titleHeight
        let currentTop = this.titleHeight
        while (articleList.length > groupArticleCounter) {
          remainingHeight = remainingHeight - rowMaxHeight
          currentTop += rowMaxHeight
          const articlesToDraw = articleList.slice(groupArticleCounter, groupArticleCounter + artPerRow)
          groupArticleCounter += artPerRow
          rowMaxHeight = 0
          for (let i = 0; i < articlesToDraw.length; i++) {
            if (rowMaxHeight < (articlesToDraw[i]._maxHeight + this.imgSize + this.imgVerSpacing + articlesToDraw[i]._isCustomPriceHeight)) {
              rowMaxHeight = articlesToDraw[i]._maxHeight + this.imgSize + this.imgVerSpacing + articlesToDraw[i]._isCustomPriceHeight
            }
          }
          // move to new page if row cannot accomodate also if row max height is greater thn page then also add in the same page dont create new page
          if (remainingHeight < rowMaxHeight && groupArticleCounter !== artPerRow) {
            remainingHeight = this.slideHeight - this.titleHeight
            currentTop = this.titleHeight
            ttlSlides++
            if (this.isMerch) {
              promises.push(this.createSlide(merch, `${slideNameValue} - ${padStart(currentSlideNbr.toString(), 3, '0')}`, currentObjects.slice(), options, this.imageDefaultScaleFactor)
                .catch((error) => {
                  // TODO:Merch@andre ask andre what needs to be done when failed creating slide due to system unable to create slide objects file
                  console.warn(`Create slide failed, unable to create slide object file, \n${error}`)
                }))
            }
            currentSlideNbr++
            currentObjects = []
            if (titleOnEachPage) {
              currentObjects.push(this.createTitleObj(slideNameValue))
            }
          }
          articlesToDraw.forEach((article) => {
            const top = currentTop + this.imgSize / 2 + this.imgVerSpacing / 2
            const left = currentImgInRow * (this.imgSize + this.imgHorSpacing) + this.imgSize / 2 + this.imgHorSpacing / 2
            currentObjects.push(
              this.createImage(top, left, article.Id, options.imageType, this.imageDefaultScaleFactor, this.imageDefaultScaleFactor),
            )
            if (attributes.length !== 0) {
              const left = currentImgInRow * (this.imgSize + this.imgHorSpacing) + this.imgHorSpacing / 2
              const top = currentTop + this.imgSize + this.imgVerSpacing
              currentObjects.push(
                this.createLabel(top, left, this.imgSize, attributes, article),
              )
            }
            if (isCustomArticlePrice) {
              currentObjects.push(this.createTextObject(article, currentTop, currentImgInRow))
            }

            currentImgInRow++
            if (currentImgInRow >= artPerRow) {
              currentImgInRow = 0
            }
          })
        }

        if (currentObjects.length > 0) {
          ttlSlides++
          if (this.isMerch) {
            promises.push(this.createSlide(merch, `${slideNameValue} - ${padStart(currentSlideNbr.toString(), 3, '0')}`, currentObjects.slice(), options, this.imageDefaultScaleFactor)
              .catch((error) => {
                // TODO:Merch@andre ask andre what needs to be done when failed creating slide due to system unable to create slide objects file
                console.warn(`Create slide failed, unable to create slide object file, \n${error}`)
              }))
          }
        }
      })
      return Promise.all(promises)
        .then(() => {
          if (this.isMerch) {
            return ttlSlides
          }
        })
    }
  }

  findNeedsOverLapping(width: number, height: number, numberOfCards: number) {
    const overlappingObject: IOverlapingObject = {
      canAccommodate: false,
      canAccommodateWithoutSpacing: false,
      overlappingWidthPercentage: 0,
      maxNumberOfRowsCanBe: 0,
      overlappingHeight: 0,
      maxNumberOfCardsCanBeInOneRow: 0,
    }
    overlappingObject.canAccommodate = false
    overlappingObject.canAccommodateWithoutSpacing = false
    overlappingObject.overlappingWidthPercentage = 0
    overlappingObject.maxNumberOfRowsCanBe = 0
    overlappingObject.maxNumberOfCardsCanBeInOneRow = 0
    const articleMaxHeight = this.fitInOnePageProps.articleAttributeMaxHeight + this.imgSize
    const numberOfROws = Math.floor(height / articleMaxHeight)
    const numbersOfCardsInEachROw = Math.floor(width / (this.imgSize + this.imgHorSpacing))
    const numbersOfCardsInEachROwWithoutSpacing = Math.floor(width / this.imgSize)
    if (numberOfROws * numbersOfCardsInEachROw >= numberOfCards) {
      overlappingObject.canAccommodate = true
    }
    else if (numberOfROws * numbersOfCardsInEachROwWithoutSpacing >= numberOfCards) {
      overlappingObject.canAccommodateWithoutSpacing = true
    }
    else {
      // find the overlapping
      overlappingObject.maxNumberOfRowsCanBe = Math.round(height / articleMaxHeight)
      overlappingObject.maxNumberOfCardsCanBeInOneRow = Math.ceil(numberOfCards / overlappingObject.maxNumberOfRowsCanBe)
      overlappingObject.overlappingWidthPercentage = this.imgSize - ((width - this.imgSize) / (overlappingObject.maxNumberOfCardsCanBeInOneRow <= 1 ? 1 : overlappingObject.maxNumberOfCardsCanBeInOneRow - 1))
    }
    return overlappingObject
  }

  createMapForArticleAttributes(selectedArticleAttributes: string[]) {
    for (let index = 0; index < selectedArticleAttributes.length; index++) {
      const attributeObject = this.merchLabelAttributes.find(attribute => attribute.SystemName === selectedArticleAttributes[index])
      if (utils.isDefined(attributeObject)) {
        this.mapOfSelectedArticleAttributesSystemNameToDisplayLabel.set(selectedArticleAttributes[index], attributeObject.DisplayName)
      }
    }
  }

  getArticleAttributesHeight(catalog: CatalogDetails, article: MyArticle, articleAttributes: string[], retailPg?: CatalogPriceGroup, wholesalePg?: CatalogPriceGroup, outletPg?: CatalogPriceGroup) {
    let textForWidth = ''
    for (let attributeIndex = 0; attributeIndex < articleAttributes.length; attributeIndex++) {
      const attributeLabel = this.mapOfSelectedArticleAttributesSystemNameToDisplayLabel.get(articleAttributes[attributeIndex])
      const emptyValuePlaceHolder = '[Blank]'
      if (utils.isDefined(attributeLabel)) {
        textForWidth += `${attributeLabel}: `
      }
      const valueAtPath = article[articleAttributes[attributeIndex]]
      if (utils.isDefined(valueAtPath)) {
        if (articleAttributes[attributeIndex].toLowerCase() === '_retailprice') {
          textForWidth += utils.isDefined(valueAtPath) ? utils.formatPrice(retailPg, Number(valueAtPath), catalog?.Config.ShowPriceThousandsSeparated) : emptyValuePlaceHolder
        }
        else if (articleAttributes[attributeIndex].toLowerCase() === '_wholesaleprice') {
          textForWidth += utils.isDefined(valueAtPath) ? utils.formatPrice(wholesalePg, Number(valueAtPath), catalog?.Config.ShowPriceThousandsSeparated) : emptyValuePlaceHolder
        }
        else if (articleAttributes[attributeIndex].toLowerCase() === '_outletprice') {
          textForWidth += utils.isDefined(valueAtPath) ? utils.formatPrice(outletPg, Number(valueAtPath), catalog?.Config.ShowPriceThousandsSeparated) : emptyValuePlaceHolder
        }
        else if (articleAttributes[attributeIndex].toLowerCase() === 'colorid') {
          textForWidth += utils.isDefined(valueAtPath) ? article.ColorName ? article.ColorName : valueAtPath : emptyValuePlaceHolder
        }
        else {
          textForWidth += article[articleAttributes[attributeIndex]]
        }
      }
      if (attributeIndex !== (articleAttributes.length - 1)) {
        textForWidth += '\n'
      }
    }
    return { textForWidth, maxHeight: this.getTextBoxHeight(textForWidth, this.articleAttributeProp.fontSize, this.font, this.imgSize) }
  }

  getTextBoxHeight(text: string, fontSize: number, font: string, width: number) {
    const textBox = new MbTextBox(text, {
      top: 0,
      left: 0,
      width,
      fontFamily: font,
      fontSize,
    })
    return textBox.height
  }

  findMerchLabelAttributes(catalog: CatalogDetails, myAttributes: IMyAttribute[]) {
    this.merchLabelAttributes = []
    // check for config MerchLabelAttributes and find the attributes with which need to show the labels
    const configMerchLabelAttributes = catalog?.Config.MerchLabelAttributes
    if (!utils.isDefined(configMerchLabelAttributes)) {
      this.merchLabelAttributes = myAttributes
    }
    else if (utils.isDefined(configMerchLabelAttributes) && configMerchLabelAttributes.length === 0) {
      this.merchLabelAttributes = []
    }
    else {
      for (let i = 0; i < myAttributes.length; i++) {
        if (configMerchLabelAttributes.includes(myAttributes[i].SystemName)) {
          this.merchLabelAttributes.push(myAttributes[i])
        }
      }
    }
  }

  findFontSizeDependingOnTheHeight(scaling: number, fontSize: number, maxHeight: number) {
    const textBoxHeight = this.getTextBoxHeight(this.fitInOnePageProps.articleMaxHeightText, fontSize, this.font, this.imgSize) || 0
    if (maxHeight >= textBoxHeight) {
      this.fitInOnePageProps.articleAttributeMaxHeight = textBoxHeight
      return fontSize
    }
    else {
      fontSize = fontSize - 1
      return this.findFontSizeDependingOnTheHeight(scaling, fontSize, maxHeight)
    }
  }

  findTheScalingFactor(totalCards: number, scaleX: number, scaleY: number, width: number, height: number) {
    const eachCardWidth = this.imgSize
    const eachCardHeight = this.fitInOnePageProps.articleAttributeMaxHeight + this.imgSize
    this.fitInOnePageProps.scaleX = scaleX - 0.02
    this.fitInOnePageProps.scaleY = scaleY - 0.02

    const newWidth = eachCardWidth * this.fitInOnePageProps.scaleX
    const hewHeight = eachCardHeight * this.fitInOnePageProps.scaleY
    const maximumNumberOCardsInOneRow = Math.floor(width / newWidth)
    const maximumNumberOfCardsInOneColumn = Math.floor((height) / hewHeight)
    const totalCardsCanAccommodateInOnePage = maximumNumberOCardsInOneRow * maximumNumberOfCardsInOneColumn
    if (totalCardsCanAccommodateInOnePage >= totalCards || scaleX <= 0.4) {
      if (scaleX < 0.4) {
        this.needOverLapping = true
        this.fitInOnePageProps.scaleX = 0.4
        this.fitInOnePageProps.scaleY = 0.4
      }
    }
    else {
      this.findTheScalingFactor(totalCards, this.fitInOnePageProps.scaleX, this.fitInOnePageProps.scaleY, width, height)
    }
  }

  createTitleObj(title: string) {
    return {
      type: 'textbox',
      top: 0,
      left: 0,
      width: this.slideWidth,
      height: this.titleHeight,
      scaleX: 1,
      scaleY: 1,
      flipX: false,
      flipY: false,
      text: title,
      fill: '#000000',
      backgroundColor: '',
      fontFamily: this.font,
      fontSize: 51,
      fontStyle: 'normal',
      fontWeight: 'normal',
      textAlign: 'center',
      locked: true,
      templateObject: true,
      isOriginTopLeft: true,
    }
  }

  createImage(top: number, left: number, articleId: number, imageType: string, scaleX: number, scaleY: number) {
    return {
      type: 'articleImage',
      top,
      left,
      width: this.imageWidth,
      height: this.imageHeight,
      articleId,
      angle: 0,
      scaleX,
      scaleY,
      flipX: false,
      flipY: false,
      locked: false,
      templateObject: false,
      shouldAllowTemplateBigImagesToResize: true,
      imageType,
    }
  }

  createTextObject(article: MyArticle, currentTop, imgInRow: number) {
    const text = article._templateCustomPrice != null ? article._templateCustomPrice.toString() : ''
    return {
      type: 'textbox',
      top: currentTop + this.imgSize + this.imgVerSpacing + (article._maxHeight || 0),
      left: imgInRow * (this.imgSize + this.imgHorSpacing) + this.imgHorSpacing / 2,
      width: this.imgSize,
      height: article._isCustomPriceHeight,
      angle: 0,
      scaleX: 1,
      scaleY: 1,
      flipX: false,
      flipY: false,
      text,
      fill: '#000000',
      backgroundColor: '',
      fontFamily: this.font,
      fontSize: this.articleAttributeProp.fontSize,
      fontStyle: 'normal',
      fontWeight: 'normal',
      textAlign: 'center',
      locked: false,
      templateObject: true,
      isOriginTopLeft: true,
    }
  }

  createLabel(top: number, left: number, width: number, attributes: string[], article: MyArticle, showLabels = true) {
    if (!attributes || attributes.length === 0) { return }
    return {
      type: 'articleDetails',
      top,
      left,
      width,
      height: article._maxHeight,
      angle: 0,
      scaleX: 1,
      scaleY: 1,
      fill: '#000000',
      backgroundColor: '',
      flipX: false,
      flipY: false,
      customOptions: {
        articleProps: attributes.slice(),
        articleBLCoords: { x: 0, y: 0 },
      },
      fontFamily: this.font,
      fontSize: this.articleAttributeProp.fontSize,
      fontStyle: this.articleAttributeProp.fontStyle,
      fontWeight: this.articleAttributeProp.fontWeight,
      textAlign: this.articleAttributeProp.textAlign,
      locked: false,
      articleId: article.Id,
      templateObject: false,
      showLabels,
      isOriginTopLeft: true,
    }
  }

  createSlide(merch, slideName, objects: any[], options: Record<string, any>, imageDefaultScaleFactor: number, articleIdList: number[] = []) {
    const folderNode = options.folder
    const sortOrder = Math.max(...folderNode.children.map(child => child.sortOrder), 0) + 1
    const folderStructure = findParentPath(folderNode)
    if (utils.isDefined(folderNode.children.find(node => node.label === slideName))) {
      let c = 0
      do {
        c++
        slideName = `${slideName} (${c})`
      } while (folderNode.children.find(node => node.label === slideName) !== undefined)
    }
    return merch!.addSlide(folderStructure.id, folderStructure.name, folderNode.sortOrder, slideName, objects, options.slideSize, imageDefaultScaleFactor, sortOrder, true, articleIdList)
  }

  generateSortOrderString(article: MyArticle, sortOrderAttributes: string[], myAttributes: Record<string, IMyAttribute>) {
    let sortOrderString = ''
    sortOrderAttributes.forEach((sortOrderAttribute) => {
      let attributeValue = article[sortOrderAttribute]
      if (sortOrderAttribute.toLowerCase() === '_retailprice'
        || sortOrderAttribute.toLowerCase() === '_wholesaleprice'
        || sortOrderAttribute.toLowerCase() === '_outletprice') {
        const articlePriceDetails = article[sortOrderAttribute]
        attributeValue = articlePriceDetails != null ? articlePriceDetails : ''
      }
      else if (sortOrderAttribute === '_favoriteTags') {
        const tagNames: string[] = []
        article._FavoriteTags.forEach((tagObject) => {
          tagNames.push(tagObject.Tag)
        })
        // we will take the first sorted result in order to maintain the sorting by article number
        const sortedTagNames = tagNames.sort()
        // adding '~' to sort the articles with tags at last
        // TODO: may need to implement separate comparer
        attributeValue = sortedTagNames.length > 0 ? `~${sortedTagNames[0]}` : ''
      }
      else if (myAttributes[sortOrderAttribute] && myAttributes[sortOrderAttribute].AttributeType === AttributeType.MultiValue) {
        const multiValues = article[sortOrderAttribute] || []
        const sortedValues = Array.isArray(multiValues) ? multiValues.sort() : []
        attributeValue = sortedValues.length > 0 ? `~${sortedValues[0]}` : ''
        //   } else if (sortOrderAttribute.toLowerCase() === '_firstdeliverydate') {
        // attributeValue = attributeValue && attributeValue.toString().trim() !== '' ? attributeValue.getTime() : ''
      }
      if (typeof attributeValue === 'number') {
        // for correct comparison of number string
        const numberParts = attributeValue.toString().split('.')
        sortOrderString += numberParts[0].toString().padStart(10, '0')
        if (numberParts[1] != null) {
          sortOrderString += `.${numberParts[1].toString().padEnd(5, '0')}`
        }
      }
      else {
        sortOrderString += attributeValue || ''
      }
    })
    return sortOrderString
  }
}

export default new SlidesGenStandard()
interface IGroupItem {
  group: string
  article: MyArticle
  articlePrice?: number
}
interface IOverlapingObject {
  canAccommodate: boolean
  canAccommodateWithoutSpacing: boolean
  overlappingWidthPercentage: number
  maxNumberOfRowsCanBe: number
  overlappingHeight: number
  maxNumberOfCardsCanBeInOneRow: number
}
