<template>
  <tx-autocomplete
    ref="refAutocomplete" v-model="currentComponentModelValue"
    :clearable="clearable"
    :debounce="appConstants.debounce.input"
    :disabled="disabled || isMasterSizeScaleValid"
    :errors="errors"
    :fetch-suggestions="searchMasterSizeScale"
    :placeholder="placeholder"
    :required="required"
    display-prop="SizeScale"
    value-prop="Id"
    @change="onModelValueSelected"
  />
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import type { ErrorObject } from '@vuelidate/core'
import TxAutocomplete from './TxAutocomplete.vue'
import utils from '@/services/utils'
import type { MasterSizeScaleModel } from '@/api/t1/model/sizeModel'
import { appConstants } from '@/models/constants'
import { useUserStore } from '@/store/userData'

import type Article from '@/models/article'

interface IProps {
  articles: Array<Article | Record<string, any>>
  attribute: IAttributeEditorAttribute
  checked?: boolean
  clearable?: boolean
  disabled?: boolean
  errors?: ErrorObject[]
  form: Record<string, any>
  modelValue: number | null
  placeholder?: string
  required?: boolean
  showCheckbox?: boolean
  showExternalChangeManagementInfo?: boolean
  showLabel?: boolean
  showUpdateSeasonlessAttributeWarning?: boolean
  shouldConsiderObsoleteModelValue?: boolean
  shouldAllowResetExistingValue?: boolean
}
const props = withDefaults(defineProps<IProps>(), {
  clearable: true,
  disabled: false,
  errors: () => [] as ErrorObject[],
  label: '',
  modelValue: null,
  placeholder: '',
  required: false,
  showCheckbox: false,
  showExternalChangeManagementInfo: false,
  showLabel: true,
  showUpdateSeasonlessAttributeWarning: true,
  shouldConsiderObsoleteModelValue: false,
  shouldAllowResetExistingValue: true,
})

const emit = defineEmits<{
  (e: 'change', modelValue: any): void
  (e: 'update:modelValue', modelValue: number | null): void
  (e: 'resetSizeScale'): void
}>()

const userStore = useUserStore()

const refAutocomplete = ref<InstanceType<typeof TxAutocomplete>>()
const isFirstChange = ref(true)

const currentComponentModelValue = computed({
  get: () => props.modelValue,
  set: (modelValue) => {
    emit('update:modelValue', modelValue)
    emit('change', modelValue)
  },
})
const validMasterSizeScales = computed(() => {
  const articles = props.articles

  const validMasterSizeScales: MasterSizeScaleModel[] = []

  Object.values(userStore.masterSizeScales).forEach((sizeScale) => {
    let valid = true

    if (!sizeScale.Status) {
      valid = false
    }

    if (Object.keys(sizeScale.Criteria).length !== 0) {
      if (articles.length !== 0) {
        articles.forEach((article) => {
          const criteriaData: Record<string, any> = {}
          Object.assign(criteriaData, article)
          Object.assign(criteriaData, props.form)

          for (const criteriaProperty in sizeScale.Criteria) {
            const criteriaPropertyValueLower = !Array.isArray(sizeScale.Criteria[criteriaProperty])
              ? [sizeScale.Criteria[criteriaProperty].toString().toLowerCase()]
              : sizeScale.Criteria[criteriaProperty].map(criteriaValue => criteriaValue.toString().toLowerCase())

            if (!criteriaData.hasOwnProperty(criteriaProperty) || criteriaData[criteriaProperty] == null || !criteriaPropertyValueLower.includes(criteriaData[criteriaProperty].toString().toLowerCase())) {
              valid = false
              break
            }
          }
        })
      }
      else {
        const criteriaData: Record<string, any> = {}
        Object.assign(criteriaData, props.form)
        for (const criteriaProperty in sizeScale.Criteria) {
          const criteriaPropertyValueLower = !Array.isArray(sizeScale.Criteria[criteriaProperty])
            ? [sizeScale.Criteria[criteriaProperty].toString().toLowerCase()]
            : sizeScale.Criteria[criteriaProperty].map(criteriaValue => criteriaValue.toString().toLowerCase())

          if (!criteriaData.hasOwnProperty(criteriaProperty) || criteriaData[criteriaProperty] == null || !criteriaPropertyValueLower.includes(criteriaData[criteriaProperty].toString().toLowerCase())) {
            valid = false
            break
          }
        }
      }
    }
    if (valid) {
      validMasterSizeScales.push(sizeScale)
    }
  })

  return validMasterSizeScales
})
const isMasterSizeScaleValid = computed(() => {
  if (props.modelValue === null) {
    return false // If no value, return false so it can be enabled for editing
  }
  // if the existing MasterSizeScale is invalid then assign the MasterSizeScaleId as null, we will allow user to update it
  const isAssignedMasterSizeScaleValid = validMasterSizeScales.value.some((item) => {
    return item.Id === props.modelValue
  })

  // If the MasterSizeScaleId is not valid, set MasterSizeScaleId and SizeScaleId to null
  if (!isAssignedMasterSizeScaleValid) {
    emit('update:modelValue', null)
    emit('change', null)
  }

  return isAssignedMasterSizeScaleValid && isFirstChange.value
})
const debouncedUpdateModelFunction = useDebounceFn((modelValue, selectedOption) => {
  let value = modelValue
  // if user remove the value set null
  if (modelValue === '') {
    value = null
    // make sizeScaleId null sizes empty when master user remove master size scale
    if (props.form.hasOwnProperty('SizeScaleId')) {
      emit('resetSizeScale')
    }
  }
  currentComponentModelValue.value = value

  // due to issue with auto complete not being able to set the correct display value when using display-prop, set the initial display value
  if (refAutocomplete.value) {
    refAutocomplete.value?.setDisplayModelValue(selectedOption ? selectedOption.SizeScale : '')
  }
}, appConstants.debounce.input)

function onModelValueSelected(value, selectedOption) {
  isFirstChange.value = false
  debouncedUpdateModelFunction(value, selectedOption)
}

function searchMasterSizeScale(query: string, callback) {
  let result: MasterSizeScaleModel[] = []
  if (utils.isDefined(query)) {
    result = validMasterSizeScales.value
      .filter(record => record.Status && record.SizeScale != null && ((!props.modelValue && !record.IsObsolete) || ((props.modelValue === record.Id) || !record.IsObsolete)) && record.SizeScale.toString().toLowerCase().includes(query.toString().toLowerCase()))
      .splice(0, 100)
  }
  callback(result)
}

watch(() => props.modelValue, (modelValue) => {
  if (modelValue == null && refAutocomplete.value) {
    // if model value changed to null programmatically while validating sizeScale update the display value and make it empty
    refAutocomplete.value?.setDisplayModelValue('')
  }
})

onMounted(async () => {
  await nextTick()
  if (props.modelValue != null && typeof props.modelValue !== 'object') {
    // props.shouldAllowResetExistingValue - we want to prefill the master size scale irrespective of whatever value it is as while editing the sizescale we dont allow masterscale to be edited
    const assignedSizeScale = props.shouldAllowResetExistingValue ? validMasterSizeScales.value.find(sizeScale => sizeScale.Id === props.modelValue) : userStore.masterSizeScales[props.modelValue]
    currentComponentModelValue.value = assignedSizeScale && (props.shouldConsiderObsoleteModelValue || !assignedSizeScale.IsObsolete) ? assignedSizeScale.Id : null

    // due to issue with auto complete not being able to set the correct display value when using display-prop, set the initial display value
    if (refAutocomplete.value) {
      refAutocomplete.value?.setDisplayModelValue(assignedSizeScale ? assignedSizeScale.SizeScale : '')
    }
  }
})
</script>
