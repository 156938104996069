<template>
  <div class="container flex flex-col w-full h-full overflow-hidden">
    <!-- HEADER -->
    <tx-form-header class="flex flex-col mx-2 my-[30px] grow-0 shrink-0 justify-center header" :title="t('catalog.actionBar.editArticles')" :show-header="showHeader" />

    <!-- BODY -->
    <div class="px-10 mt-4 alerts">
      <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
    </div>
    <div class="w-full h-full overflow-auto body">
      <div v-if="failedArticles.length === 0">
        <tx-collapse v-model="activeGroups" class="px-10 mb-4">
          <tx-collapse-item v-for="(group, groupIndex) in groups" :key="groupIndex" title-class="tracking-[1px] font-semibold text-tx-accordion-title" :title="group.label" :value="group.label">
            <div class="grid w-full grid-cols-2 gap-x-6">
              <div v-for="attribute in group.attributes" :key="groupIndex + attribute.SystemName" class="mb-5 last-of-type:mb-4">
                <attribute-editor
                  v-if="form.hasOwnProperty(attribute.SystemName) && attributeOptions.hasOwnProperty(attribute.SystemName)"
                  v-model="form[attribute.SystemName]"
                  :attribute="attribute" :form="form"
                  :checked="attributeOptions[attribute.SystemName].checked"
                  :disabled="attributeOptions[attribute.SystemName].disabled || attribute.ReadOnly"
                  :required="attribute.IsRequired"
                  :errors="v$[attribute.SystemName]?.$errors"
                  :show-checkbox="true"
                  :show-external-change-management-info="attribute.ExternalChangeManagementURL != null"
                  :articles="articles"
                  @blur="v$[attribute.SystemName]?.$touch"
                  @change="handleChange(attribute)"
                />
              </div>
            </div>
          </tx-collapse-item>
        </tx-collapse>
        <div class="grid w-full grid-cols-2 px-10 gap-x-6">
          <div v-for="attribute in nonGroupedAttributes" :key="attribute.SystemName" class="mb-5 last-of-type:mb-4">
            <attribute-editor
              v-if="form.hasOwnProperty(attribute.SystemName) && attributeOptions.hasOwnProperty(attribute.SystemName)"
              v-model="form[attribute.SystemName]"
              :attribute="attribute" :form="form" :checked="attributeOptions[attribute.SystemName].checked"
              :disabled="attributeOptions[attribute.SystemName].disabled || attribute.ReadOnly"
              :required="attribute.IsRequired"
              :errors="v$[attribute.SystemName]?.$errors"
              :show-checkbox="true"
              :show-external-change-management-info="attribute.ExternalChangeManagementURL != null"
              :articles="articles"
              @blur="v$[attribute.SystemName]?.$touch"
              @change="handleChange(attribute)"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <tx-alert show type="error" :text="t('updateArticle.failedArticle')" />
        <table class="w-full text-center">
          <thead class="border-b bg-default">
            <tr>
              <th scope="col" class="py-2 text-sm font-semibold whitespace-nowrap" v-text="t('general.articleNumber')" />
              <th scope="col" class="py-2 text-sm font-semibold" v-text="t('updateArticle.errorMessage')" />
            </tr>
          </thead>
          <tbody>
            <tr v-for="failedArticle in failedArticles" :key="failedArticle.ArticleNumber" class="bg-white border-b">
              <td class="py-2 text-sm font-light whitespace-nowrap" v-text="failedArticle.ArticleNumber" />
              <td class="py-2 text-sm font-light whitespace-normal" v-text="failedArticle.ErrorMessage" />
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- FOOTER -->
    <tx-form-footer
      class="flex flex-row justify-end flex-shrink-0 flex-nowrap"
      :primary-text="t('general.update')" :primary-disabled="articleDetailsTemplateAttributes.length === 0 || failedArticles.length > 0 || !Object.values(attributeOptions).some(x => x.checked === true) || v$.$invalid" :secondary-disabled="loading"
      :primary-loading="loading" @primary-click="onUpdate(false)" @secondary-click="onCancel"
    />

    <tx-dialog
      v-model="seasonlessWarningDialogVisible" :title="t('general.alert')" :loading="loading"
      :cancel-state="loading ? 'disabled' : 'enabled'" :ok-state="loading ? 'loading' : 'enabled'"
      show-ok-cancel @click="seasonlessWarningDialogVisible = false" @ok="onUpdate(true)"
    >
      <div class="text-xl" v-text="t('updateArticle.seasonlessWarningWhileSave')" />
    </tx-dialog>
  </div>
</template>

<script setup lang="ts">
import { isArray, uniqBy } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import { computed, nextTick, reactive, ref, watch } from 'vue'
import useArticleLocalDataUpdater from '../composables/articleLocalDataUpdater'
import useAttributeValidator from '../composables/attributeValidator'
import AttributeEditor from '@/shared/components/AttributeEditor.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import TxCollapse from '@/shared/components/txCollapse/txCollapse.vue'
import TxCollapseItem from '@/shared/components/txCollapse/txCollapseItem.vue'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxFormHeader from '@/shared/components/forms/TxFormHeader.vue'
import TxFormFooter from '@/shared/components/forms/TxFormFooter.vue'
import type Article from '@/models/article'
import type MyArticle from '@/models/myArticle'
import { AttributeType } from '@/models/catalogAttribute'
import { updateArticle } from '@/api/t1/article'
import { appConstants } from '@/models/constants'
import { useArticleFormHelper } from '@/shared/composables/articleFormHelper'
import utils, { CancelToken } from '@/services/utils'
import useErrorMessage from '@/shared/composables/errorMessage'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'

interface IProps {
  articles: MyArticle[]
  showHeader?: boolean
  isModel?: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  showHeader: true,
  isModel: false,
})

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated', articles: MyArticle[] | Article[]): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { errorMessage, hasError } = useErrorMessage()
const { getIndexedRestrictedAttributesBasedOnArticlesMaxSateRank, getIndexedRestrictedAttributesBasedOnArticlesStateRank } = useArticleFormHelper()
const { refreshLocalArticlesData } = useArticleLocalDataUpdater()

const loading = ref(false)
const seasonlessWarningDialogVisible = ref(false)
const form = reactive<Record<string, any>>({})
const attributeOptions = reactive<Record<string, { checked: boolean, disabled: boolean }>>({})
const failedArticles = ref<{ ArticleNumber: string, ErrorMessage: string }[]>([])
const articles = ref<MyArticle[] | Article[]>([])

const groups = ref<Array<{ label: string, attributes: IMyAttribute[] }>>([])
const activeGroups = ref<Array<string>>([])
const nonGroupedAttributes = ref<Array<IMyAttribute>>([])
const editableAttributes = ref<Array<IMyAttribute>>([])

const articleDetailsTemplateAttributes = computed(() => {
  const result: IMyAttribute[] = []
  if (userStore.activeCatalog && userStore.myAttributes) {
    if (userStore.activeCatalog.Config.AllowArticleNameForUpdate) {
      const articleNameField = Object.assign({}, appConstants.staticFieldTemplate, {
        AttributeType: AttributeType.Nvarchar,
        Creatable: true,
        Editable: true,
        DisplayName: t('general.modelName'),
        SystemName: 'ArticleName',
        IsRequired: true,
        IsSeasonless: true,
        IsModelLevel: false,
        Visible: true,
      })
      result.push(articleNameField)
    }
    const myAttributes = userStore.myAttributes
    userStore.activeCatalog.Config.ArticleDetailsTemplate2.forEach((itm) => {
      const attribute = myAttributes[itm.value]
      if (utils.isDefined(attribute) && !attribute.IsStatic && attribute.Editable && attribute.AttributeType !== AttributeType.Calc
        && (!utils.isDefined(attribute.AttributeSource) || (attribute.AttributeSource.toLowerCase() === 'self'
        || (attribute.AttributeSource.toLowerCase() === 'parent' && attribute.Overridable)))
      ) {
        if (Object.keys(attribute.Criteria).length) {
          attribute.CriteriaVettingList = myAttributes[itm.value].VettingList ? [...myAttributes[itm.value].VettingList as string[]] : []
        }
        result.push(attribute)
      }
    })
  }
  return result
})

// editable attributes required in useAttributeValidator will be initalized in initAttributes function
const { updateCriteriaAttributeAllowedValues, updateLookupAttributeValues, v$ } = useAttributeValidator(articles, editableAttributes.value, form, attributeOptions)

// initForm uses updateCriteriaAttributeAllowedValues
initForm()
initAttributes()

// METHODS
function init() {
  reset()
  initForm()
  initAttributes()
}

/**
 * @description initialize attribute based on the T1S-ArticleForm Configuration
 * initialize group attributes
 * initialize nonGroupAttributes
 * update attribute's following properties: SortOrder,IsRequired,ReadOnly
 */
function initAttributes() {
  const indexedRestrictedAttributesBasedOnArticlesMaxSateRank = getIndexedRestrictedAttributesBasedOnArticlesMaxSateRank(articles.value)
  const indexedRestrictedAttributesBasedOnArticlesStateRank = getIndexedRestrictedAttributesBasedOnArticlesStateRank(articles.value)
  const indexedAllowedAttributes = utils.arrayToStringDictionary(articleDetailsTemplateAttributes.value, 'SystemName')

  if (userStore.activeCatalog?.Config?.ArticleForm
    && userStore.activeCatalog.Config.ArticleForm.updateArticles) {
    const updateArticleFormConfig = userStore.activeCatalog.Config.ArticleForm.updateArticles
    if (updateArticleFormConfig.hasOwnProperty('groups') && Array.isArray(updateArticleFormConfig.groups)) {
      updateArticleFormConfig.groups.forEach((group) => {
        const currentGroup = {
          label: group.label as string,
          attributes: [] as Array<IMyAttribute>,
        }
        // insert group attributes
        if (group.hasOwnProperty('attributes') && Array.isArray(group.attributes)) {
          group.attributes.forEach((attributeConfig, index) => {
            if (indexedAllowedAttributes.hasOwnProperty(attributeConfig.attribute)) {
              const currentAttribute = indexedAllowedAttributes[attributeConfig.attribute]
              const isRequired = currentAttribute.IsRequired || attributeConfig.required
              const readOnly = (!isRequired && (currentAttribute.ReadOnly || attributeConfig.readOnly)) || (currentAttribute.IsModelLevel && indexedRestrictedAttributesBasedOnArticlesMaxSateRank.hasOwnProperty(currentAttribute.SystemName)) || (!currentAttribute.IsModelLevel && indexedRestrictedAttributesBasedOnArticlesStateRank.hasOwnProperty(currentAttribute.SystemName))
              const sortOrder = index
              const configuredStateLockingExternalChangeManagementURL = currentAttribute.SystemName !== 'ModelName' ? appConfig.stateLockingExternalChangeManagementURL : appConfig.stateLockingExternalChangeManagementURLForModelName
              const stateLockingExternalChangeManagementURL = configuredStateLockingExternalChangeManagementURL && configuredStateLockingExternalChangeManagementURL.toString().trim().length
                && ((currentAttribute.IsModelLevel && indexedRestrictedAttributesBasedOnArticlesMaxSateRank.hasOwnProperty(currentAttribute.SystemName)
                && indexedRestrictedAttributesBasedOnArticlesMaxSateRank[currentAttribute.SystemName].AllowChangeRequest) || (!currentAttribute.IsModelLevel && indexedRestrictedAttributesBasedOnArticlesStateRank.hasOwnProperty(currentAttribute.SystemName)
                && indexedRestrictedAttributesBasedOnArticlesStateRank[currentAttribute.SystemName].AllowChangeRequest))
                ? configuredStateLockingExternalChangeManagementURL
                : undefined
              const attributeToInsert = Object.assign({}, currentAttribute, { ReadOnly: readOnly, IsRequired: isRequired, SortOrder: sortOrder, ExternalChangeManagementURL: stateLockingExternalChangeManagementURL })
              utils.insertSorted(attributeToInsert, currentGroup.attributes, (a, b) => utils.comparer(a, b, ['SortOrder', 'DisplayName']))
              editableAttributes.value.push(attributeToInsert)
              // attribute priority will be given to group (first encountered group in updateArticleFormConfig) and then to non groups(based on T1S logic), delete so it will not duplicated if repeated in configuration
              delete indexedAllowedAttributes[attributeConfig.attribute]
            }
          })
        }
        // if group contains valid attributes
        if (currentGroup.attributes.length) {
          groups.value.push(currentGroup)
          if (group.expanded) {
            activeGroups.value.push(group.label)
          }
        }
      })
    }
    if (updateArticleFormConfig.hasOwnProperty('attributes') && Array.isArray(updateArticleFormConfig.attributes)) {
      updateArticleFormConfig.attributes.forEach((attributeConfig, index) => {
        if (indexedAllowedAttributes.hasOwnProperty(attributeConfig.attribute)) {
          const currentAttribute = indexedAllowedAttributes[attributeConfig.attribute]
          const isRequired = currentAttribute.IsRequired || attributeConfig.required
          const readOnly = (!isRequired && (currentAttribute.ReadOnly || attributeConfig.readOnly)) || (currentAttribute.IsModelLevel && indexedRestrictedAttributesBasedOnArticlesMaxSateRank.hasOwnProperty(currentAttribute.SystemName)) || (!currentAttribute.IsModelLevel && indexedRestrictedAttributesBasedOnArticlesStateRank.hasOwnProperty(currentAttribute.SystemName))
          const sortOrder = index
          const configuredStateLockingExternalChangeManagementURL = currentAttribute.SystemName !== 'ModelName' ? appConfig.stateLockingExternalChangeManagementURL : appConfig.stateLockingExternalChangeManagementURLForModelName
          const stateLockingExternalChangeManagementURL = configuredStateLockingExternalChangeManagementURL && configuredStateLockingExternalChangeManagementURL.toString().trim().length
            && ((currentAttribute.IsModelLevel && indexedRestrictedAttributesBasedOnArticlesMaxSateRank.hasOwnProperty(currentAttribute.SystemName)
            && indexedRestrictedAttributesBasedOnArticlesMaxSateRank[currentAttribute.SystemName].AllowChangeRequest) || (!currentAttribute.IsModelLevel && indexedRestrictedAttributesBasedOnArticlesStateRank.hasOwnProperty(currentAttribute.SystemName)
            && indexedRestrictedAttributesBasedOnArticlesStateRank[currentAttribute.SystemName].AllowChangeRequest))
            ? configuredStateLockingExternalChangeManagementURL
            : undefined
          const attributeToInsert = Object.assign({}, currentAttribute, { ReadOnly: readOnly, IsRequired: isRequired, SortOrder: sortOrder, ExternalChangeManagementURL: stateLockingExternalChangeManagementURL })
          utils.insertSorted(attributeToInsert, nonGroupedAttributes.value, (a, b) => utils.comparer(a, b, ['SortOrder', 'DisplayName']))
          editableAttributes.value.push(attributeToInsert)
          // delete so it will not duplicated if repeated in configuration
          delete indexedAllowedAttributes[attributeConfig.attribute]
        }
      })
    }
    // add remaining allowed attributes that are not configured
    const remainingAttributes = Object.values(indexedAllowedAttributes)
    remainingAttributes.forEach((attribute) => {
      const configuredStateLockingExternalChangeManagementURL = attribute.SystemName !== 'ModelName' ? appConfig.stateLockingExternalChangeManagementURL : appConfig.stateLockingExternalChangeManagementURLForModelName
      const stateLockingExternalChangeManagementURL = configuredStateLockingExternalChangeManagementURL && configuredStateLockingExternalChangeManagementURL.toString().trim().length
        && ((attribute.IsModelLevel && indexedRestrictedAttributesBasedOnArticlesMaxSateRank.hasOwnProperty(attribute.SystemName)
        && indexedRestrictedAttributesBasedOnArticlesMaxSateRank[attribute.SystemName].AllowChangeRequest) || (!attribute.IsModelLevel && indexedRestrictedAttributesBasedOnArticlesStateRank.hasOwnProperty(attribute.SystemName)
        && indexedRestrictedAttributesBasedOnArticlesStateRank[attribute.SystemName].AllowChangeRequest))
        ? configuredStateLockingExternalChangeManagementURL
        : undefined
      const attributeToInsert = Object.assign({}, attribute, { ReadOnly: attribute.ReadOnly || (attribute.IsModelLevel && indexedRestrictedAttributesBasedOnArticlesMaxSateRank.hasOwnProperty(attribute.SystemName)) || (!attribute.IsModelLevel && indexedRestrictedAttributesBasedOnArticlesStateRank.hasOwnProperty(attribute.SystemName)), ExternalChangeManagementURL: stateLockingExternalChangeManagementURL })
      nonGroupedAttributes.value.push(attributeToInsert)
      editableAttributes.value.push(attributeToInsert)
    })
  }
  else {
    articleDetailsTemplateAttributes.value.forEach((attribute) => {
      const configuredStateLockingExternalChangeManagementURL = attribute.SystemName !== 'ModelName' ? appConfig.stateLockingExternalChangeManagementURL : appConfig.stateLockingExternalChangeManagementURLForModelName
      const stateLockingExternalChangeManagementURL = configuredStateLockingExternalChangeManagementURL && configuredStateLockingExternalChangeManagementURL.toString().trim().length
        && ((attribute.IsModelLevel && indexedRestrictedAttributesBasedOnArticlesMaxSateRank.hasOwnProperty(attribute.SystemName)
        && indexedRestrictedAttributesBasedOnArticlesMaxSateRank[attribute.SystemName].AllowChangeRequest) || (!attribute.IsModelLevel && indexedRestrictedAttributesBasedOnArticlesStateRank.hasOwnProperty(attribute.SystemName)
        && indexedRestrictedAttributesBasedOnArticlesStateRank[attribute.SystemName].AllowChangeRequest))
        ? configuredStateLockingExternalChangeManagementURL
        : undefined
      const attributeToInsert = Object.assign({}, attribute, { ReadOnly: attribute.ReadOnly || (attribute.IsModelLevel && indexedRestrictedAttributesBasedOnArticlesMaxSateRank.hasOwnProperty(attribute.SystemName)) || (!attribute.IsModelLevel && indexedRestrictedAttributesBasedOnArticlesStateRank.hasOwnProperty(attribute.SystemName)), ExternalChangeManagementURL: stateLockingExternalChangeManagementURL })
      nonGroupedAttributes.value.push(attributeToInsert)
      editableAttributes.value.push(attributeToInsert)
    })
  }
}

function handleChange(attribute: IMyAttribute) {
  if (attributeOptions.hasOwnProperty(attribute.SystemName)) {
    if (v$.value && v$.value[attribute.SystemName]) {
      v$.value[attribute.SystemName].$touch()
    }
    attributeOptions[attribute.SystemName].checked = true
    nextTick(() => {
      updateCriteriaAttributeAllowedValues()
      updateLookupAttributeValues()
    })
  }
}

function onCancel() {
  emit('cancel')
}

async function onUpdate(skipSeasonlessDialog = false) {
  errorMessage.value = ''
  if (!(await v$.value.$validate())) {
    errorMessage.value = t('validations.formInvalid')
    return
  }

  if (userStore.activeCatalog && articles.value.length > 0) {
    const attributes = Object.keys(attributeOptions)
    let anySeasonlessAttributeUpdated = false
    if (attributes.length && userStore.myAttributes && !skipSeasonlessDialog) {
      for (let index = 0; index < attributes.length; index++) {
        if (attributeOptions[attributes[index]].checked && userStore.myAttributes[attributes[index]].IsSeasonless) {
          anySeasonlessAttributeUpdated = true
          break
        }
      }
    }
    if (!skipSeasonlessDialog && anySeasonlessAttributeUpdated) {
      seasonlessWarningDialogVisible.value = true
      return
    }

    loading.value = true
    const catalog = userStore.activeCatalog
    const promises: Promise<any>[] = []
    const updatedArticles: (MyArticle | Article)[] = []
    failedArticles.value = []

    for (let index = 0; index < articles.value.length; index++) {
      const article = articles.value[index]
      const requestObj: Record<string, any> = {}
      articleDetailsTemplateAttributes.value.forEach((attribute) => {
        if (form.hasOwnProperty(attribute.SystemName) && attributeOptions.hasOwnProperty(attribute.SystemName) && attributeOptions[attribute.SystemName].checked) {
          const attributeValue = utils.getArticleAttributeTypeSpecificValue(attribute, form[attribute.SystemName])
          article[attribute.SystemName] = attributeValue
          requestObj[attribute.SystemName] = attributeValue
        }
      })
      requestObj.ArticleNumber = article.ArticleNumber
      requestObj.ArticleName = article.ArticleName
      requestObj.ModelNumber = article.ModelNumber
      promises.push(updateArticle(catalog.CatalogCode, article.Id, requestObj, true)
        .then(async () => {
          updatedArticles.push(article)
          if (anySeasonlessAttributeUpdated) {
            const articlesByModel = await appConfig.DB!.getMyArticlesByModelNumber(catalog, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, article.ModelNumber, true, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
            if (!(articlesByModel instanceof CancelToken) && articlesByModel) {
              articlesByModel.forEach((a) => {
                if (a.Id !== article.Id) {
                  updatedArticles.push(a)
                }
              })
            }
          }
        })
        .catch((error) => {
          let errorMessage = t('updateArticle.technicalIssue')
          if (utils.isDefined(error.response) && utils.isDefined(error.response.data) && Array.isArray(error.response.data) && error.response.data.length) {
            errorMessage = error.response.data.map(e => e.ErrorMessage).join(', ')
          }
          failedArticles.value.push({
            ArticleNumber: article.ArticleNumber,
            ErrorMessage: errorMessage,
          })
        }),
      )
      await utils.delay(500)
    }
    Promise.all(promises)
      .then(async () => {
        if (updatedArticles.length) {
          if (updatedArticles.length === 1) {
            await refreshLocalArticlesData(true, undefined, updatedArticles[0].Id)
          }
          else {
            const uniqArticlesByModelId = uniqBy(updatedArticles, 'ModelId')
            if (uniqArticlesByModelId.length === 1) {
              await refreshLocalArticlesData(true, uniqArticlesByModelId[0].ModelId)
            }
            else {
              const catalogCodes = Array.from(catalog._AllRelatedCatalogCodes)
              await userStore.doLoadData(['Articles'], catalogCodes)
            }
          }
          emit('updated', updatedArticles)
        }
      })
      .catch((e) => {
        console.error(e)
        errorMessage.value = t('general.unexpectedError')
      })
      .finally(() => {
        seasonlessWarningDialogVisible.value = false
        loading.value = false
      })
  }
}

async function initForm() {
  articles.value = props.articles
  if (props.isModel) {
    const queryCriterion: Array<[number, string]> = [[userStore.activeCatalog!.CatalogCode, props.articles[0].ModelNumber]]
    for (let i = 1; i < props.articles.length; i++) {
      queryCriterion.push([+userStore.activeCatalog!.CatalogCode, props.articles[i].ModelNumber])
    }
    const res = await appConfig.DB!.articles.where('[CatalogCode+ModelNumber]').anyOf(queryCriterion).toArray()
    if (res && isArray(res) && res[0]) {
      articles.value = res
    }
  }
  articleDetailsTemplateAttributes.value.forEach((attribute) => {
    let resetArticleAttributeValue = false
    let articleAttributeValue = utils.getArticleAttributeValue(attribute, articles.value[0])
    let disableAttribute = false
    const sourceFields: Record<string, any> = {}
    if (utils.isDefined(attribute.parsedValidationExpression) && attribute.parsedValidationExpression.length) {
      attribute.parsedValidationExpression.forEach((itm) => {
        if (utils.isDefined(itm.SourceField) && articles.value[0].hasOwnProperty(itm.SourceField)) {
          sourceFields[itm.SourceField] = articles.value[0][itm.SourceField]
        }
      })
    }
    for (let i = 1; i < articles.value.length; i++) {
      const article = articles.value[i]
      // if for articles source Field value in validation expression should be same else the editing for that attribute will be disabled.
      Object.keys(sourceFields).forEach((sourceField) => {
        if (!disableAttribute) {
          if (utils.isDefined(sourceFields[sourceField])) {
            if (!utils.isDefined(article[sourceField]) || sourceFields[sourceField] !== article[sourceField]) {
              disableAttribute = true
            }
          }
          else if (utils.isDefined(article[sourceField])) {
            disableAttribute = true
          }
        }
      })
      const currentArticleAttributeValue = utils.getArticleAttributeValue(attribute, article)
      if ((!utils.isDefined(articleAttributeValue) && !utils.isDefined(currentArticleAttributeValue))
        || (utils.isDefined(articleAttributeValue) && !utils.isDefined(currentArticleAttributeValue))
        || (!utils.isDefined(articleAttributeValue) && utils.isDefined(currentArticleAttributeValue))
        || (articleAttributeValue?.toString().toLowerCase() !== currentArticleAttributeValue?.toString().toLowerCase())
      ) {
        resetArticleAttributeValue = true
        break
      }
    }
    if (resetArticleAttributeValue) {
      // reset number/boolean/mutliValue attribute Value to base value
      articleAttributeValue = utils.getArticleAttributeDefaultValue(attribute)
    }
    form[attribute.SystemName] = articleAttributeValue
    attributeOptions[attribute.SystemName] = { checked: false, disabled: disableAttribute }
  })
  updateCriteriaAttributeAllowedValues()
  updateLookupAttributeValues()
}

function reset() {
  loading.value = false
  errorMessage.value = ''
  seasonlessWarningDialogVisible.value = false
  utils.resetReactiveObject(form)
  utils.resetReactiveObject(attributeOptions)
  groups.value = []
  activeGroups.value = []
  nonGroupedAttributes.value = []
  editableAttributes.value = []
  failedArticles.value = []
}
// METHODS - END

watch(() => props.articles, init)
</script>
